import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dialogflow',
    templateUrl: './dialogflow.component.html',
    styleUrls: ['./dialogflow.component.scss']
})

export class DialogFlowComponent {

    constructor() {
    }

}
