import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class AdminService {
 
  constructor(private http: HttpClient, private _configuration: Configuration) {
  }

  public userlist<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getUsersList, {
      headers: this._configuration.getHeaders()
    });
  }

  public userNameAvail(mailId) {
    return this.http.post(this._configuration._checkUsernameAvail + mailId, '', { headers: this._configuration.getHeaders() })
  }

  public getDesignation<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getDesignations, {
      headers: this._configuration.getHeaders()
    });
  }

  public getDepartment<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getDepartments, {
      headers: this._configuration.getHeaders()
    });
  }

  public getCompanyWeekend(year){
    return this.http.get(this._configuration._getCompanyWeekend + '?year=' + year + '&active=true' , { 
      headers: this._configuration.getHeaders() })
}

  public getHoliday<T>(active): Observable<T> {
    return this.http.get<T>(this._configuration._getHoliday + '?active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }


  public getHolidays(year){
    return this.http.get(this._configuration._getHolidays + '?year=' + year + '&active=true' , { 
      headers: this._configuration.getHeaders() })
}

public getUserLeavePolicyByYear(user,year){
  return this.http.get(this._configuration._getUserLeavePolicyByYear + '?userid=' + user + '&year=' + year + '&active=true' , { 
    headers: this._configuration.getHeaders() })
}

public getCompanyLeavePolicy(){
  return this.http.get(this._configuration._getCompanyLeavePolicy + '?active=true' , { 
    headers: this._configuration.getHeaders() })
}


public getAttendanceType() {
  return this.http.get(this._configuration._attendanceType ,{
    headers: this._configuration.getHeaders()
  });
}

public getAttendanceTypeList(active) {
  return this.http.get(this._configuration._attendanceTypeList + '?active=' + active ,{
    headers: this._configuration.getHeaders()
  })
}

public getCompanyLeavePolicyByYear(year){
  return this.http.get(this._configuration._getCompanyLeavePolicyByYear + '?year=' + year + '&active=true' , { 
    headers: this._configuration.getHeaders() })
}

public getUserLeavePolicy<T>(active): Observable<T> {
   
  return this.http.get<T>(this._configuration._getUserLeavePolicy + '?active=' + active, {
    headers: this._configuration.getHeaders()
  });
}

  public getCountryDetails<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getCountryCode);
  }

  public addUser(empDetails) {
    return this.http.post(this._configuration._saveUsers, empDetails, { headers: this._configuration.getHeaders() })
  }

  public updateUser(empUpdatedDetails) {
    return this.http.post(this._configuration._saveUsers, empUpdatedDetails, { headers: this._configuration.getHeaders() })
  }


  public getHealthInsurance(userid) {
    return this.http.get(this._configuration._getHealthInsurance + '?userid=' + userid  + '&active=true', { headers: this._configuration.getHeaders()});
  }
  
  public addHealthInsurance(sendData){
    return this.http.post(this._configuration._saveHealthInsurance, sendData, { headers: this._configuration.getHeaders() })
  }

  public updateHealthInsurance(sendData) {
    return this.http.put(this._configuration._updateHealthInsurance, sendData, { headers: this._configuration.getHeaders() })
  }

  createMaxItemsToShowConfig(maxItemsToShow) {
    let maxItemsToShowConfig = '';
    if (maxItemsToShow) {
        maxItemsToShowConfig = '&$top=' + maxItemsToShow;
    }

    return maxItemsToShowConfig;
}

createSkipConfig(skip) {
  let skipConfig = '';

  if (skip) {
      skipConfig = '&$skip=' + skip;
  }

  return skipConfig;
}
  
  public getAttendanceReport(fromDateInUTC, toDateInUTC,active ) {
    // const maxItemsToShowConfig = this.createMaxItemsToShowConfig(maxItemsToShow);
    // const skipConfig = this.createSkipConfig(skip);
    const URL = this._configuration._getHierarchyReport + '?' + 'fromDate=' + fromDateInUTC +
     '&toDate=' + toDateInUTC + '&active=' + true 
     return this.http.get(this._configuration._getHierarchyReport + '?' + 'fromDate=' + fromDateInUTC +
     '&toDate=' + toDateInUTC + '&active=' + active , {
      headers: this._configuration.getHeaders()
  });
    
}

  public updatetoActiveUser(userDetails) {
    return this.http.put(this._configuration._UserstoActive, userDetails, { headers: this._configuration.getHeaders() })
  }

  public updatetoInActiveUser(userDetails) {
    return this.http.put(this._configuration._UserstoInActive, userDetails, { headers: this._configuration.getHeaders() })
  }

  public getClients<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getClients, {
      headers: this._configuration.getHeaders()
    });
  }

  public getCountries<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getCountries, {
      headers: this._configuration.getHeaders()
    });
  }

  public getStates(CountryId) {
    return this.http.get(this._configuration._getStates + CountryId, { headers: this._configuration.getHeaders() })
  }

  public updateClient(clientUpdatedDetails) {
    return this.http.post(this._configuration._saveClients, clientUpdatedDetails, { headers: this._configuration.getHeaders() })
  }

  public deleteClient(clientID) {
    return this.http.put(this._configuration._deleteClient + clientID, '', { headers: this._configuration.getHeaders() })
  }

  public getMyCompany<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getMyCompany, {
      headers: this._configuration.getHeaders()
    });
  }

  public updateMyCompany(companyUpdatedDetails) {
    return this.http.put(this._configuration._updateMyCompany, companyUpdatedDetails, { headers: this._configuration.getHeaders() })
  }

  public saveAnnouncement(announcement) {
    return this.http.post(this._configuration._saveAnnouncement, announcement, { headers: this._configuration.getHeaders() })
  }

  public updateAnnouncement(announcement) {
    return this.http.put(this._configuration._updatetAnnouncement, announcement, { headers: this._configuration.getHeaders() })
  }

  public updateAttendanceType(adddata) {
    return this.http.put(this._configuration._updateattendanceType, adddata, { headers: this._configuration.getHeaders() })
  }
  public getAnnouncement<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getAnnouncement, {
      headers: this._configuration.getHeaders()
    });
  }

  public getAnnouncementForDashboard<T>(date): Observable<T> {
    return this.http.get<T>(this._configuration._getAnnouncementForDashboard + date, {
      headers: this._configuration.getHeaders()
    });
  }

  public deleteAnnouncement<T>(announcement_id): Observable<T> {
    return this.http.delete<T>(this._configuration._deleteAnnouncement + announcement_id,
      {
        headers: this._configuration.getHeaders()
      });
  }

  public activateAnnouncement(announcement) {
    return this.http.put(this._configuration.activeteAnnouncements, announcement, { headers: this._configuration.getHeaders() })
  }

  public deActivateAnnouncement(announcement) {
    return this.http.put(this._configuration.deactivateAnnouncements, announcement, { headers: this._configuration.getHeaders() })
  }

  public bulkImport() {
    return this._configuration._bulkImport;
  }
 public userLeavepolicy(){
  return this._configuration._userLeavepolicyImport
 }
 //Add Medical Form
 public healthInsurance(){
  return this._configuration._addMedicalForm
 }
 public health
  public sendEmailNotification<T>(userid): Observable<T> {
    return this.http.get<T>(this._configuration._sendEmailNotification + userid, {
      headers: this._configuration.getHeaders()
    });
  }

  public saveDepartment(addptdata) {
    return this.http.post(this._configuration._saveDepartment, addptdata, { headers: this._configuration.getHeaders() })
  }
  public saveHoliday(addholidata) {
    return this.http.post(this._configuration._saveHoliday, addholidata, { headers: this._configuration.getHeaders() })
  }

  public saveUserLeavePolicy(addleavetypedata) {
    return this.http.post(this._configuration._saveUserLeavePolicy, addleavetypedata, { headers: this._configuration.getHeaders() })
  }

  public saveCompanyLeavePolicy(addleavetypedata) {
    return this.http.post(this._configuration._saveCompanyLeavePolicy, addleavetypedata, { headers: this._configuration.getHeaders() })
  }

  public saveCompanyWeekend(adddata) {
    return this.http.post(this._configuration._saveCompanyWeekend, adddata, { headers: this._configuration.getHeaders() })
  }
  public saveAttendanceType(adddata) {
    return this.http.post(this._configuration._saveattendanceType, adddata, { headers: this._configuration.getHeaders() })
  }


  public importBulkDepartment() {
    return this._configuration._saveBulkDepartment;
  }

  public deleteDepartment(deptData) {
    return this.http.post(this._configuration._deleteDepartment, deptData, { headers: this._configuration.getHeaders() })
  }

  public deleteHoliday(requestData) {
    return this.http.post(this._configuration._deleteHoliday, requestData, { headers: this._configuration.getHeaders() });
 }

 public deleteUserLeavePolicy(requestData) {
  return this.http.post(this._configuration._deleteUserLeavePolicy, requestData, { headers: this._configuration.getHeaders() });
}
public deleteCompanyLeavePolicy(requestData) {
  return this.http.post(this._configuration._deleteCompanyLeavePolicy, requestData, { headers: this._configuration.getHeaders() });
}

public deleteAttendanceType(requestData) {
  return this.http.post(this._configuration._deleteattendanceType + '?id=' + requestData , { headers: this._configuration.getHeaders() });
}
  public saveDesignation(adddesdata) {
    return this.http.post(this._configuration._saveDesignation, adddesdata, { headers: this._configuration.getHeaders() })
  }

  public importBulkDesignation() {
    return this._configuration._saveBulkDesignation;
  }

  public deleteDesignation(deptData) {
    return this.http.post(this._configuration._deleteDesignation, deptData, { headers: this._configuration.getHeaders() })
  }

  public sendBulkUserMail(deptData) {
    return this.http.post(this._configuration._sendBulkMail, deptData, { headers: this._configuration.getHeaders() })
  }

  public getMailSettings<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getMailSettings, {
      headers: this._configuration.getHeaders()
    });
  }

  public saveMailSettings(maildata) {
    return this.http.post(this._configuration._saveMailSettings, maildata, { headers: this._configuration.getHeaders() });
  }

  /*  public testMailSettings<T>(emailId): Observable<T> {
     return this.http.get<T>(this._configuration._testMailSettings + emailId, {
       headers: this._configuration.getHeaders()
     });
   } */

  public testIncomingMailSettings<T>(emailAddressId): Observable<T> {
    return this.http.get<T>(this._configuration._testIncomingMailSettings + emailAddressId, {
      headers: this._configuration.getHeaders()
    });
  }

  public testOutgoingMailSettings<T>(emailAddressId): Observable<T> {
    return this.http.get<T>(this._configuration._testOutgoingMailSettings + emailAddressId, {
      headers: this._configuration.getHeaders()
    });
  }

  public activatelicensekey(key) {
    return this.http.post(this._configuration._activeLicenseKey, key, { headers: this._configuration.getHeaders() });
  }

  public resetpasword(passdata) {
    return this.http.put(this._configuration._resetPassword, passdata, { headers: this._configuration.getHeaders() });
  }

  public disableMFA(data) {
    return this.http.put(this._configuration._disableMFA, data, { headers: this._configuration.getHeaders() });
  }

  public generateOtp(otp) {
    return this.http.post(this._configuration._generateOTP, otp, { headers: this._configuration.getHeaders() });
  }

  public verifyOtp(otp) {
    return this.http.post(this._configuration._verifyOTP, otp, { headers: this._configuration.getHeaders() })
  }

  public activateCompanyLicense() {
    return this.http.post(this._configuration._activateCompanyLicense, '', { headers: this._configuration.getHeaders() });
  }

  public getAnnouncementList<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getannouncementlist, {
      headers: this._configuration.getHeaders()
    });
  }

  public getOverAllAssignedTask<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getOverAllAssignedTaskSummary, {
      headers: this._configuration.getHeaders()
    });
  }

  public monthlyTaskStatistics<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getmonthlyStatistics, {
      headers: this._configuration.getHeaders()
    });
  }

  public getBestPerformance<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getBestPerformance, {
      headers: this._configuration.getHeaders()
    });
  }

  public getWorstPerformance<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getWorstPerformance , {
      headers: this._configuration.getHeaders()
    });
  }

  public getTaskOverdue<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getTaskOverdue, {
      headers: this._configuration.getHeaders()
    });
  }
  public getDelayedTask<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getdelayedTask, {
      headers: this._configuration.getHeaders()
    });
  }


  public getConnectionType(): Observable<any> {
    return this.http.get(this._configuration._getConnectionType, {
      headers: this._configuration.getHeaders()
    });
  }
  public saveUserTimeIn(): Observable<any> {
    return this.http.post(this._configuration._userTimeIn, {
      headers: this._configuration.getHeaders()
    });
  }

  public getJobByUserId(id){
    return this.http.get(this._configuration._getJobDescriptionById + id + '&active=true', { headers: this._configuration.getHeaders() });
}
}


