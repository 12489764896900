import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class InboxMailService {
    constructor(private http: HttpClient, private _configuration: Configuration) {
    }

    public validateIncomingMailCredentials(data): Observable<any> {
        return this.http.post(this._configuration._validateIncomingMailCredentialsURL, data, { headers: this._configuration.getHeaders() });
    }

    public validateOutgoingMailCredentials(data): Observable<any> {
        return this.http.post(this._configuration._validateOutgoingMailCredentialsURL, data, { headers: this._configuration.getHeaders() });
    }

    public composeMail(mailDetails): Observable<any> {
        return this.http.post(this._configuration._composeMail, mailDetails, { headers: this._configuration.getHeaders() })
    }

    public saveDraft(data): Observable<any> {
        return this.http.post(this._configuration._saveDraftURL, data, { headers: this._configuration.getHeaders() })
    }

    public deleteDraftMessages(data): Observable<any> {
        return this.http.post(this._configuration._deleteDraftMessagesURL, data, { headers: this._configuration.getHeaders() })
    }

    public getAllInboxMail(data): Observable<any> {
        return this.http.post(this._configuration._getAllInboxMail, data, { headers: this._configuration.getHeaders() })
    }

    public getMailById(data): Observable<any> {
        return this.http.post(this._configuration._getInboxMailById, data, {
            headers: this._configuration.getHeaders()
        });
    }

    public getAllDraftMails(data): Observable<any> {
        return this.http.post(this._configuration._getAllDraftMail, data, { headers: this._configuration.getHeaders() })
    }

    public getDraftMailById(data): Observable<any> {
        return this.http.post(this._configuration._getDraftMailById, data, {
            headers: this._configuration.getHeaders()
        });
    }

    public getAllSentMails(data): Observable<any> {
        return this.http.post(this._configuration._getAllSentMail, data, { headers: this._configuration.getHeaders() })
    }

    public getSentMailById(data): Observable<any> {
        return this.http.post(this._configuration._getSentMailById, data, {
            headers: this._configuration.getHeaders()
        });
    }

    public getAllTrashMails(data): Observable<any> {
        return this.http.post(this._configuration._getAllTrashMails, data, { headers: this._configuration.getHeaders() })
    }

    public getTrashMailById(data): Observable<any> {
        return this.http.post(this._configuration._getTrashMailById, data, {
            headers: this._configuration.getHeaders()
        });
    }

    public getAllStarredMails(data): Observable<any> {
        return this.http.post(this._configuration._getAllStarredMail, data, { headers: this._configuration.getHeaders() })
    }

    public getStarredMailById(data): Observable<any> {
        return this.http.post(this._configuration._getStarredMailById, data, {
            headers: this._configuration.getHeaders()
        });
    }

    public onUnreadMail(sendData): Observable<any> {
        return this.http.post(this._configuration._unreadMail, sendData, { headers: this._configuration.getHeaders() });
    }

    public onReadMail(sendData): Observable<any> {
        return this.http.post(this._configuration._readMail, sendData, { headers: this._configuration.getHeaders() });
    }

    public getNewMessageCount(data): Observable<any> {
        return this.http.post(this._configuration._getNewMessageCount, data, {
            headers: this._configuration.getHeaders()
        });
    }

    public getFileAttachment(data): Observable<any> {
        return this.http.post(this._configuration._getFileAttachmentForMail, data, {
            headers: this._configuration.getHeaders(), responseType: 'blob' as 'json'
        });
    }

    public onReplyMail(data): Observable<any> {
        return this.http.post(this._configuration._replyMail, data, {
            headers: this._configuration.getHeaders()
        });
    }
}
