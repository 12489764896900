import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';
// import { AppConfig } from '../config/app.config';

@Injectable()
export class LoginService {
    options: any;

    constructor(private http: HttpClient, private _configuration: Configuration) {
    }

    public login<T>(loginInfo): Observable<T> {
        // const body = {
        //     'userName': loginInfo.UserName,
        //     'password': loginInfo.Password
        // }
        const body = new HttpParams().set('userName', loginInfo.UserName).set('password', loginInfo.Password).set('grant_type', 'password');

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;')
        };

        return this.http.post<T>(this._configuration.ServerWithApiUrl + 'logintoken/tokenbylogin', body, options);
    }

    public saveAlexaIntegrationDetails(details) {
        return this.http.post(this._configuration._saveAlexaIntegrationDetail, details, { headers: this._configuration.getHeaders() })
    }
}
