import { Component, OnInit, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
// import { CookieService } from 'angular2-cookie/core';
import { CookieService } from 'ngx-cookie';
import { ComponentsCommunicationService } from '../../services/componentsCommunication.service';
import { AppConfig } from '../../app.config';
import { Settings } from '../../settings/settings.model';
import { Configuration } from '../../services/configuration.constants';
import { NGXToastrService } from '../../services/toastr.service';

var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit, OnDestroy {
    settingsData: any;
    sidebarColor: any;
    compactMenuStatus = true; // Making sidebar compact
    largeSidebar: boolean;
    smallSidebar: boolean;
    imageBgStatus: any;
    sidebarBagroundImage: any;

    settings: any = AppConfig.settings;

    loggedInUserId: any;
    ThemeID: any;
    EmailNotification: any;
    PushNotification: any;
    LockTaskInMobile: any;
    MFA: any;
    sidebarWidthStatus: any;
    sidebarImageStatus: any;
    settingsDataToSend: any;

    subscription;

    constructor(private settingsservice: SettingsService, private elementRef: ElementRef, private _cookieService: CookieService,
        private conf: Configuration, private toastrservice: NGXToastrService,
        private componentsCommunicationService: ComponentsCommunicationService) {
        this.subscription = this.componentsCommunicationService.currentUserDetails.subscribe((data) => {
            // console.log(data);
            if (data === 'YES') {
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {
        this.loggedInUserId = this.conf.getuserId();

        // Making sidebar compact - so commented the code below
        /* this.elementRef.nativeElement.querySelector('#sidebarToggle')
            .addEventListener('click', this.onClick.bind(this)); */

        this.settingsservice.showSettings(false).subscribe(data => {
            if (data['Model'] == null) {
                const saveSettingsData = {
                    'UserId': this.conf.getuserId(),
                    'AppColorThemeId': this.settings.fullLayoutSaveSettings.appColorThemeId,
                    'LockTaskApp': this.settings.fullLayoutSaveSettings.lockTaskApp,
                    'EnablePushNotification': this.settings.fullLayoutSaveSettings.enablePushNotification,
                    'EnableMFA': this.settings.fullLayoutSaveSettings.enableMFA,
                    'EnableEMailNotification': this.settings.fullLayoutSaveSettings.enableEMailNotification,
                    'Active': this.settings.fullLayoutSaveSettings.active,
                    'EnableAssignerReceiverVerticalView': this.settings.fullLayoutSaveSettings.enableAssignerReceiverVerticalView
                }


                this.settingsservice.saveSettings(saveSettingsData).subscribe(success => {
                    this.settingsservice.showSettings(false).subscribe(successData => {
                        this.settingsData = successData;
                        this.sidebarColor = this.settingsData.Model.AppColorTheme;
                    }, error3 => {
                        console.log(error3);
                        this.sidebarColor = 'black';
                    });
                }, error2 => {
                    console.log(error2);
                    this.sidebarColor = 'black';
                });
            } else {
                this.settingsData = data;
                this.sidebarColor = this.settingsData.Model.AppColorTheme;

                // Making sidebar compact - so commented the code below
                /* if (this.settingsData.Model.IsCompactMenu === false) {
                    this.compactMenuStatus = false;
                } else {
                    this.compactMenuStatus = true;
                } */

                if (this.settingsData.Model.SideBarWidth === "small") {
                    this.smallSidebar = true;
                    this.largeSidebar = false;
                } else if (this.settingsData.Model.SideBarWidth === "large") {
                    this.smallSidebar = false;
                    this.largeSidebar = true;
                } else {
                    this.smallSidebar = false;
                    this.largeSidebar = false;
                }

                if (this.settingsData.Model.IsSidebarBgImage === "false") {
                    this.imageBgStatus = 'none';
                } else {
                    this.imageBgStatus = 'block';
                }

            }
            localStorage.setItem('settingsData', JSON.stringify(this.settingsData));
        }, error1 => {
            this.sidebarColor = 'black';
            console.log(error1);
        })
        let cookiesData: any = this._cookieService.getAll();

        this.sidebarBagroundImage = cookiesData.sidebarImage;
    }


    onClick(event) {
        // initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow() }, 300);
    }

    ngOnDestroy() {
        this.settingsservice.settingsData = null
    }

}
