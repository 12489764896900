import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DialogFlowComponent } from './dialogflow/dialogflow.component';
import { LoadersCssModule } from 'angular2-loaders-css';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FindInUserListPipe } from '../pipes/findInUserList.pipe.module';
import { QuillModule } from 'ngx-quill';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { TaskDetailedViewModule } from '../task-detailed-view/task-detailed-view.module';
import { DateFilterPopupComponent } from './popup-modal/date-filter-popup/date-filter-popup.component'

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        DialogFlowComponent,
        NgbModule,
        FormsModule,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        LoadersCssModule,
        FormsModule,
        ReactiveFormsModule,
        QuillModule,
        FileUploadModule,
        FindInUserListPipe,
        TaskDetailedViewModule,
        NgxSkeletonLoaderModule,
        
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        DialogFlowComponent,
        DateFilterPopupComponent,
    ],
    entryComponents: [DateFilterPopupComponent]
})
export class SharedModule { }
