export let commentSkeleton : any ={
    Task_comment_image:{
        width: '40px', height: '40px', 'background-color': '#dedfe1',margin :'5px',
      'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
    },
    Task_comment_list:{
        width: '10em', height: '10px', 'background-color': '#dedfe1',margin :'0px','border-radius':'0px',
      'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
    },
    Task_comment_list1:{
        width: '8em', height: '10px', 'background-color': '#dedfe1',margin :'0px','border-radius':'0px',
      'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
    }


}