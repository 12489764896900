import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class MyTaskService {
    constructor(private http: HttpClient, private _configuration: Configuration) {

    }
    public getSelfTaskList<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getSelfTaskList, { headers: this._configuration.getHeaders() });
    }
    public saveSelfTask(task) {
        return this.http.post(this._configuration._saveSelfTask, task, { headers: this._configuration.getHeaders() });
    }
    public changeSelfTaskStatus(status) {
        return this.http.put(this._configuration._changeTaskCommentStatus, status, { headers: this._configuration.getHeaders() })
    }

    // For ToDo task
    public saveTodoTask(task) {
        return this.http.post(this._configuration._saveTodoTask, task, { headers: this._configuration.getHeaders() });
    }
    public changeTodoSelfTask(status) {
        return this.http.post(this._configuration._changeTodoTaskStatus, status, { headers: this._configuration.getHeaders() })
    }
    public updateSelfTask(task) {
        return this.http.post(this._configuration._updateSelfTaskAttachment, task, { headers: this._configuration.getHeaders() });
    }
    public getTodoTaskList<T>(sendobj): Observable<T> {
        return this.http.post<T>(this._configuration._getTodoTaskListbyPaging,sendobj, { headers: this._configuration.getHeaders() });
    }

    public getOnHoldTaskList<T>(sendObj): Observable<T> {
        return this.http.post<T>(this._configuration._getOnHoldTaskList, sendObj, { headers: this._configuration.getHeaders() });
    }

    public getInprogressTaskList<T>(sendObj): Observable<T> {
        return this.http.post<T>(this._configuration._getInprogressTaskList, sendObj, { headers: this._configuration.getHeaders() });
    }

    public getCompletedTaskList<T>(sendObj): Observable<T> {
        return this.http.post<T>(this._configuration._getCompletedTaskList, sendObj, { headers: this._configuration.getHeaders() });
    }

    public changeTodoTaskStatus(status) {
        return this.http.post(this._configuration._changeTodoTaskStatus, status, { headers: this._configuration.getHeaders() })
    }

    public editTodoTaskStatus(status) {
        return this.http.post(this._configuration._editTodoTaskStatus, status, { headers: this._configuration.getHeaders() })
    }

    public deleteTodoTaskStatus(status) {
        return this.http.put(this._configuration._deleteTodoTaskStatus, status, { headers: this._configuration.getHeaders() })
    }

    public completeTodoTaskStatus(status) {
        return this.http.put(this._configuration._completeTodoTaskStatus, status, { headers: this._configuration.getHeaders() })
    }

    public reopenSelfTask(status) {
        return this.http.put(this._configuration._reopenTask, status, { headers: this._configuration.getHeaders() })
    }
}

