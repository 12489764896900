import * as moment from 'moment';

export class ActivityLogJSONResponseModel {
    deserialise(input: any) {
        const obj = {
            eventName: input.EventName,
            displayName: input.DisplayName,
            imagePath: input.ImagePath,
            activityDate: moment.utc(input.AddDateTimeInUTC).local().format('YYYY-MM-DD HH:mm:ss')
        }
        return obj;
    }
}