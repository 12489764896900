export class CommentsJSONResponseModel {
    deserialise(input: any) {
        const isNewComment = input.taskType === 'myTask' ? input.IsNewComment : input.isNewComment;
        const obj = {
            taskType: input.taskType,
            IsNewComment: isNewComment,
            taskId: input.Id ? input.Id : input.id,
            taskStatusId: input.taskStatusId,
            taskName: input.Name ? input.Name : input.name,
            userName: input.createUserDisplayName ? input.createUserDisplayName : input.main_receiver ? input.main_receiver.displayName : (input.group_receivers && input.group_receivers[0]) || input.isGroupTask ? "Group Task" : input.AssignerDetails[0].AssignerName,
            selectedUserImage: input.createUserImagePath ? input.createUserImagePath : input.main_receiver ? input.main_receiver.imagePath : (input.group_receivers && input.group_receivers[0]) || input.isGroupTask ? "assets/img/task/group.png" : input.AssignerDetails && input.AssignerDetails[0].AssignerImagePath ? input.AssignerDetails[0].AssignerImagePath : '',
        }
        return obj;
    }
}
