import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/do';
import { Configuration } from './configuration.constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private configuration: Configuration) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).do(event => { }, err => {
            if (err.url.includes(this.configuration._downloadAttachmentURL.split('/').pop()) && (err.status === 403 || err.status === 404)) {
                this.router.navigate(['/error'])
            } else {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    sessionStorage.setItem('tokenExpired', 'true');
                    // this.router.navigate(['/login/maintenance']);
                    sessionStorage.removeItem('incomingMailConfig');
                    sessionStorage.removeItem('outgoingMailConfig');
                    this.router.navigate(['/login']);
                } else if (err instanceof HttpErrorResponse && err.status === 403) {
                    sessionStorage.setItem('tokenExpired', 'true');
                    this.router.navigate(['/login']);
                }
            }
        });
    }
}
