import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Configuration } from './configuration.constants';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';


@Injectable()
export class BirthdayBotService {

  constructor(private http: HttpClient, private _configuration: Configuration) { }

  public findBirthday<T>(birthDate): Observable<T> {
    return this.http.post<T>(this._configuration._getBirthday, birthDate, {
        headers: this._configuration.getHeaders()
    });
}

}
