import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterSelfTask',
})

export class FilterSelfTask implements PipeTransform {

    transform(items: any[], searchText: string, jsonString: string): any[] {
        if (!searchText) {
            return items;
        } else {
            searchText = searchText.toLowerCase();
            return items.filter(it => {
                return it[jsonString].toLowerCase().includes(searchText);
            });
        }
    }
}