import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Configuration } from './configuration.constants';
import { Observable } from 'rxjs';

@Injectable()
export class CommonService {
    reportPageConfig;

    constructor(private http: HttpClient, private conf: Configuration) {
    }

    public downloadAttachment(data) {
        return this.http.post(this.conf._downloadAttachmentURL, data, { headers: this.conf.getHeaders(), responseType: 'blob' });
    }

    /* Report */
    public getMyHierarchy(userId) {
        return this.http.get(this.conf._getMyHierarchy + userId, { headers: this.conf.getHeaders() });
    }


    public getReport(requestJSON, formValue, maxItemsToShow, skip) {
        const statusConfig = this.createStatusConfig(formValue.statusForm);
        const severityConfig = this.createSeverityConfig(formValue.severityForm);
        const bellCountConfig = this.createBellCountConfig(formValue.priority);
        const maxItemsToShowConfig = this.createMaxItemsToShowConfig(maxItemsToShow);
        const skipConfig = this.createSkipConfig(skip);
        const orderByConfig = this.createOrderByConfigData(formValue.orderByForm);

        const URL = this.conf._getReportURL + '?' + '$filter=' + statusConfig + ' and ' + severityConfig + bellCountConfig
            + '&$orderby=' + orderByConfig + maxItemsToShowConfig + skipConfig;

        return this.http.post(URL, requestJSON, {
            headers: this.conf.getHeaders()
        });
    }

    createStatusConfig(statusForm) {
        let statusConfig = ''
        statusForm.statusTypeArray.forEach((statusType, i) => {
            if (statusForm.selectedStatusName === 'All') {
                statusConfig = 'TaskStatusId gt 0 and TaskStatusId lt 5';
            } else {
                if (statusType.isSelected) {
                    statusConfig = statusConfig ? statusConfig + ' or ' + 'TaskStatusId eq ' + statusType.id
                        : 'TaskStatusId eq ' + statusType.id;

                    if (statusType.id === 3) {
                        statusConfig = statusConfig + ' or ' + 'TaskStatusId eq 4'; // For archived task if completed status is selected
                    }
                }
            }
        });

        return '(' + statusConfig + ')';
    }

    createSeverityConfig(severityForm) {
        let severityConfig = '';
        severityForm.severityArray.forEach((severityType, i) => {
            if (severityForm.selectedSeverityName === 'All') {
                severityConfig = 'TaskPriorityId gt 0 and TaskPriorityId lt 5'
            } else {
                if (severityType.isSelected) {
                    severityConfig = severityConfig ? severityConfig + ' or ' + 'TaskPriorityId eq ' + severityType.id
                        : 'TaskPriorityId eq ' + severityType.id;
                }
            }
        });

        return '(' + severityConfig + ')';
    }

    createBellCountConfig(priority) {
        let bellCountConfig = '';
        if (priority) {
            bellCountConfig = 'and BellCount gt 0';
        }

        return bellCountConfig;
    }

    createMaxItemsToShowConfig(maxItemsToShow) {
        let maxItemsToShowConfig = '';
        if (maxItemsToShow) {
            maxItemsToShowConfig = '&$top=' + maxItemsToShow;
        }

        return maxItemsToShowConfig;
    }

    createSkipConfig(skip) {
        let skipConfig = '';

        if (skip) {
            skipConfig = '&$skip=' + skip;
        }

        return skipConfig;
    }

    createOrderByConfigData(orderByForm) {
        return orderByForm.selectedOrderByTypeProperty + ' ' + orderByForm.selectedOrderBySortNameProperty;
    }

    // Meeting
    public zoommeetingsignature(meetingdata) {
        return this.http.post(this.conf._zoomMeetingSignature, meetingdata, { headers: this.conf.getHeaders() })
    }

    public getHierarchyUserReport(sendObj) {
        return this.http.post(this.conf._getHierarchyUserReport, sendObj, {
          headers: this.conf.getHeaders()
        })
      }
}
