import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class InActiveUser implements CanActivate {
    constructor(private router: Router ) { };

    canActivate() {
        if (sessionStorage.getItem('confirm-licence-code') === 'verify') {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
