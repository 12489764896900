import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadersCssModule } from 'angular2-loaders-css';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ImageViewerModule } from 'ngx-image-viewer';
import { FormsModule } from '@angular/forms';
import { CommentsComponent } from './comments.component';
import { MainDatePipe } from '../pipes/main-date-pipe.module'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AutofocusModule } from '../shared/directives/autofocus.directive'

@NgModule({
    exports: [
        CommentsComponent
    ],
    imports: [
        CommonModule,
        LoadersCssModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DragScrollModule,
        ImageViewerModule,
        FormsModule,
        MainDatePipe,
        NgxSkeletonLoaderModule,
        AutofocusModule
    ],
    declarations: [
        CommentsComponent
    ]
})
export class CommentsModule { }
