import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class VerifiedUser implements CanActivate {
    constructor(private router: Router, ) { };

    canActivate() {
        if (sessionStorage.getItem('verify-status')) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
 