import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FindInUserList } from './findinuserlist.pipe';

@NgModule({
  declarations: [FindInUserList],
  imports: [CommonModule],
  exports: [FindInUserList]
})

export class FindInUserListPipe {

    static forRoot() {
        return {
            ngModule: FindInUserListPipe,
            providers: [],
        };
     }
}
