import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorDisplayComponent } from '../field-error-display/field-error-display.component';

@NgModule({
    exports: [
        FieldErrorDisplayComponent
    ],
    imports: [
        CommonModule
    ],
    declarations: [
        FieldErrorDisplayComponent
    ]
})
export class FieldErrorModule { }
