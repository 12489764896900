import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AttendanceService {
  reportPageConfig;

  constructor(private http: HttpClient, private _configuration: Configuration) {
  }

  public profileObs$: BehaviorSubject<any> = new BehaviorSubject({ id: 0, month: 0, year: 0, name: '', check: 0, CasualLeave: 0, MedicalLeave: 0, Month: ''});

  public getAttendanceStatus<T>(active): Observable<T> {
    return this.http.get<T>(this._configuration._attendanceStatus + '?active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }
  public getAttendanceType(active) {
    return this.http.get(this._configuration._attendanceType + '?active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }
  public getAttendanceTypeById(id) {
    return this.http.get(this._configuration._attendanceTypeById + id, {
      headers: this._configuration.getHeaders()
    });
  }
  public getUserStatus<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getUserAttendance, {
      headers: this._configuration.getHeaders()
    });
  }
  public saveTimeIn(timeIn) {
    return this.http.post(this._configuration._userTimeIn, timeIn, {
      headers: this._configuration.getHeaders()
    });
  }

  public getPunchInAttendance(data) {
    return this.http.post(this._configuration._punchInAttendance, data, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
  }

  public saveTimeOut(timeOut) {
    return this.http.put(this._configuration._UserTimeOut, timeOut, {
      headers: this._configuration.getHeaders()
    });
  }
  public leaveRequest(requestModel) {
    return this.http.post(this._configuration._leaveRequest, requestModel, {
      headers: this._configuration.getHeaders()
    });
  }

  public requestApproval(addData) {
    return this.http.post(this._configuration._requestApproval, addData, {
      headers: this._configuration.getHeaders()
    });
  }

  public getUserAttendanceByUserId(userId, active) {
    return this.http.get(this._configuration._getUserAttendanceByUserId + '?UserId=' + userId + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }
  public getUserAttendanceByMonth(data) {
    return this.http.post(this._configuration._getUserAttendanceByMonth, data, {
      headers: this._configuration.getHeaders()
    });
  }
  public getCheckedInAttendance(userId, currentDate, active) {
    return this.http.get(this._configuration._checkedInAttendance + '?UserId=' + userId +
      '&currentDate=' + currentDate + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }

  public deleteAttendance(requestModel) {
    return this.http.post(this._configuration._deleteAttendance, requestModel, {
      headers: this._configuration.getHeaders()
    });
  }

  public userRequest(reqObj) {
    return this.http.post(this._configuration._UserRequest, reqObj, {
      headers: this._configuration.getHeaders()
    });
  }
  public updateAttendance(reqObj) {
    return this.http.post(this._configuration._updateUserAttendance, reqObj, {
      headers: this._configuration.getHeaders()
    });
  }

  public getUserLeavePlicyByYear(userId, year, active) {
    return this.http.get(this._configuration._userLeaveDetailsByYear + '?userId=' + userId + '&year=' + year + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }
  public getUpcomingHolidaysByMonth(month, year, active) {
    return this.http.get(this._configuration._getHolidaysByMonth + '?month=' + month +
      '&year=' + year + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }
  public getComingHolidaysByYear(year, active) {
    return this.http.get(this._configuration._getHolidays + '?year=' + year + '&active=' + active, {
      headers: this._configuration.getHeaders()
    })
  }
  public getUserAttendanceReport(companyId, usersId, month, year) {
    const URL = this._configuration._getUserAttendanceReportURL + '?' + 'companyId=' + companyId +
      '&userId=' + usersId + '&month=' + month + '&year=' + year + '&active=' + true
    return this.http.get(URL, {
      headers: this._configuration.getHeaders()
    });

  }
  public getHierarchyUserReport(data) {
    return this.http.post(this._configuration._getHierarchyUserReport, data, {
      headers: this._configuration.getHeaders()
    })
  }
  public getUserReport(data){
    return this.http.post(this._configuration._getUserReport, data,  {
      headers: this._configuration.getHeaders()
    })
  }
  public userInfo<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getUsersList, { headers: this._configuration.getHeaders() });
  }

  public getSubordinateUser() {
    return this.http.get(this._configuration._getSubordinateUserList, {
      headers: this._configuration.getHeaders()
    })
  }

  public getWeekendByYear(year, active) {
    return this.http.get(this._configuration._companyWeekendByYear + '?year=' + year + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }

  public getBulkAttendanceReport(fromDate, toDate, userId){
    return this.http.get(this._configuration._getBulkUserAttendance+ '?userId='  + userId + '&startDate=' + fromDate + '&endDate=' + toDate,{
      headers: this._configuration.getHeaders()
    });
  }

}