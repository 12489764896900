import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class BirthdayDetailsJSON {
  @JsonProperty('ClientName', String)
  clientName: string;
  @JsonProperty('CompanyName', String)
  company: string;
  @JsonProperty('DisplayName', String)
  displayName: string;
  @JsonProperty('Department', String)
  department: string;
  @JsonProperty('DepartmentId', Number)
  departmentId: number;
  @JsonProperty('Designation', String)
  designation: string;
  @JsonProperty('DesignationId', Number)
  designationId: number;
  @JsonProperty('Email', String)
  email: string;
  @JsonProperty('Mobile', String)
  mobile: string;
  @JsonProperty('EmployeeId', String)
  userId: string;
  @JsonProperty('DOB', String)
  dob: string;

  constructor() {
    this.clientName = void 0;
    this.company = void 0;
    this.displayName = void 0;
    this.department = void 0;
    this.departmentId = void 0;
    this.designation = void 0;
    this.designationId = void 0;
    this.email = void 0;
    this.mobile = void 0;
    this.userId = void 0;
    this.dob = void 0;
  }

}

export class NotificationResponseJSON {

  getDeserializedData(data, root) {
    return {
      Body: data.Body,
      Read: data.Read,
      TaskId: data.TaskId,
      Title: data.Title,
      UserId: data.UserId,
      UserImage: data.UserImage,
      root: root
    }
  }
}
