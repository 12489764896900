import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadersCssModule } from 'angular2-loaders-css';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { SearchTextPipeModule } from '../pipes/search-text-pipe.module';
import { MainDatePipe } from '../pipes/main-date-pipe.module'
import { CommentsModule } from '../comments/comments.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ImageViewerModule } from 'ngx-image-viewer';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DomSanitizer } from 'app/pipes/domSanitizer.pipe.module';
import { DetailedviewSidebarComponent } from './detailedview-sidebar.component';
@NgModule({
    exports: [
        DetailedviewSidebarComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NgbModule, QuillModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DragScrollModule,
        ImageViewerModule,
        LoadersCssModule,
        CommentsModule,
        SearchTextPipeModule,
        FormsModule, ReactiveFormsModule,
        MainDatePipe, DomSanitizer
    ],
    declarations: [
        DetailedviewSidebarComponent,
    ],
})
export class TaskDetailedViewModule { }