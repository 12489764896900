import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFilterPopupComponent } from 'app/shared/popup-modal/date-filter-popup/date-filter-popup.component';
import { BehaviorSubject } from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class PopUpModalService {
    private dateFilterDataCommunication = new BehaviorSubject({
        name: 'name'
    });
    dateFilterCommunicationData = this.dateFilterDataCommunication.asObservable();

    constructor(private modalService: NgbModal) { }



    getDateFilterCommunicationData(data) {
        this.dateFilterDataCommunication.next(data);
    }

    openDateFilterPopup(data) {
        const modalRef = this.modalService.open(DateFilterPopupComponent)
        modalRef.componentInstance.popupDetails = data;
        return modalRef.result;
    }

}