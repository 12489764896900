import { Component, OnInit, ViewChild, TemplateRef, Renderer2, ElementRef } from '@angular/core';
import { Configuration } from '../../services/configuration.constants';
import { UserProfileService } from '../../services/user-profile.service';
import { Router } from '@angular/router';
import { PushNotificationService } from '../../services/pushnotification.service';
import { DataService } from '../../services/dashboard.service';
import { NGXToastrService } from '../../services/toastr.service';
import { ComponentsCommunicationService } from '../../services/componentsCommunication.service';
import { AppConfig } from '../../app.config';
import { JsonConvert, ValueCheckingMode, Any } from 'json2typescript';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BirthdayBotService } from '../../services/birthday-bot.service';
import { BirthdayDetailsJSON, NotificationResponseJSON } from './navbar.model';
import { ReceivedTaskService } from '../../services/receivedtask.service';
import { AssignedTaskJSONModal, NotificationTaskJSONModal } from '../../assigned-task/assignedTaskList/assigned-task-list.model';
import { FilterSelectedMemberTaskPipe } from 'app/pipes/filterSelecteMemberTasks.pipe';
import * as navbarCard from './navbar-skeleton-config'
import * as screenfull from 'screenfull';
import { ActivityLogSidebarCommunicationService } from 'app/services/activity-log-sidebar.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    navbarCardList = navbarCard.navbarSkeleton
    skeletonLoopCount = new Array(2);
    screenFull = screenfull;
    loggedInUserImage = 'assets/default_user/usericon.png';
    loggedInUserId: number;
    status: string;
    timedOut = false;
    sessionExpired: boolean;
    pushnotificationdropdown = [];
    notificationCount: number;
    isLoadingdNotification = true;
    settings: any = AppConfig.settings;

    isOpenTaskDetailedView: boolean;
    detailedViewTaskaskDetails: any;

    @ViewChild('showUserBirthdayModal', { static: true }) showUserBirthdayModal: TemplateRef<any>;
    showBirthdayIcon = false;
    birthdayPeopleList = [];
    userDisplayName: string;

    stopBirthdayIconAnimation: boolean;
    @ViewChild('shakeCakeIcon', { static: true }) shakeBirthdayIcon: ElementRef;

    priorityNotificationText = 'Priority';
    reopenedNotificationText = 'Reopened';
    assignedNotificationText = 'Assigned';

    commentedNotificationText = 'Commented';

    onHoldNotificationText = 'Onhold';
    inProgressNotificationText = 'Inprogress';
    completedNotificationText = 'Completed';

    reportedNotificationText = 'Reported';
    editedNotificationText = 'Edited';
    rewardedNotificationText = 'Rewarded';
    hierarchyRequestedNotificationText = 'Hierarchy Requested';
    hierarchyRejectedNotificationText = 'Hierarchy Rejected';
    hierarchyAcceptedNotificationText = 'Hierarchy Accepted';
    hierarchyRemovedNotificationText = 'Hierarchy Removed';

    browserType() {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return 'Firefox';
        } else {
            return 'unknown';
        }
    }

    constructor(private receivedTaskService: ReceivedTaskService,private activityLogSidebarCommunicationService:ActivityLogSidebarCommunicationService,
        private config: Configuration, private userprofileservice: UserProfileService,
        private pushNotificationService: PushNotificationService,
        private router: Router, private dataService: DataService, private toastrService: NGXToastrService,
        private componentsCommunicationService: ComponentsCommunicationService, private birthdayBotService: BirthdayBotService,
        private modal: NgbModal, private renderer: Renderer2) {
        this.componentsCommunicationService.currentUserDetails.subscribe((data) => {
            if (data === 'YES') {
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {
        // this.status = this.config.getUserStatus();
        this.userprofileservice.userInfo().subscribe(data => {
            
            this.loggedInUserImage = data['ImagePath'];
            if (this.loggedInUserImage === null || this.loggedInUserImage === '') {
                this.loggedInUserImage = 'assets/default_user/usericon.png';
            }
            this.status = 'Online';
        }, error => {
            console.log(error);
        })

        this.loggedInUserId = this.config.getuserId();
        // $.getScript('./assets/js/hopscotch.min.js');
        // $.getScript('./assets/js/tour.js');
        if (this.browserType() !== "Safari") {
            this.pushNotifications();
        }
        this.getBirthdayList();
    }

    toggleFullScreen() {
        screenfull.toggle();
    }

    getBirthdayList() {
        const jsonConvert: JsonConvert = new JsonConvert();
        const userEmailId = this.config.getUserMailId();
        const userDepartment = this.config.getUserDepartment();
        const birthdayDetailsJSON = BirthdayDetailsJSON;

        const birthdayDate = {
            'FromDate': new Date(),
            'ToDate': null
        }
        this.birthdayBotService.findBirthday<any[]>(birthdayDate)
            .subscribe(birthdayData => {
                if (birthdayData.length > 0) {
                    this.birthdayPeopleList = birthdayData.map(obj => {
                        if (obj.Email === userEmailId) {
                            if (localStorage.getItem('showUserSelfBirthdayMsg') !== 'NO') {
                                this.userDisplayName = this.config.getUserName();
                                this.modal.open(this.showUserBirthdayModal, { size: 'lg' });
                                localStorage.setItem('showUserSelfBirthdayMsg', 'NO');
                            }
                        } else {
                            if (obj.Department === userDepartment) {
                                this.showBirthdayIcon = true;
                                if (!localStorage.getItem('startBirthdayIconAnimation') &&
                                    localStorage.getItem('startBirthdayIconAnimation') !== 'YES') {
                                    localStorage.setItem('startBirthdayIconAnimation', 'YES');
                                    this.renderer.addClass(this.shakeBirthdayIcon.nativeElement, 'shake-icon');
                                }
                                return jsonConvert.deserialize(obj, birthdayDetailsJSON);
                            }
                        }
                    });
                }
            });
    }

    stopIconAnimation() {
        if (localStorage.getItem('startBirthdayIconAnimation') === 'YES') {
            localStorage.setItem('startBirthdayIconAnimation', 'NO');
            this.renderer.removeClass(this.shakeBirthdayIcon.nativeElement, 'shake-icon');
        }
    }

    pushNotifications() {
        this.pushnotificationdropdown = [];
        this.notificationCount = 0;
        this.isLoadingdNotification = true;
        this.pushNotificationService.receiveRecentNotifications(this.loggedInUserId).subscribe(data => {
            this.pushnotificationdropdown = [];
            for (const property in data) {
                if (property) {
                    this.pushnotificationdropdown.push(new NotificationResponseJSON().getDeserializedData(data[property], property));
                }
            }

            if (this.pushnotificationdropdown && this.pushnotificationdropdown.length !== 0) {
                this.pushnotificationdropdown.reverse();
                this.pushNotificationService.markAllNotificationsRead().subscribe(readnots => {
                    this.pushnotificationdropdown.forEach(element => {
                        if (element.Read === 0) {
                            this.notificationCount++;
                        }
                    })
                }, error => {
                    console.log(error);
                });
            }

            this.isLoadingdNotification = false;
        }, error => {
            this.isLoadingdNotification = true;
            console.log(error);
        });
    }

    receivedTaskView(task) {
        // console.log('task');
        this.dataService.saveDashboardTaskData(task);
        this.router.navigate(['/task/myTask']);
    }

    logout() {
        const changeUserStatus = {
            'UserStatusId': this.settings.navbarComponent.userStatusId,
            'Id': this.loggedInUserId
        }
        const userDetails = localStorage.getItem('userDetails');
        const environment = localStorage.getItem('env');

        if (localStorage.getItem(('userDetails'))) {
            this.userprofileservice.updateUserDetail(changeUserStatus).subscribe(success => {
                localStorage.setItem('loggedOutInOneTab', '1');
                localStorage.clear();
                sessionStorage.clear();
                console.clear();
                localStorage.setItem('oldUserDetails', userDetails);
                localStorage.setItem('env', environment);
                this.status = 'Offline';
                this.router.navigate(['/']);
            }, error => {
                console.log(error);
                this.toastrService.typeError('Logout cannot be done. Please try again.');
            });
        } else {
            console.clear();
            sessionStorage.clear();
            this.status = 'Offline';
            localStorage.setItem('env', environment);
            this.router.navigate(['/']);
        }
    }

    changeUserStatus(status) {
        const changeUserStatus = {
            'UserStatusId': status,
            'Id': this.loggedInUserId
        }
        this.userprofileservice.updateUserDetail(changeUserStatus).subscribe(success => {
            if (status === 1) {
                this.status = 'Online';
            } else if (status === 2) {
                this.status = 'Offline';
            } else if (status === 3) {
                this.status = 'Away';
            }
        }, error => {
            console.log(error);
            this.toastrService.typeError('Something went wrong. Please try again.');
        });
    }

    getNotifications() {
        this.pushNotifications();
    }

    redirectOnNotification(notification) {
        if (notification.Title === 'Task Completed') {
            this.router.navigate(['assignedtask']);
        } else if (notification.Title === 'New Task') {
            this.router.navigate(['/receivedTask']);
        } else if (notification.Title === 'Task Reopened') {
            this.router.navigate(['/receivedTask']);
        }
    }

    onImageRightClick(event) {
        return false;
    }

    notificationContainerClicked(event) {
        event.stopPropagation();
    }

    deleteNotification(event, pushnotification, i) {
        event.stopPropagation();
        this.pushNotificationService.deleteNotification(pushnotification.root)
            .subscribe((data) => {
                this.pushnotificationdropdown.splice(i, 1);
                this.toastrService.typeSuccess('Notification is cleared successfully');
            }, error => {
                console.log(error);
                this.toastrService.typeError('Something went wrong. Please try again');
            });
    }

    deleteAllNotifications() {
        this.pushNotificationService.deleteAllNotifications()
            .subscribe((data) => {
                this.pushNotifications();
                this.toastrService.typeSuccess('All notifications are cleared successfully');
            }, error => {
                console.log(error);
                this.toastrService.typeError('Something went wrong. Please try again');
            });
    }


    onShowDetailedView(task) {
        if (task.TaskId !== 0) {
            this.receivedTaskService.getTaskByTaskId(task.TaskId).subscribe(data => {
                const filteredTask = data.Receivers.find(obj => obj.UserId === this.loggedInUserId);
                data.isFYI = filteredTask ? filteredTask.IsFyi : false;
                if (filteredTask) {
                    data.Receivers.splice(data.Receivers.indexOf(filteredTask), 1);
                    data.CreateUserImagePath = data.CreatedUserImage;
                    const taskReceiver = data.Receivers.find(obj => obj.IsFyi !== true);
                    data.IsNewComment = filteredTask.UserTaskIsNewComment;
                    data.isRead = filteredTask.IsRead;
                    data.TaskStatusId = data.isFYI && taskReceiver ? taskReceiver.UserTaskStatusId : filteredTask.UserTaskStatusId;
                    data = new NotificationTaskJSONModal().deserialize(data);
                } else if (!filteredTask && data.Receivers.length === 1) {
                    data.CreateUserImagePath = data.Receivers[0].ImagePath;
                    data.CreatedDisplayName = data.Receivers[0].DisplayName;
                    data.CreateUserId = data.Receivers[0].UserId;
                    data.Receivers = [];
                    data = new NotificationTaskJSONModal().deserialize(data);
                } else if (!filteredTask && data.Receivers.length > 1 && !data.IsGroupTask) {
                    const receiver = data.Receivers.filter(obj => obj.IsFyi !== true);
                    // data.TaskStatusId=receiver[0].UserTaskStatusId;
                    data.CreateUserImagePath = receiver[0].ImagePath;
                    data.CreatedDisplayName = receiver[0].DisplayName;
                    data.CreateUserId = receiver[0].UserId;
                    data.Receivers.splice(data.Receivers.indexOf(receiver[0]), 1);
                    data = new NotificationTaskJSONModal().deserialize(data);
                } else {
                    data = new NotificationTaskJSONModal().deserialize(data);
                }
                data.page = "dashboard";
                data.taskType = filteredTask ? 'received' : 'assigned';
                this.onOpenTaskDetailedView(data);
            }, error => {
                this.toastrService.typeError('Something went wrong. Please try again');
            })
        }
    }

    onOpenTaskDetailedView(task) {
       // event.preventDefault();
        // event.stopPropagation();
        // this.isOpenTaskDetailedView = true;
        // this.detailedViewTaskaskDetails = task;
        const data = {
            isShowDetailedView: true,
            taskDetails: task,
            isOpenSidebarModal: true
        }
        this.activityLogSidebarCommunicationService.getUserDetails(data);
        event.preventDefault();
        event.stopPropagation();
    }

    closeDetailedViewModalEvent(event) {
        if (event) {
            this.isOpenTaskDetailedView = false;
        }
    }

    detailedViewCommunicationEvent(event) {
        if (event) {
            //   this.getMyTaskList();
        }
    }

    // Join Meeting
    callMeeting() {
        this.router.navigate(['/meeting']);
    }

}
