import { Routes } from '@angular/router';
import { OnlyLoggedInUsersGuard } from '../../services/OnlyLoggedInUsers.service';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'mytask',
    loadChildren: () => import('../../my-task/my-task.module').then(m => m.MyTaskPageModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'todotask',
    loadChildren: () => import('../../todo-task/todo-task.module').then(m => m.TodoTaskPageModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('../../attendance/attendance.module').then(m => m.AttendanceModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'assignedtask',
    loadChildren: () => import('../../assigned-task/assigned-task.module').then(m => m.AssignedTaskModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'receivedtask',
    loadChildren: () => import('../../received-task/received-task.module').then(m => m.ReceivedTaskModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'myworkgroup',
    loadChildren: () => import('../../my-workgroup/my-workgroup.module').then(m => m.MyWorkGroupModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('../../analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'archivedtasks',
    loadChildren: () => import('../../archived-tasks/archived-tasks.module').then(m => m.ArchivedTasksModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'project',
    loadChildren: () => import('../../project/project.module').then(m => m.ProjectModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'resumeBuilder/:id',
    loadChildren: () => import('../../resume-builder/resume-builder-module').then(m => m.resumeBuilderModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'resumePage/:id',
    loadChildren: () => import('../../resume-page/resume-page-module').then(m => m.resumePageModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('../../userprofile/user-profile-page.module').then(m => m.UserProfilePageModule),
    canActivate: [OnlyLoggedInUsersGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('../../report/report.module').then(m => m.ReportModule),
    canActivate: [OnlyLoggedInUsersGuard]
  }
];
