import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ElementRef, Output, EventEmitter, Renderer2 } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ReceivedTaskService } from '../services/receivedtask.service';
import { NGXToastrService } from '../services/toastr.service';
import { Configuration } from '../services/configuration.constants';
import { AppConfig } from '../app.config';
import { AssignedTaskService } from '../services/assignedtask.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TaskDetailedViewJSONResponseModel } from '../shared/models/taskDetailedView.JSON.model'
import { AdminService } from '../services/admin.service';
import { DetailedViewCommunicationService } from '../services/detailedViewCommunication.service';
import { MyTaskService } from '../services/mytask.service';
import { CommonService } from '../services/common.service';
import { onCreateObjectURLAndDownloadFile } from '../shared/utils/common-functions';
import * as detailedViewSkeleton from './task-detailed-view-skeleton-config';
import { ComponentsCommunicationService } from '../services/componentsCommunication.service';

@Component({
  selector: 'app-task-detailed-view',
  templateUrl: './task-detailed-view.component.html',
  styleUrls: ['./task-detailed-view.component.scss']
})

export class TaskDetailedViewComponent implements OnInit {
  skeleton = detailedViewSkeleton.taskDetailViewSkeleton;
  starImageArray = []
  // video Modal
  videoURL: any;
  isOpenVideoModal: boolean;
  closeResult: string;

  // audio modal
  audioURL: any
  isOpenAudioModal: boolean;

  settings: any = AppConfig.settings;
  modalRef: NgbModalRef;
  secondModal: NgbModalRef;
  isRewardOrArchive = false;
  comment;
  selectedStar = 0;
  @Input() isOpenTaskDetailedView = false;
  @Input() detailedViewTaskaskDetails;
  @ViewChild('detailedViewModal', { static: true }) detailedViewModal: ElementRef;
  @Output() closeDetailedViewModalEvent = new EventEmitter<any>();
  @Output() detailedViewCommunicationEvent = new EventEmitter<any>();
  maximumBellCount = this.settings.tasksettings.MAX_BELL;

  taskDetailsView: any;
  userId: any;
  userMailDetails: any;
  isShowCommentsModal = false;
  canSendComments = true;
  selectedTaskForComment;
  editTask;
  assigntaskform: FormGroup;
  isAssigningTask = false;
  taskdescription;
  taskDescriptionContent;
  taskDetailedView = true;
  isProvidingReward = false;
  isProvidingRewardAndArchive = false;
  openedPopupCount = 0;
  onListenToKeyBoardEvent: () => void;
  constructor(private modalDialog: NgbModal, private receivedtaskservice: ReceivedTaskService, private renderer: Renderer2,
    private assignedTaskService: AssignedTaskService, private toastrservice: NGXToastrService, private myTaskService: MyTaskService,
    private formBuilder: FormBuilder, private conf: Configuration, private adminService: AdminService, private elref: ElementRef,
    private detailedViewCommunicationService: DetailedViewCommunicationService, private commonService: CommonService,
    private componentsCommunicationService: ComponentsCommunicationService, private ChangeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.userId = this.conf.getuserId();
    this.userMailDetails = this.conf.getUserMailId();
    this.createForm();
    this.comment = 'Good Work!!';
  }

  ngOnChanges() {
    if (this.isOpenTaskDetailedView) {
      this.taskDetailsView = new TaskDetailedViewJSONResponseModel().deserialise(this.detailedViewTaskaskDetails);
      if (!this.taskDetailsView.userId && this.taskDetailsView.isGroupTask &&
        (this.taskDetailsView.taskType === 'assigned' || this.taskDetailsView.taskType === 'scheduled')) {
        this.showTaskDetailedView();
        this.taskDetailedView = false;
      } else {
        this.showTaskDetailedView();
        this.getUserDesignation();
      }
    }
  }

  showTaskDetailedView() {
    if (this.taskDetailsView) {
      $.getScript('../../../../assets/js/detailedView.js');
      this.modalRef = this.modalDialog.open(this.detailedViewModal, { size: 'lg', backdrop: 'static' });
      this.modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      this.onListenToKeyBoardEvent = this.renderer.listen(document, 'keyup', (event) => {
        if (event.key === 'Escape') {
          if (this.openedPopupCount === 0) {
            this.closeTaskDetailedViewModal();
          } else if (this.openedPopupCount === 1 || this.openedPopupCount === 2) {
            this.openedPopupCount = 0;
          }
        }
      })
      if (this.taskDetailsView.taskType === 'received' && !this.taskDetailsView.isRead) {
        this.readTask();
      }
    }
  }

  getDismissReason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      this.closeTaskDetailedViewModal();
    }
  }

  closeTaskDetailedViewModal() {
    this.modalRef.close();
    this.closeDetailedViewModalEvent.emit(true);
    this.taskDetailedView = true;
    this.openedPopupCount = 0;
    if (this.onListenToKeyBoardEvent) {
      this.onListenToKeyBoardEvent();
    }
  }


  taskStatusChange(event, task): any {
    const id = task.taskStatusId;
    event.stopPropagation();
    if (event.target.defaultValue !== undefined) {
      if (event.target.defaultValue !== '3') {
        $(event.target.nextElementSibling).css('display', 'inline-flex');
        this.updateTaskStatus(event, task);
      } else if (event.target.defaultValue === '3') {
        task.taskStatusId = 5;
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to change the task status to Completed?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0CC27E',
          cancelButtonColor: '#FF586B',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmButtonClass: 'btn btn-success btn-raised mr-2',
          cancelButtonClass: 'btn btn-danger btn-raised',
          buttonsStyling: false,
          // allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            task.taskStatusId = 3;
            $(event.target.nextElementSibling).css('display', 'inline-flex');
            this.updateTaskStatus(event, task);
          } else if (result.dismiss) {
            task.taskStatusId = id;
            $(event.target.nextElementSibling).css('display', 'none');
            $(event.target).parent().removeClass('focus');
          }
        });
      }
    }
  }

  updateTaskStatus(event, tasklist) {
    this.taskDetailsView.taskStatusId = Number(event.target.defaultValue);
    const stat = {
      'TaskId': tasklist.id,
      'UserId': this.userId,
      'TaskAction': this.settings.receivedTaskListComponent.taskStatus_TaskAction,
      'TaskValue': event.target.defaultValue.toString(),
      'ModBy': this.userMailDetails
    }

    this.receivedtaskservice.changeTaskEvent(stat).subscribe(
      successResponse => {
        if (!tasklist.isRead) {
          this.componentsCommunicationService.updateReceivedTaskUnreadCount({
            isCallApi: false, event: 'UnReadToRead'
          })
        }
        $(event.target.nextElementSibling).css('display', 'none');
        const sendDetails = {
          type: 'taskStatus',
          taskDetails: this.taskDetailsView
        }
        this.detailedViewCommunicationMessage(sendDetails);
      },
      errorResponse => {
        this.taskDetailsView.taskStatusId = Number(tasklist.taskStatusId);
        $(event.target.nextElementSibling).css('display', 'none');
        console.log(errorResponse.error);
        this.toastrservice.typeError('Something went wrong. Please try again.');
      }
    );
  }

  readTask() {
    const changereadstatus = {
      'TaskId': this.taskDetailsView.id,
      'UserId': this.userId,
      'TaskAction': this.settings.receivedTaskListComponent.readStatus_TaskAction,
      'TaskValue': this.settings.receivedTaskListComponent.readStatus_TaskValue,
      'ModBy': this.userMailDetails
    }
    this.receivedtaskservice.changeTaskEvent(changereadstatus).subscribe(
      successResponse => {
        this.taskDetailsView.isRead = true;
        const sendDetails = {
          type: 'readTask',
          taskDetails: this.taskDetailsView
        }
        this.componentsCommunicationService.updateReceivedTaskUnreadCount({
          isCallApi: false, event: 'UnReadToRead'
        })
        this.detailedViewCommunicationMessage(sendDetails);
      },
      errorResponse => {
        this.toastrservice.typeError("Something went wrong while reading the task.please try again")
        console.log(errorResponse.error)
      }
    );

  }

  onDeleteScheduledTask() {
    const sendata = {
      "id": this.taskDetailsView.id
    }
    this.assignedTaskService.deleteScheduledTask(sendata).subscribe(data => {
      this.toastrservice.typeSuccess('Task is deleted successfully');
      const sendDetails = {
        type: 'deleteScheduleTask',
        taskDetails: this.taskDetailsView
      }
      this.detailedViewCommunicationMessage(sendDetails);
      this.modalRef.close();
    }, error => {
      this.toastrservice.typeError('Something went wrong. Please try again.');
    })
  }

  createForm() {
    this.assigntaskform = this.formBuilder.group({
      tasktitle: ['', Validators.required],
      editor: ['', Validators.required],
    });
  }

  getUserDesignation() {
    if (this.taskDetailsView.userId) {
      this.adminService.userlist()
        .subscribe((data: any) => {
          if (this.taskDetailedView == true) {
            this.taskDetailedView = false;
          }
          const user = data.find(obj => obj.Id === this.taskDetailsView.userId);
          this.taskDetailsView.designation = user.Designation ? user.Designation : null;
        }, error => {
          this.toastrservice.typeError('Something went wrong. Please try again.');
        })
    }
  }

  editReopenTask(event, assigntaskmodal, assignedTask) {
    this.editTask = true;
    this.taskdescription = this.taskDetailsView.description;
    this.assigntaskform.setValue({
      tasktitle: this.taskDetailsView.name,
      editor: this.taskDetailsView.description
    })
    this.secondModal = this.modalDialog.open(assigntaskmodal, { size: 'lg', backdrop: 'static' })
    this.secondModal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        this.onCloseEditReopenTask();
      }
    });
    this.openedPopupCount === 2;
  }


  onEditReopenTask(form) {
    if (this.assigntaskform.valid) {
      this.isAssigningTask = true;
      const formdata = form.value;
      const postdata = {
        'ModBy': this.conf.getUserMailId(),
        'TaskDescription': formdata.editor,
        'TaskId': this.taskDetailsView.id,
        'TaskName': formdata.tasktitle,
        'TaskStatusId': this.taskDetailsView.taskStatusId,
        'TaskPriorityId': this.taskDetailsView.taskPriorityId
      }
      this.assignedTaskService.taskEditReopen(postdata).subscribe(
        success => {
          this.isAssigningTask = false;
          this.assigntaskform.reset();
          this.taskDetailsView.isReopenedTask = true;
          this.taskDetailsView.taskStatusId = 1;
          this.toastrservice.typeSuccess('Task is reopened successfully.');
          // this.modalRef.close();
          this.secondModal.close();
          this.taskDetailsView.receivers.forEach(element => {
            element.UserTaskStatusId = 1
          });
          this.taskDetailsView.name = formdata.tasktitle;
          this.taskDetailsView.description = formdata.editor;
          const sendDetails = {
            type: 'reopened',
            taskDetails: this.taskDetailsView
          }
          this.detailedViewCommunicationMessage(sendDetails);
        }, error => {
          this.isAssigningTask = false;
          this.assigntaskform.reset();
          Swal.fire({
            title: 'Error', text: 'Something went wrong. Please refresh the page and try again.',
            timer: 2000, showConfirmButton: false, allowOutsideClick: false
          });
        }
      );
    }
  }

  reopenSelfTask(task) {
    const repoenSelfTask = {
      'TaskId': this.taskDetailsView.id,
      'TaskName': this.taskDetailsView.name,
      'TaskDescription': this.taskDetailsView.description,
      'ModBy': ""
    }
    this.myTaskService.reopenSelfTask(repoenSelfTask).subscribe(data => {
      this.toastrservice.typeSuccess('Task is reopened successfully.');
      const sendDetails = {
        type: 'taskStatus',
        taskDetails: this.taskDetailsView
      }
      this.detailedViewCommunicationMessage(sendDetails);
      this.modalRef.close();
    },
      error => {
        console.log(error);
        this.toastrservice.typeError('Something went wrong. Please try again.');
      }
    );
  }

  changeRewardComment(rewardComment) {
    this.comment = rewardComment;
  }

  giveRewardAndArchiveTask(event) {
    this.giveReward(event, 'integrated');
  }

  giveReward(event, type) {
    if (this.selectedStar) {
      const comment = {
        'TaskId': this.taskDetailsView.id,
        'Comments': this.comment,
        'Reward': this.selectedStar.toString(),
      }

      if (type !== 'integrated') {
        this.isProvidingReward = true;
      } else {
        this.isProvidingRewardAndArchive = true;
      }

      this.assignedTaskService.updateCommentandReward(comment).subscribe(success => {
        this.toastrservice.typeCustom('#efce4a',
          this.selectedStar, ' Reward');
        if (type !== 'integrated') {
          this.taskDetailsView.reward = this.selectedStar.toString();
          this.taskDetailsView.rewardComment = this.comment;

          const sendDetails = {
            type: 'rewarded',
            taskDetails: this.taskDetailsView
          }
          this.detailedViewCommunicationMessage(sendDetails);
          this.modalRef.close();
          this.isProvidingReward = false;
          this.closeDetailedViewModalEvent.emit(true);
        } else if (type === 'integrated') {
          this.taskArchival(event, 'btn');
        }
      }, error => {
        this.isProvidingReward = false;
        this.isProvidingRewardAndArchive = false;
        this.toastrservice.typeError('Something went wrong. Please try again.');
      });
    } else {
      this.toastrservice.typeInfo('Select atleast 1 Star.');
    }
  }

  givePriority(event, task) {
    event.stopPropagation();
    if (task.bellCount < this.maximumBellCount) {
      const incrementPriority = task.bellCount + 1;
      const priorityCount = {
        'TaskId': task.id,
        'UserId': this.userId, // loggedin user details
        'TaskAction': this.settings.assignedTaskListComponent.priority_TaskAction,
        'TaskValue': incrementPriority.toString(),
        'ModBy': this.userMailDetails
      }
      this.assignedTaskService.changeTaskAction(priorityCount).subscribe(
        success => {
          this.taskDetailsView.bellCount = incrementPriority;
          this.toastrservice.typeCustom('#0cc27e',
            incrementPriority, ' Priority');
          const sendDetails = {
            type: 'priority',
            taskDetails: this.taskDetailsView
          }
          this.detailedViewCommunicationMessage(sendDetails);
        },
        error => {
          this.toastrservice.typeError('Something went wrong while assigning priority. Please try again.');
        }
      );
    } else if (task.bellCount >= this.maximumBellCount) {
      Swal.fire({
        title: 'Warning', html:
          '<span style="color:#0cc27e;font-size:30px;font-weight: 600">'
          + this.maximumBellCount + '</span> is the maximum priority for task.'
      });
    }
    return false;
  }

  taskArchival(event, type) {
    if (this.taskDetailsView.taskType === 'assigned') {
      this.assignedtaskArchival(event, type)
    } else {
      this.receivedTaskArchival(event, type)
    }
  }

  assignedtaskArchival(event, type): any {
    if (type === 'icon') {
      event.stopPropagation();
    }
    const taskArchival = {
      'TaskId': this.taskDetailsView.id,
      'UserId': this.userId,
      'TaskAction': this.settings.assignedTaskListComponent.taskArchival_TaskAction,
      'TaskValue': this.settings.assignedTaskListComponent.taskArchival_TaskValue,
      'ModBy': this.userMailDetails
    }
    this.assignedTaskService.changeTaskAction(taskArchival).subscribe(
      success => {
        this.modalRef.close();
        this.isProvidingRewardAndArchive = false;
        this.toastrservice.typeSuccess('Task is archived successfully.');
        const sendDetails = {
          type: 'archived',
          taskDetails: this.taskDetailsView
        }
        this.detailedViewCommunicationMessage(sendDetails);
      },
      error => {
        let subErrorText = 'Please try again.';
        console.log(error);
        if (type === 'btn') {
          this.isProvidingRewardAndArchive = false;
          subErrorText = 'Please refresh the page and try again.'
        }
        this.toastrservice.typeError('Something went wrong while archiving the task.' + ' ' + subErrorText);
      }
    );
    return false;
  }

  receivedTaskArchival(event, type): any {
    event.stopPropagation();
    const stat = {
      'TaskId': this.taskDetailsView.id,
      'UserId': this.userId,
      'TaskAction': this.settings.receivedTaskListComponent.taskArchival_TaskAction,
      'TaskValue': this.settings.receivedTaskListComponent.taskArchival_TaskValue,
      'ModBy': this.userMailDetails
    }
    this.receivedtaskservice.changeTaskEvent(stat).subscribe(
      successResponse => {
        if (!this.taskDetailsView.isRead) {
          this.componentsCommunicationService.updateReceivedTaskUnreadCount({
            isCallApi: false, event: 'UnReadToRead'
          })
        }

        this.modalRef.close();
        this.toastrservice.typeSuccess('Task is archived successfully.');
        const sendDetails = {
          type: 'archived',
          taskDetails: this.taskDetailsView
        }
        this.detailedViewCommunicationMessage(sendDetails);
      },
      errorResponse => console.log(errorResponse.error)
    );
    return false;
  }


  onCloseEditReopenTask() {
    this.secondModal.close();
    this.assigntaskform.reset();
    const element = this.renderer.createElement('input');
    element.focus();

  }

  detailedViewCommunicationMessage(taskDetails) {
    if (this.taskDetailsView.page === 'dashboard') {
      this.detailedViewCommunicationService.refreshTaskStats(taskDetails);
      this.selectedStar = 0
    } else {
      this.detailedViewCommunicationEvent.emit(taskDetails);
      this.selectedStar = 0
    }
  }

  onShowCommentModal(task, canSendComments, event) {
    event.preventDefault();
    event.stopPropagation();
    this.openedPopupCount = 1;
    this.isShowCommentsModal = true;
    this.canSendComments = canSendComments;
    this.detailedViewTaskaskDetails.createUserDisplayName = this.taskDetailsView.userDisplayName;
    this.detailedViewTaskaskDetails.createUserImagePath = this.taskDetailsView.userImagePath;
    this.detailedViewTaskaskDetails.taskStatusId = this.taskDetailsView.taskStatusId;
    this.selectedTaskForComment = {
      task: this.detailedViewTaskaskDetails,
      taskType: this.taskDetailsView.taskType
    }
  }

  closeCommentModal(event) {
    if (event) {
      this.isShowCommentsModal = false;
      this.canSendComments = true;
      this.selectedTaskForComment = {};
    }
  }

  openVideoModal(attachment) {
    this.isOpenVideoModal = true;
    this.videoURL = attachment.URL;
  }

  closeVideoModal(event) {
    if (event) {
      this.isOpenVideoModal = false;
    }
  }

  sendCommunicationMessage(event) {
    if (event === 'closeModal') {
      this.isShowCommentsModal = false;
      this.canSendComments = true;
      this.selectedTaskForComment = {};
    } else if (event === 'changeStatus') {
      this.taskDetailsView.isNewComment = this.taskDetailsView.isNewComment === true ? false : this.taskDetailsView.isNewComment;
    }
  }

  onImageRightClick(event) {
    return false;
  }

  quillTextChange(event) {
    this.taskDescriptionContent = event.text;
    if (!this.taskDescriptionContent.trim().length) {
      this.assigntaskform.controls['editor'].setErrors({ 'incorrect': true });
      this.assigntaskform.updateValueAndValidity();
    } else {
      this.assigntaskform.get('editor').clearValidators();
      this.assigntaskform.get('editor').updateValueAndValidity();
    }
  }

  assignedClickEvent(event) {
    event.preventDefault();
    event.stopPropagation()
    return false;
  }

  downloadAttachment(URL, event) {
    event.target.classList.add('cus-beat-effect');
    const sendObj = {
      'AttachmemtLink': URL
    }

    this.commonService.downloadAttachment(sendObj)
      .subscribe((data: Blob) => {
        const fileName = URL.split('/').pop();
        onCreateObjectURLAndDownloadFile(data, fileName, event);
      }, error => {
        console.log(error);
        event.target.classList.remove('cus-beat-effect');
        this.toastrservice.typeError('Something went wrong. Please try again.');
      });
  }

}
