import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { Configuration } from './configuration.constants';


export class FeatureCollection {
    type: string;
    features: Feature[];
}

export class Feature {
    type: string;
    properties: FeatureProperty;
    geometry: FeatureGeometry;
}

export class FeatureProperty {
    text: string;
    value: number;
    tooltip: any;
}

export class FeatureGeometry {
    type: string;
    coordinates: number[];
}

const color: Object = {
    'UAE': 1242,
    'W. Sahara': 1245,
    'United Arab Emirates': 1368,
    'Somaliland': 4578,
    'Falkland Is.': 1258,
    'Fr. S. Antarctic Lands': 45789,
    'Eq. Guinea': 4578,
    'Côte d\'Ivoire': 1242,
    'Antarctica': 20147,
    'Liberia': 13632,
    'Afghanistan': 20312,
    'Albania': 13262,
    'Algeria': 214080,
    'Angola': 128564,
    'Anguilla': 69.3,
    'Antigua and Barbuda': 1242,
    'Argentina': 540164,
    'Armenia': 10279,
    'Aruba': 66.3,
    'Australia': 1444189,
    'Austria': 437123,
    'Azerbaijan': 74145,
    'Bahamas, The': 8657,
    'Bahrain': 33862,
    'Bangladesh': 185415,
    'Barbados': 4348,
    'Belarus': 76139,
    'Belgium': 534672,
    'Belize': 1693,
    'Benin': 8701,
    'Bermuda': 92.2,
    'Bhutan': 2092,
    'Bolivia': 34425,
    'Bosnia and Herz.': 17977,
    'Botswana': 15789,
    'Brazil': 2353025,
    'British Virgin Islands': 87.2,
    'Brunei': 15102,
    'Bulgaria': 55837,
    'Burkina Faso': 12503,
    'Burundi': 3094,
    'Cambodia': 16551,
    'Cameroon': 31669,
    'Canada': 1788717,
    'Cape Verde': 1899,
    'Cayman Islands': 49.1,
    'Central African Rep.': 1786,
    'Chad': 13947,
    'Chile': 257968,
    'China': 10380380,
    'Colombia': 384901,
    'Comoros': 717,
    'Dem. Rep. Congo': 34677,
    'Congo': 13502,
    'Cook Islands': 78.5,
    'Costa Rica': 48144,
    'Ivory Coast': 32000,
    'Croatia': 57159,
    'Cuba': 75.2,
    'Curacao': 83.8,
    'Cyprus': 23269,
    'Czech Rep.': 205658,
    'Denmark': 340806,
    'Djibouti': 1589,
    'Dominica': 526,
    'Dominican Rep.': 64077,
    'Ecuador': 100755,
    'Egypt': 286435,
    'El Salvador': 25329,
    'Equatorial Guinea': 14308,
    'Eritrea': 3858,
    'Estonia': 25953,
    'Ethiopia': 52335,
    'European Union': 73.2,
    'Falkland Islands': 0,
    'Faroe Islands': 55,
    'Fiji': 4212,
    'Finland': 271165,
    'France': 2846889,
    'French Polynesia': 76.1,
    'Gabon': 17182,
    'Gambia, The': 825,
    'Georgia': 16535,
    'Germany': 3859547,
    'Ghana': 38648,
    'Gibraltar': 100,
    'Greece': 238023,
    'Greenland': 2160,
    'Grenada': 862,
    'Guatemala': 60422,
    'Guernsey': 87,
    'Guinea': 6529,
    'Guinea-Bissau': 1024,
    'Guyana': 2997,
    'Haiti': 8711,
    'Honduras': 19511,
    'Hong Kong': 289628,
    'Hungary': 137104,
    'Iceland': 16693,
    'India': 2049501,
    'Indonesia': 888648,
    'Iran': 404132,
    'Iraq': 221130,
    'Ireland': 246438,
    'Isle of Man': 88,
    'Israel': 303771,
    'Italy': 2147952,
    'Jamaica': 13787,
    'Japan': 4616335,
    'Jersey': 97,
    'Jordan': 35765,
    'Kazakhstan': 212260,
    'Kenya': 60770,
    'Kiribati': 181,
    'Dem. Rep. Korea': 28000,
    'Korea': 1416949,
    'Kosovo': 7318,
    'Kuwait': 172350,
    'Kyrgyzstan': 7402,
    'Lao PDR': 11676,
    'Latvia': 31970,
    'Lebanon': 49919,
    'Lesotho': 2159,
    'Libya': 41148,
    'Liechtenstein': 55,
    'Lithuania': 48232,
    'Luxembourg': 62395,
    'Macau': 90.6,
    'Macedonia': 11342,
    'Madagascar': 10595,
    'Malawi': 4263,
    'Malaysia': 326933,
    'Maldives': 2854,
    'Mali': 11915,
    'Malta': 10582,
    'Marshall Islands': 193,
    'Mauritania': 5079,
    'Mauritius': 13240,
    'Mexico': 1282725,
    'Moldova': 7944,
    'Monaco': 95.1,
    'Mongolia': 11981,
    'Montenegro': 4462,
    'Montserrat': 75.3,
    'Morocco': 109201,
    'Mozambique': 16684,
    'Myanmar': 62802,
    'Namibia': 13353,
    'Nepal': 19637,
    'Netherlands': 866354,
    'New Caledonia': 70.9,
    'New Zealand': 198118,
    'Nicaragua': 11707,
    'Niger': 8025,
    'Nigeria': 573652,
    'Niue': 49.5,
    'Norway': 500244,
    'Oman': 77755,
    'Pakistan': 250136,
    'Palau': 269,
    'Panama': 43784,
    'Papua New Guinea': 16060,
    'Paraguay': 29704,
    'Peru': 202948,
    'Philippines': 284927,
    'Poland': 546644,
    'Portugal': 230012,
    'Puerto Rico': 103500,
    'Qatar': 210002,
    'Romania': 199950,
    'Russia': 1857461,
    'Rwanda': 8012,
    'Saint Kitts and Nevis': 841,
    'Saint Lucia': 1356,
    'Saint Martin': 84,
    'Saint Vincent and the Grenadines': 736,
    'Samoa': 826,
    'San Marino': 1786,
    'Sao Tome and Principe': 60.6,
    'Saudi Arabia': 752459,
    'Senegal': 15584,
    'Serbia': 43866,
    'Seychelles': 1420,
    'Sierra Leone': 5033,
    'Singapore': 308051,
    'Sint Maarten': 81.3,
    'Slovakia': 99971,
    'Slovenia': 49506,
    'Solomon Islands': 1155,
    'Somalia': 1071,
    'South Africa': 350082,
    'Spain': 1406855,
    'Sri Lanka': 74588,
    'Sudan': 73816,
    'S. Sudan': 12833,
    'Suriname': 5297,
    'Swaziland': 3676,
    'Sweden': 570137,
    'Switzerland': 712050,
    'Syria': 71998,
    'Taiwan': 529550,
    'Tajikistan': 9242,
    'Tanzania': 47932,
    'Thailand': 373804,
    'Timor-Leste': 4478,
    'Togo': 4604,
    'Tonga': 454,
    'Trinidad and Tobago': 28788,
    'Tunisia': 48553,
    'Turkey': 806108,
    'Turkmenistan': 47932,
    'Turks and Caicos Islands': 74.8,
    'Tuvalu': 38,
    'Uganda': 27616,
    'Ukraine': 401647,
    'United Kingdom': 2945146,
    'United States': 17418925,
    'Uruguay': 55143,
    'Uzbekistan': 62619,
    'Vanuatu': 812,
    'Venezuela': 205787,
    'Vietnam': 186049,
    'U.S. Virgin Islands': 80,
    'West Bank and Gaza': 82.7,
    'Western Sahara': 40,
    'Yemen': 43229,
    'Zambia': 26758,
    'Zimbabwe': 13672
};

@Injectable()
export class MapService {
    constructor(private http: HttpClient, private _configuration: Configuration) {
        
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    public userWorkGroupWithGeoLocation<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._userWorkGroupWithGeoLocation, {
            headers: this._configuration.getHeaders()
        });
    }

    getColors(): Object {
        return color;
    }

}
