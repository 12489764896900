
export let VerticalTaskTimeLineView = [
    {
        description: "Arjun Assigned a task to Sam",
        type: 'Assigned',
        date: '12/03/1996 12:40AM'

    },
    {
        description: "Arun  commented on Task ",
        type: 'Commented',
        date: '12/03/1997 1:02PM'

    },
    {
        description: "Priority",
        type: 'Priority',
        date: '12/03/1990 4:45AM'

    },
    {
        description: "Reopen a task",
        type: 'Reopened',
        date: '12/03/1998 3:45PM'

    },

    {
        description: "Task Completed",
        type: 'Completed',
        date: '12/03/1998 9:45PM'

    },
    {
        description: "Reported",
        type: 'Reported',
        date: '12/03/1998 6:36AM'

    }

]

