
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterbyname',
})

export class FindInUserList implements PipeTransform {
  transform(items: any[], searchByName, namefromtextbox): any {
    if (namefromtextbox) {
      $('.selectedUserImage').remove(); 
      $('.highlightLetter').removeClass('active');
      return items.filter((item: any) => {
        if (typeof item['displayName'] === 'string' &&
          item['displayName'].toLowerCase().indexOf(namefromtextbox.toLowerCase()) > -1) {
          return true;
        }
        return false;
      });
    }

    // console.log('searchByName', searchByName);
    if (!searchByName || searchByName === 'All') {
      if (items !== undefined) {
        return items.sort((a, b) => {
          if (a.displayName < b.displayName) return -1;
          else if (a.displayName > b.displayName) return 1;
          else return 0;
        });
      }
    } else if (searchByName.length === 1) {
      return items.filter((item: any) => {
        // console.log('Item', item);
        if (typeof item['displayName'].toLowerCase() === 'string' &&
          (item['displayName'].startsWith(searchByName) || item['displayName'].startsWith(searchByName.toLowerCase()))) {
          return true;
        }
        return false;
      });
    } else if (searchByName === 'NotInScope') {
      return items;
    } else {
      return items.filter((item: any) => {
        if (typeof item['department'] === 'string' &&
          item['department'].toLowerCase().indexOf(searchByName.toLowerCase()) > -1) {
          return true;
        }
        return false;
      });
    }
  }
}
