export let navbarSkeleton: any = 
    {
    notify_image:{
        width: '45px', height: '45px', 'background-color': '#dedfe1','margin': '10px 10px 0px 20px',
        'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
    },
    notify_name:{
        width: '60%', height: '10px', 'background-color': '#dedfe1', margin: '0px','border-radius':'0px',
        'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
    },
    notify_desc:{
        width: '70%', height: '10px', 'background-color': '#dedfe1','border-radius':'0px',
        'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
    }
}

