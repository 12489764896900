export let taskDetailViewSkeleton :any ={
detailView_image :{
    width: '90px', height: '90px', 'background-color': '#dedfe1', margin: '5px',
    'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
},
detailView_name :{
    width: '70%', height: '15px', 'background-color': '#dedfe1', margin: '0px','border-radius':'0px',
    'background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
},
detailView_sub:{
    width: '90%', height: '15px', 'background-color': '#dedfe1', margin: '0px','border-radius':'0px',
    'margin-top':'15px','background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
},
detailView_desc:{
    width: '100%', height: '15px', 'background-color': '#dedfe1', margin: '0px','border-radius':'0px',
    'margin-top':'20px','background-image': 'linear-gradient(90deg, #ddd 0px,#e8e8e8 40px,#ddd 80px)'
}
}