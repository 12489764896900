import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Renderer2, Input, ViewChild, ViewContainerRef, AfterViewInit, HostListener, ElementRef, AfterViewChecked, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CommentService } from '../services/comment.service';
import { Configuration } from '../services/configuration.constants';
import { UploadService } from '../services/upload.service';
import { AppConfig } from '../app.config';
import Swal from 'sweetalert2';
import { DetailedViewCommunicationService } from '../services/detailedViewCommunication.service'

import { ReceivedTaskService } from '../services/receivedtask.service'
import { AssignedTaskService } from '../services/assignedtask.service';
import { CommentsJSONResponseModel } from '../shared/models/comments.JSON.model'
import { NGXToastrService } from '../services/toastr.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../services/admin.service';
import { MyTaskService } from '../services/mytask.service';
import * as Chartist from 'chartist';
// import { ChartType, ChartEvent } from "ng-chartist/dist/chartist.component";
import { ActivityLogSidebarCommunicationService } from '../services/activity-log-sidebar.service';
import { TaskDetailedViewJSONResponseModel } from '../shared/models/taskDetailedView.JSON.model';
import { onCreateObjectURLAndDownloadFile } from '../shared/utils/common-functions';

import { ComponentsCommunicationService } from '../services/componentsCommunication.service';
import * as verticalTimeLineView from '../detailedview-sidebar/detailedview-sidebar.config';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ActivityLogService } from 'app/services/activityLog.service';
import { ActivityLogJSONResponseModel } from './activitylog.model';
@Component({
    selector: 'app-detailedview-sidebar',
    templateUrl: './detailedview-sidebar.component.html',
    styleUrls: ['./detailedview-sidebar.component.scss']
})
export class DetailedviewSidebarComponent implements OnInit, AfterViewChecked {
    verticalView = verticalTimeLineView.VerticalTaskTimeLineView;
    activeTab = 'timelineTab';
    taskDetails: any;
    commentDetaileView: any;
    //video Modal
    videoURL: any;
    isOpenVideoModal: boolean;
    isLoadingTimelineDetails: boolean;
    settings: any = AppConfig.settings;
    secondModal: NgbModalRef;
    modalRef: NgbModalRef;
    closeResult: string;
    openedPopupCount = 0;
    isRewardOrArchive = false;
    isErrorinComments: boolean;
    comment;
    selectedStar = 0;
    @Input() taskDetailsView;
    @Input() openNotifyView = true;
    @ViewChild('detailedView', { static: true }) detailedView: ElementRef;
    @ViewChild('t', { read: false, static: false })
    public tabSet: NgbTabset;
    @Input() canSendComments = true;
    loggedInUserId: number;
    loggedInUserImage: string;
    loggedInUserMailId: string;
    commentList: any = [];
    userComment: any = '';
    commentLoading = true;
    noComments = true;
    isValidAttachment = false;
    extensionError = false;
    biggerFileSize = false;
    isProvidingRewardAndArchive = false;
    attachmentExtension: any;
    commentSending: boolean;
    isNotCommentSent: boolean;
    attachedFileFormData: any;
    uploadedFileName: string;
    maximumBellCount = this.settings.tasksettings.MAX_BELL;
    userId: any;
    receivedData: Subscription;

    userMailDetails: any;
    isShowCommentsModal = false;
    selectedTaskForComment;
    editTask;
    isAssigningTask = false;
    taskdescription;
    taskDescriptionContent;
    assigntaskform: FormGroup;
    data;
    taskListData;
    receivedSidebar: Observable<boolean>;
    priorityNotificationText = 'Bell Given';
    audioNotificationText = 'Audio';
    reopenedNotificationText = 'Reopened';
    assignedNotificationText = 'Assigned';
    commentedNotificationText = 'Comment Read';
    improgressNotificationText = 'InProgress';
    completedNotificationText = 'Completed';
    reportedNotificationText = 'Reported';
    hierarchyRequestedNotificationText = 'Hierarchy Requested';
    hierarchyRejectedNotificationText = 'Hierarchy Rejected';
    hierarchyAcceptedNotificationText = 'Hierarchy Accepted';
    hierarchyRemovedNotificationText = 'Hierarchy Removed';
    onHoldNotificationText = 'OnHold';
    createdTaskNotificationText = 'created';
    rewardedNotificationText = 'Task Rewarded';
    severityNotificationText = 'Severity changed';
    // commentReadedText = 'Comment Readed';
    addedFyimemberText = 'as FYI Member';
    editedNotificationText = "Task Edited";
    maxFileSize = this.settings.tasksettings.MAX_UPLOAD_SIZE;
    maxFileSizeText = this.settings.tasksettings.MaxFileSizeText;
    activityList: any;
    onHoldData: any;
    scrHeight: number;
    timeTook: any;
    onHoldTime: string;
    constructor(private toastrservice: NGXToastrService, private commentService: CommentService, private receivedtaskservice: ReceivedTaskService, private renderer: Renderer2, private activityLogService: ActivityLogService,
        private conf: Configuration, private myTaskService: MyTaskService, private adminService: AdminService, private formBuilder: FormBuilder, private modalDialog: NgbModal, private cdr: ChangeDetectorRef, private assignedTaskService: AssignedTaskService,
        private uploadService: UploadService, private taskDetailedViewService: ActivityLogSidebarCommunicationService, private detailedViewCommunicationService: DetailedViewCommunicationService,
        private componentsCommunicationService: ComponentsCommunicationService, private elRef: ElementRef, private ChangeDetectorRef: ChangeDetectorRef, private commonService: CommonService) {

    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.elRef.nativeElement.contains(event.target)) {
            this.renderer.removeClass(this.elRef.nativeElement.querySelector('.detailedViewSideBar'), 'open');
        }
    }

    ngOnInit() {
        this.userId = this.conf.getuserId();
        this.userMailDetails = this.conf.getUserMailId();
        this.createForm();
        this.comment = 'Good Work!!';
        this.taskListData = this.verticalView;

        this.receivedData = this.taskDetailedViewService.receivedData.subscribe((data: any) => {

            if (data) {
                if (data.isOpenSidebarModal) {
                    this.activityList = [];
                    this.renderer.addClass(this.elRef.nativeElement.querySelector('.detailedViewSideBar'), 'open');
                    this.taskDetailsView = new TaskDetailedViewJSONResponseModel().deserialise(data.taskDetails);
                    if (this.taskDetailsView.taskType !== 'scheduled') {
                        this.isLoadingTimelineDetails = true;
                        this.getActivityLogsByTaskId(this.taskDetailsView.id);
                        this.getElapsedOnHoldTime(this.taskDetailsView.id);
                    }
                    this.activeTab = "timelineTab";
                    this.tabSet.select("timelineTab");
                    this.clearAttachment();
                    this.selectedStar = 0;
                    this.commentList = [];
                    this.commentLoading = true;
                    if (this.taskDetailsView.userId) {
                        this.getUserDesignation();
                    }
                    else {
                        this.showTaskDetailedView();
                    }
                }
            }
        });
    }

    ngOnChanges() {
        // if (this.taskDetailsView) {
        //     this.showComments(this.taskDetailsView);
        // }
    }


    onClosingActivitySidebar() {
        this.renderer.removeClass(this.elRef.nativeElement.querySelector('.detailedViewSideBar'), 'open');
        const data = { tasktype: '', taskDetails: [], isOpenSidebarModal: false }
        this.taskDetailedViewService.getUserDetails(data);
    }

    showComments() {
        this.noComments = false;
        this.isErrorinComments = false;
        this.commentLoading = true;
        this.commentList = [];
        if (this.taskDetailsView) {
            const taskDetails = this.taskDetailsView;
            taskDetails.createUserDisplayName = this.taskDetailsView.userDisplayName;
            taskDetails.createUserImagePath = this.taskDetailsView.userImagePath;
            taskDetails.taskStatusId = this.taskDetailsView.taskStatusId;
            this.taskDetails = new CommentsJSONResponseModel().deserialise(taskDetails);
            this.userComment = '';
            this.cdr.detectChanges();
            this.noComments = true;
            this.loggedInUserImage = this.conf.getUserImage();
            this.loggedInUserId = this.conf.getuserId();
            const loggedInUserMailId = this.conf.getUserMailId();
            this.isNotCommentSent = false;
            if (this.taskDetails.IsNewComment) {
                const commentRead = {
                    'UserId': this.loggedInUserId,
                    'TaskId': this.taskDetails.taskId,
                    'TaskAction': taskDetails.taskType === "received" ? this.settings.receivedTaskListComponent.comments_TaskAction : this.settings.assignedTaskListComponent.newComment_TaskAction,
                    'TaskValue': taskDetails.taskType === "received" ? this.settings.receivedTaskListComponent.comments_TaskValue : this.settings.assignedTaskListComponent.newComment_TaskValue,
                    'ModBy': loggedInUserMailId
                }
                if (this.taskDetailsView.taskType === "myTask" || this.taskDetailsView.taskType === "received") {
                    this.commentService.changeTaskCommentStatus(commentRead).subscribe(success => {
                        const sendDetails = {
                            type: 'commentStatus',
                            taskDetails: this.taskDetailsView
                        }
                        this.taskDetailsView.isNewComment = false;
                        this.detailedViewCommunicationMessage(sendDetails);

                    }, error => {
                        console.log(error);
                    });
                }
                else {
                    this.assignedTaskService.changeAssignedTaskCommentStatus(commentRead).subscribe(success => {
                        const sendDetails = {
                            type: 'commentStatus',
                            taskDetails: this.taskDetailsView
                        }
                        this.taskDetailsView.isNewComment = false;
                        this.detailedViewCommunicationMessage(sendDetails);
                    }, error => {
                        console.log(error);
                    });
                }
            }

            this.commentService.getComments(taskDetails.id).subscribe(data => {
                this.noComments = false
                this.commentLoading = false;
                this.commentList = data;
            }, error => {
                this.commentLoading = false;
                this.noComments = true;
                this.isErrorinComments = true;
                this.toastrservice.typeError('Something went wrong. Please Refresh the Page')
            });
        }
    }

    getUserDesignation() {
        if (this.taskDetailsView.userId) {
            this.adminService.userlist()
                .subscribe((data: any) => {
                    const user = data.find(obj => obj.Id === this.taskDetailsView.userId);
                    this.taskDetailsView.designation = user.Designation ? user.Designation : null;
                    this.showTaskDetailedView();
                })
        }

    }

    getActivityLogsByTaskId(taskId) {
        this.activityLogService.getAllActivityLogsByTaskId(taskId).subscribe((data: any) => {
            if (data && data.length) {
                this.activityList = data.map(obj => new ActivityLogJSONResponseModel().deserialise(obj));
            }
            else {
                this.activityList = [];
            }
            this.isLoadingTimelineDetails = false;
        }, error => {
            this.toastrservice.typeError("Something went wrong while getting activities.please refresh the page")
        })
    }
    getElapsedOnHoldTime(taskId) {
        this.activityLogService.getElapsedOnHoldTimeByTaskId(taskId).subscribe((data: any) => {
            this.onHoldData = data.OnHoldCount;
            const [hours, minutes] = data.ElapsedTime.split(":");
            this.timeTook = `${hours}hrs ${minutes}mins`;
            const [hours2, minutes2] = data.OnHoldTime.split(":");
            this.onHoldTime = `${hours2}hrs ${minutes2}mins`;
            // this.onHoldTime = data.OnHoldTime;
        })
    }

    showTaskDetailedView() {
        if (this.taskDetailsView) {
            // this.showComments();
            if (this.taskDetailsView.taskType === 'received' && !this.taskDetailsView.isRead) {
                this.readTask();
            }
        }
    }


    taskStatusChange(event, task): any {
        const id = task.taskStatusId;
        event.stopPropagation();
        if (event.target.defaultValue !== undefined) {
            if (event.target.defaultValue !== '3') {
                $(event.target.nextElementSibling).css('display', 'inline-flex');
                this.updateTaskStatus(event, task);
            } else if (event.target.defaultValue === '3') {
                task.taskStatusId = 5;
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'Do you want to change the task status to Completed?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0CC27E',
                    cancelButtonColor: '#FF586B',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    confirmButtonClass: 'btn btn-success btn-raised mr-2',
                    cancelButtonClass: 'btn btn-danger btn-raised',
                    buttonsStyling: false,
                    allowOutsideClick: false
                }).then(() => {
                    task.taskStatusId = 3;
                    $(event.target.nextElementSibling).css('display', 'inline-flex');
                    this.updateTaskStatus(event, task);
                }, function (dismiss) {
                    task.taskStatusId = id;
                    $(event.target.nextElementSibling).css('display', 'none');
                    $(event.target).parent().removeClass('focus');
                    event.stopPropagation();
                    event.preventDefault();
                });
            }
        }
    }

    updateTaskStatus(event, tasklist) {
        this.taskDetailsView.taskStatusId = Number(event.target.defaultValue);
        const stat = {

            'TaskId': tasklist.id,
            'UserId': this.userId,
            'TaskAction': this.settings.receivedTaskListComponent.taskStatus_TaskAction,
            'TaskValue': event.target.defaultValue.toString(),
            'ModBy': this.userMailDetails
        }

        this.receivedtaskservice.changeTaskEvent(stat).subscribe(
            successResponse => {

                if (!tasklist.isRead) {
                    this.componentsCommunicationService.updateReceivedTaskUnreadCount({
                        isCallApi: false, event: 'UnReadToRead'
                    })
                }
                $(event.target.nextElementSibling).css('display', 'none');
                const sendDetails = {
                    type: 'taskStatus',
                    taskDetails: this.taskDetailsView
                }
                this.getActivityLogsByTaskId(this.taskDetailsView.id);
                this.detailedViewCommunicationMessage(sendDetails);
            },
            errorResponse => {
                this.taskDetailsView.taskStatusId = Number(tasklist.taskStatusId);
                $(event.target.nextElementSibling).css('display', 'inline-flex');
                console.log(errorResponse.error);
                this.toastrservice.typeError('Something went wrong. Please try again.');
            }
        );
    }

    readTask() {
        const changereadstatus = {
            'TaskId': this.taskDetailsView.id,
            'UserId': this.userId,
            'TaskAction': this.settings.receivedTaskListComponent.readStatus_TaskAction,
            'TaskValue': this.settings.receivedTaskListComponent.readStatus_TaskValue,
            'ModBy': this.userMailDetails
        }
        this.receivedtaskservice.changeTaskEvent(changereadstatus).subscribe(
            successResponse => {
                this.taskDetailsView.isRead = true;
                const sendDetails = {
                    type: 'readTask',
                    taskDetails: this.taskDetailsView
                }
                this.componentsCommunicationService.updateReceivedTaskUnreadCount({
                    isCallApi: false, event: 'UnReadToRead'
                })
                this.detailedViewCommunicationMessage(sendDetails);
            },
            errorResponse => console.log(errorResponse.error)
        );

    }

    createForm() {
        this.assigntaskform = this.formBuilder.group({
            tasktitle: ['', Validators.required],
            editor: ['', Validators.required],
        });
    }

    toggleActiveTab(tabName) {
        this.activeTab = tabName;
        this.tabSet.select(tabName);
    }

    editReopenTask(event, assigntaskmodal, assignedTask) {
        this.editTask = true;
        this.taskdescription = this.taskDetailsView.description;
        this.assigntaskform.setValue({
            tasktitle: this.taskDetailsView.name,
            editor: this.taskDetailsView.description
        })
        this.onClosingActivitySidebar();
        this.secondModal = this.modalDialog.open(assigntaskmodal, { size: 'lg', backdrop: 'static' })
        this.secondModal.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            if (reason === ModalDismissReasons.ESC) {
                this.onCloseEditReopenTask();
            }
        });
        this.openedPopupCount === 2;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
    }

    ngAfterViewChecked() {
        //your code to update the model
        this.cdr.detectChanges();
    }

    getDescriptionHeight(footerHeight, header, detailedViewSideBar) {
        this.getScreenSize();
        // const minHeight = ((this.scrHeight * 80 / 100) - (footerHeight.offsetHeight + 100))
        const minHeight = detailedViewSideBar.offsetHeight - (header.offsetHeight + footerHeight.offsetHeight + 48);
        return minHeight > 50 ? minHeight + 'px' : '50px';
    }

    getCommentsBodyHeight(footerHeight, detailedViewSideBar) {
        this.getScreenSize();
        // const minHeight = ((this.scrHeight * 80 / 100) - (footerHeight.offsetHeight + 10))
        const minHeight = detailedViewSideBar.offsetHeight - (footerHeight.offsetHeight + 52);
        return minHeight > 85 ? minHeight + 'px' : '85px';
    }

    onEditReopenTask(form) {
        if (this.assigntaskform.valid) {
            this.isAssigningTask = true;
            const formdata = form.value;
            const postdata = {
                'ModBy': this.conf.getUserMailId(),
                'TaskDescription': formdata.editor,
                'TaskId': this.taskDetailsView.id,
                'TaskName': formdata.tasktitle,
                'TaskStatusId': this.taskDetailsView.taskStatusId,
                'TaskPriorityId': this.taskDetailsView.taskPriorityId,

            }
            this.assignedTaskService.taskEditReopen(postdata).subscribe(
                success => {
                    this.isAssigningTask = false;
                    this.assigntaskform.reset();
                    this.taskDetailsView.isReopenedTask = true;
                    this.taskDetailsView.taskStatusId = 1;
                    this.toastrservice.typeSuccess('Task is reopened successfully.');
                    this.secondModal.close();
                    if (this.taskDetailsView.receivers && this.taskDetailsView.receivers.length) {
                        this.taskDetailsView.receivers.forEach(element => {
                            element.UserTaskStatusId = 1
                        });
                    }
                    const sendDetails = {
                        type: 'reopened',
                        taskDetails: this.taskDetailsView
                    }
                    this.getActivityLogsByTaskId(this.taskDetailsView.id);
                    this.detailedViewCommunicationMessage(sendDetails);
                }, error => {
                    this.isAssigningTask = false;
                    this.assigntaskform.reset();
                    Swal.fire({
                        title: 'Error', text: 'Something went wrong. Please refresh the page and try again.',
                        timer: 2000, showConfirmButton: false, allowOutsideClick: false
                    }).then();
                }
            );
        }
    }

    reopenSelfTask(task) {
        const repoenSelfTask = {
            'TaskId': this.taskDetailsView.id,
            'TaskName': this.taskDetailsView.name,
            'TaskDescription': this.taskDetailsView.description,
            'ModBy': ""
        }
        this.myTaskService.reopenSelfTask(repoenSelfTask).subscribe(data => {
            this.toastrservice.typeSuccess('Task is reopened successfully.');
            const sendDetails = {
                type: 'taskStatus',
                taskDetails: this.taskDetailsView
            }
            this.getActivityLogsByTaskId(this.taskDetailsView.id);
            this.taskDetailsView.taskStatusId = 1;
            this.detailedViewCommunicationMessage(sendDetails);
        },
            error => {
                console.log(error);
                this.toastrservice.typeError('Task can not be archived. Please try again.');
            }
        );
    }

    changeRewardComment(rewardComment) {
        this.comment = rewardComment;
    }

    giveRewardAndArchiveTask(event) {
        this.giveReward(event, 'integrated');
    }

    giveReward(event, type) {
        if (this.selectedStar) {
            const comment = {
                'TaskId': this.taskDetailsView.id,
                'Comments': this.comment,
                'Reward': this.selectedStar.toString(),
            }
            this.assignedTaskService.updateCommentandReward(comment).subscribe(success => {
                this.toastrservice.typeSuccess(this.selectedStar + ' Reward is given.')
                if (type !== 'integrated') {
                    this.taskDetailsView.reward = this.selectedStar.toString();
                    this.taskDetailsView.rewardComment = this.comment
                    const sendDetails = {
                        type: 'rewarded',
                        taskDetails: this.taskDetailsView
                    }
                    this.getActivityLogsByTaskId(this.taskDetailsView.id);
                    this.detailedViewCommunicationMessage(sendDetails);
                } else if (type === 'integrated') {
                    this.taskArchival(event, 'btn');
                }
            }, error => {
                this.toastrservice.typeError('Reward cannot be given. Please Try Again.');
            });
        } else {
            this.toastrservice.typeError('Select atleast 1 Star.');
        }
    }

    taskArchival(event, type) {
        if (this.taskDetailsView.taskType === 'assigned') {
            this.assignedtaskArchival(event, type)
        } else {
            this.receivedTaskArchival(event, type)
        }
    }


    downloadAttachment(URL, event) {
        event.stopPropagation();
        event.preventDefault();
        event.target.classList.add('cus-beat-effect');
        const sendObj = {
            'AttachmemtLink': URL
        }
        this.commonService.downloadAttachment(sendObj)
            .subscribe((data: Blob) => {
                const fileName = URL.split('/').pop();
                onCreateObjectURLAndDownloadFile(data, fileName, event);
                // $(".notification-sidebar").addClass('open');
            },
                error => {
                    console.log(error);
                    event.target.classList.remove('cus-beat-effect');
                    this.toastrservice.typeError('Something went wrong. Please try again.');
                });
    }

    receivedTaskArchival(event, type): any {
        event.stopPropagation();
        const stat = {
            'TaskId': this.taskDetailsView.id,
            'UserId': this.userId,
            'TaskAction': this.settings.receivedTaskListComponent.taskArchival_TaskAction,
            'TaskValue': this.settings.receivedTaskListComponent.taskArchival_TaskValue,
            'ModBy': this.userMailDetails
        }
        this.receivedtaskservice.changeTaskEvent(stat).subscribe(
            successResponse => {
                if (!this.taskDetailsView.isRead) {
                    this.componentsCommunicationService.updateReceivedTaskUnreadCount({
                        isCallApi: false, event: 'UnReadToRead'
                    })
                }
                this.toastrservice.typeSuccess('Task is archived successfully.');
                this.taskDetailsView.taskStatusId = 4;
                const sendDetails = {
                    type: 'archived',
                    taskDetails: this.taskDetailsView
                }
                this.getActivityLogsByTaskId(this.taskDetailsView.id);
                this.detailedViewCommunicationMessage(sendDetails);
            },
            errorResponse => console.log(errorResponse.error)
        );
        return false;
    }

    assignedtaskArchival(event, type): any {
        if (type === 'icon') {
            event.stopPropagation();
        }
        const taskArchival = {
            'TaskId': this.taskDetailsView.id,
            'UserId': this.userId,
            'TaskAction': this.settings.assignedTaskListComponent.taskArchival_TaskAction,
            'TaskValue': this.settings.assignedTaskListComponent.taskArchival_TaskValue,
            'ModBy': this.userMailDetails
        }
        this.assignedTaskService.changeTaskAction(taskArchival).subscribe(
            success => {
                this.toastrservice.typeSuccess('Task is archived successfully.');
                this.taskDetailsView.taskStatusId = 4;
                // this.onClosingActivitySidebar();
                const sendDetails = {
                    type: 'archived',
                    taskDetails: this.taskDetailsView
                }
                this.getActivityLogsByTaskId(this.taskDetailsView.id);
                this.detailedViewCommunicationMessage(sendDetails);
            },
            error => {
                this.toastrservice.typeError('Task cannot be archived. Please try again.');
                console.log(error);
                this.toastrservice.typeError('Task cannot be archived. Please Try Again.');
            }
        );
        return false;
    }

    onCloseEditReopenTask() {
        this.secondModal.close();
        this.assigntaskform.reset();
        const element = this.renderer.createElement('input');
        element.focus();
    }

    detailedViewCommunicationMessage(taskDetails) {
        this.detailedViewCommunicationService.refreshTaskStats(taskDetails);
    }

    onShowCommentModal(task, canSendComments, event) {
        event.preventDefault();
        event.stopPropagation();
        this.isShowCommentsModal = true;
        this.canSendComments = canSendComments;
        this.taskDetailsView.createUserDisplayName = this.taskDetailsView.userDisplayName;
        this.taskDetailsView.createUserImagePath = this.taskDetailsView.userImagePath;
        this.taskDetailsView.taskStatusId = this.taskDetailsView.taskStatusId;
        this.selectedTaskForComment = {
            task: this.taskDetailsView,
            taskType: this.taskDetailsView.taskTypetaskDetailsView
        }
    }

    onImageRightClick(event) {
        return false;
    }

    quillTextChange(event) {
        this.taskDescriptionContent = event.text;
        if (!this.taskDescriptionContent.trim().length) {
            this.assigntaskform.controls['editor'].setErrors({ 'incorrect': true });
            this.assigntaskform.updateValueAndValidity();
        } else {
            this.assigntaskform.get('editor').clearValidators();
            this.assigntaskform.get('editor').updateValueAndValidity();
        }
    }

    assignedClickEvent(event) {
        event.preventDefault();
        event.stopPropagation()
        return false;
    }

    sendComment(comment) {
        if ((comment !== '' && comment.trim().length) || this.isValidAttachment) {
            this.commentSending = true;
            this.isNotCommentSent = false;
            if (comment === 'ThumbsUp') {
                comment = String.fromCodePoint(128077);
            } else if (comment === '') {
                comment = null;
            }
            let commentsAttachmentExtension = null;
            let commentsAttachedFileURL = null;
            let commentsAttachedFileSize = null;
            if (this.isValidAttachment) {
                commentsAttachmentExtension = this.attachmentExtension;
                this.uploadService.uploadTaskCommentAttachment(this.attachedFileFormData)
                    .subscribe((data) => {
                        commentsAttachedFileURL = JSON.parse(data['_body']).FileLink;
                        commentsAttachedFileSize = JSON.parse(data['_body']).FileSize;
                        this.onSaveComments(comment, commentsAttachmentExtension, commentsAttachedFileURL, commentsAttachedFileSize);
                    }, error => {
                        console.log(error);
                        this.toastrservice.typeError('Something went wrong. Please try again.');
                        this.commentSending = false;
                        this.isNotCommentSent = true;
                    });
            } else {
                this.onSaveComments(comment, commentsAttachmentExtension, commentsAttachedFileURL, commentsAttachedFileSize);
            }

        } else {
            this.toastrservice.typeError('Empty Comment or Attachment can not be sent');
        }
    }

    onSaveComments(comment, commentsAttachmentExtension, commentsAttachedFileURL, commentAttachmentFileSize) {
        const commentSendData = {
            'TaskId': this.taskDetails.taskId,
            'Comments': comment,
            'AttachmentExtension': commentsAttachmentExtension,
            'AttachmentURL': commentsAttachedFileURL,
            'FileSize': commentAttachmentFileSize
        }

        this.commentService.saveComments(commentSendData).subscribe(success => {
            this.attachedFileFormData = undefined;
            this.attachmentExtension = '';
            this.isValidAttachment = false;

            this.commentService.getComments(this.taskDetails.taskId).subscribe(data => {
                this.commentList = data;
                this.commentSending = false;
            }, error => {
                this.commentSending = false;
                this.toastrservice.typeError('Something went wrong while updating the comments. Please refresh the page.');
            });
            this.userComment = '';
        }, error => {
            this.commentSending = false;
            this.isNotCommentSent = true;
            this.toastrservice.typeError('Something went wrong. Please try again.');

        });
    }

    clearAttachment() {
        event.stopPropagation();
        event.preventDefault();
        this.attachedFileFormData = undefined;
        this.attachmentExtension = '';
        this.isValidAttachment = false;

    }


    // closeCommentModal() {
    //     this.modalRef.close();

    //     this.closeCommentModalEvent.emit(true);
    //     // this.communicationEvent.emit('closeModal');
    //     this.commentList = [];
    //     this.clearAttachment();
    // }

    fileChange(event) {
        const fileList: FileList = event.target.files;
        this.attachedFileFormData = undefined;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            // this.uploadedFileName = file.name;
            const splitFileName = file.name.split('.')

            if (splitFileName[0].length > 10) {
                this.uploadedFileName = splitFileName[0].slice(0, 17) + '...' + '.' + splitFileName[1];
            } else {
                this.uploadedFileName = file.name;
            }

            this.attachmentExtension = this.uploadedFileName.substr((this.uploadedFileName.lastIndexOf('.') + 1)).toLowerCase();
            switch (this.attachmentExtension) {
                case 'jpg':
                    this.extensionError = false;
                    break;
                case 'jpeg':
                    this.extensionError = false;
                    break;
                case 'png':
                    this.extensionError = false;
                    break;
                case 'mp3':
                    this.extensionError = false;
                    break;
                case 'wav':
                    this.extensionError = false;
                    break;
                case 'm4a':
                    this.extensionError = false;
                    break;
                case 'mp4':
                    this.extensionError = false;
                    break;
                case 'mov':
                    this.extensionError = false;
                    break;
                case 'txt':
                    this.extensionError = false;
                    break;
                case 'doc':
                    this.extensionError = false;
                    break;
                case 'docx':
                    this.extensionError = false;
                    break;
                case 'ppt':
                    this.extensionError = false;
                    break;
                case 'pptx':
                    this.extensionError = false;
                    break;
                case 'pdf':
                    this.extensionError = false;
                    break;
                case 'xlsx':
                    this.extensionError = false;
                    break;
                case 'xls':
                    this.extensionError = false;
                    break;
                case 'csv':
                    this.extensionError = false;
                    break;
                case 'zip':
                    this.extensionError = false;
                    break;
                case 'rar':
                    this.extensionError = false;
                    break;
                default:
                    this.extensionError = true;
                    this.isValidAttachment = false;
                    this.toastrservice.typeError('File format is not supported');
                    return false;
            }

            if (file) {
                if (file.size <= this.maxFileSize) {
                    if (!this.extensionError) {
                        const sendFormData = new FormData();
                        sendFormData.append('file', file);
                        this.attachedFileFormData = sendFormData;
                        this.isValidAttachment = true;
                        this.biggerFileSize = false;
                    } else {
                        this.attachedFileFormData = undefined;
                        this.attachmentExtension = '';
                        this.isValidAttachment = false;
                    }
                } else {
                    this.biggerFileSize = true;
                    this.toastrservice.typeError('Maximum file size allowed is ' + this.maxFileSizeText + '.');
                }
            }
        }
        // For Re-Select the same Image
        event.target.value = '';
    }

    givePriority(event, task) {
        event.stopPropagation();
        if (task.bellCount < this.maximumBellCount) {
            const incrementPriority = task.bellCount + 1;
            const priorityCount = {
                'TaskId': task.id,
                'UserId': this.userId, // loggedin user details
                'TaskAction': this.settings.assignedTaskListComponent.priority_TaskAction,
                'TaskValue': incrementPriority.toString(),
                'ModBy': this.userMailDetails
            }
            this.assignedTaskService.changeTaskAction(priorityCount).subscribe(
                success => {
                    this.getActivityLogsByTaskId(this.taskDetailsView.id);
                    this.taskDetailsView.bellCount = incrementPriority;
                    this.toastrservice.typeSuccess(incrementPriority + ' Priority is given');
                    const sendDetails = {
                        type: 'priority',
                        taskDetails: this.taskDetailsView
                    }
                    this.detailedViewCommunicationMessage(sendDetails);
                },
                error => {
                    this.toastrservice.typeError('Something went wrong while assigning priority. Please try again.');
                }
            );
        } else if (task.bellCount >= this.maximumBellCount) {
            Swal.fire({
                title: 'Warning', text: this.maximumBellCount + ' is the maximum priority for task.'
            }).then();
        }
        return false;
    }

    ngOnDestroy() {
        const data = {
            isShowDetailedView: false,
            taskDetails: [],
            isOpenSidebarModal: false
        }
        this.taskDetailedViewService.getUserDetails(data);
        this.receivedData.unsubscribe();
    }
}
