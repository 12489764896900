import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';

@Injectable()
export class ComponentsCommunicationService {
  private userDetails = new BehaviorSubject('');
  currentUserDetails = this.userDetails.asObservable();

  private expandedSidebar = new BehaviorSubject('');
  expandedSidebarInfo = this.expandedSidebar.asObservable();

  private onShowStatCards = new BehaviorSubject({
    showCards: true
  });
  showStatCardsInfo = this.onShowStatCards.asObservable();

  receivedTaskUnreadCountValueSource = new BehaviorSubject(
    {
      isCallAPI: true,
      event: ''
    }
  );
  receivedTaskUnreadCountValue = this.receivedTaskUnreadCountValueSource.asObservable();

  constructor() { }

  changedUserDetails(data) {
    this.userDetails.next(data);
  }

  /* For changing the position of assigned tasklist header UI elements */
  changeUIElements(data) {
    this.expandedSidebar.next(data);
  }

  updateReceivedTaskUnreadCount(countData) {
    this.receivedTaskUnreadCountValueSource.next(countData);
  }

  toggleStatCards(data) {
    this.onShowStatCards.next(data);
  }
}
