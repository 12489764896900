import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadersCssModule } from 'angular2-loaders-css';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskDetailedViewComponent } from './task-detailed-view.component';
import { FieldErrorModule } from '../field-error-display/field-error-display.module';
import { QuillModule } from 'ngx-quill';
import { SearchTextPipeModule } from '../pipes/search-text-pipe.module';
import { MainDatePipe } from '../pipes/main-date-pipe.module'
import { CommentsModule } from '../comments/comments.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DomSanitizer } from 'app/pipes/domSanitizer.pipe.module';
import { VideoModalModule } from '../video-modal/video-modal.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AutofocusModule } from '../shared/directives/autofocus.directive'

@NgModule({
    exports: [
        TaskDetailedViewComponent
    ],
    imports: [
        CommonModule,
        LoadersCssModule,
        FormsModule,
        ReactiveFormsModule,
        FieldErrorModule,
        SearchTextPipeModule,
        MainDatePipe,
        CommentsModule,
        VideoModalModule,
        NgbModule,
        NgMultiSelectDropDownModule,
        QuillModule,
        DomSanitizer,
        NgxSkeletonLoaderModule,
        AutofocusModule
    ],
    declarations: [
        TaskDetailedViewComponent
    ]
})
export class TaskDetailedViewModule { }
