import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { Configuration } from './configuration.constants';
import html2canvas from 'html2canvas';
import  jsPDF from 'jspdf';

@Injectable()
export class ProjectService {
  captureDivAndDownloadAsPDF(divToCapture) {
  
    html2canvas(divToCapture,{scale:4}).then(canvas => {
     
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.setFontSize(70);
      const imgData = canvas.toDataURL('image/png');
     
      const imgWidth = 190; // Adjust as needed
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('resume.pdf');
    });
  }
  constructor(private http: HttpClient, private _configuration: Configuration) {
    const savedData = localStorage.getItem(this.localStorageKey);
    if (savedData) {
      this.selectedProjectIds = JSON.parse(savedData);
    }
  }

  private selectedProjectIds: number[] = [];
  private localStorageKey = 'selectedProjectIds';

  getSelectedProjectIds(): number[] {
    return this.selectedProjectIds;
  }

  setSelectedProjectIds(projectIds: number[]): void {
    this.selectedProjectIds = projectIds;
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.selectedProjectIds));
  }

  public getProject<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getProject, {
      headers: this._configuration.getHeaders()
    });
  }

  public saveProject(data) {
    return this.http.post(this._configuration._saveProject, data, {
      headers: this._configuration.getHeaders()
    })
  }
  public getProjeById<T>(id, active): Observable<T> {
    return this.http.get<T>(this._configuration._getProjectById + '?id=' + id + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }

  public getbyProjectName<T>(projectName): Observable<T> {
    return this.http.get<T>(this._configuration._getbyProjectName + '?projectTypeName=' + projectName , {
      headers: this._configuration.getHeaders()
    });
  }

  public getProjectType<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getProjectType, {
      headers: this._configuration.getHeaders()
    });
  }
 

  public deleteProject(projectId) {
    return this.http.delete(this._configuration._deleteProject
      + '?projectId=' + projectId, { headers: this._configuration.getHeaders() });
  }

  public getUserProjectByUserId<T>(userId, active): Observable<T> {
    return this.http.get<T>(this._configuration._getUserProjectByUserId
      + '?userId=' + userId + '&active=' + active, {
      headers: this._configuration.getHeaders()
    });
  }

  public getUserDetailsById<T>(userId): Observable<T> {
    return this.http.get<T>(this._configuration._getUserDetailsById
      + '?userId=' + userId, {
      headers: this._configuration.getHeaders()
    });
  }

  public getMultipleProject(data) {
    return this.http.post(this._configuration._getMultipleProject, data, {
      headers: this._configuration.getHeaders()
    })
  }

  public getUserSkillSetbyId<T>(id): Observable<T> {
    return this.http.get<T>(this._configuration._getUserSkillSetbyId
      + '?id=' + id, {
      headers: this._configuration.getHeaders()
    });
  }


}