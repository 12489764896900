import { Component, HostBinding } from '@angular/core';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    // Variables
    settings: any = AppConfig.settings;
    product_name = this.settings.footersettings.PRODUCT_NAME;
    product_website = this.settings.footersettings.PRODUCT_WEBSITE;
    copy_right_name: any = this.settings.footersettings.COPYRIGHT_NAME;
    version: any = this.settings.footersettings.VERSION;
    year: number;
    companyURL = this.settings.footersettings.COMPANY_URL

    constructor() {
        this.year = new Date().getFullYear();
    }
}

