import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app.config';


@Injectable()
export class Configuration {

  public Server = AppConfig.settings.apiServer.servername;
  public ApiUrl = 'api/';
  // public ServerWithApiUrl = this.Server + this.ApiUrl;
  public initialURL = 'http://taskadminwebservice.us-east-2.elasticbeanstalk.com/api/';
 // public ServerWithApiUrl = 'http://einnel-crm-customer-env.eba-b2ar7jms.us-west-2.elasticbeanstalk.com/api/';
  //public ServerWithApiUrl = 'http://default-environment.ie3pzxk2rq.us-east-2.elasticbeanstalk.com/api/';
  //public ServerWithApiUrl = 'http://ec2-54-203-11-69.us-west-2.compute.amazonaws.com:8080/api/';
  public ServerWithApiUrl= 'https://api.einnel-task.com/api/';
  //public ServerWithApiUrl = 'http://einnel-crm-customer-env.eba-b2ar7jms.us-west-2.elasticbeanstalk.com/api/';
  private companyName: string;
  private userId: number;
  private tokenId: string;
  private userImage: string;
  private userName: string;
  private companyId: number;
  private userMail: string;
  private role: string;
  private timezone: any;
  private userstatus: string;
  private userlocationCoordinate: string;
  private firebaseNotificationPath: any;
   private roleId:number;
  // Dashboard Servive
  public _getTaskSummary = this.ServerWithApiUrl + 'dashboard/gettasksummary';
  public _getTodayTaskStatistics = this.ServerWithApiUrl + 'dashboard/gettodaytaskstatistics?todayDateTimeInUTC=';
  public _getRecentTaskReceived = this.ServerWithApiUrl + 'dashboard/getrecenttaskreceived';
  public _recentAssignedTasks = this.ServerWithApiUrl + 'dashboard/GetMyRecentAssignedTaskDetails';
  public _getUserTaskRewards = this.ServerWithApiUrl + 'dashboard/getusertaskrewards';
  public _getWeeklyStatistics = this.ServerWithApiUrl + 'dashboard/getweeklytaskstatistics?currentDateTimeInUTC=';
  public _getUserPerformance = this.ServerWithApiUrl + 'dashboard/getuserperformance';
  public _getWeeklyUserPerformanceStat = this.ServerWithApiUrl + 'dashboard/getweeklyperformancestatistics?currentDateTimeInUTC=';

  // Admin Service
  public _getUsersList = this.ServerWithApiUrl + 'user/getuserslist';
  public _getSubordinateUserList = this.ServerWithApiUrl + 'user/GetSubordinateUsers';
  public _checkUsernameAvail = this.ServerWithApiUrl + 'user/UserNameValidation?Username=';
  public _saveUsers = this.ServerWithApiUrl + 'user/save';
  public _UserstoActive = this.ServerWithApiUrl + 'user/BulkActiveUser';
  public _UserstoInActive = this.ServerWithApiUrl + 'user/BulkInActiveUser';
  public _getDesignations = this.ServerWithApiUrl + 'company/getdesignations'; /*user/getdesignations*/
  public _getDepartments = this.ServerWithApiUrl + 'company/getdepartments'; /*user/getdepartments*/

  public _getCountryCode = 'assets/json/countrieslistwithcode.json'; /*user/getdepartments*/
  public _saveDepartment = this.ServerWithApiUrl + 'company/savedepartment';

  public _saveBulkDepartment = this.ServerWithApiUrl + 'company/bulksavedepartment';
  public _deleteDepartment = this.ServerWithApiUrl + 'company/Deletedepartment';


  public _getHealthInsurance = this.ServerWithApiUrl + 'healthinsurance/gethealthinsuranceByUserId';
  public _saveHealthInsurance = this.ServerWithApiUrl + 'healthinsurance/save';
  public _updateHealthInsurance = this.ServerWithApiUrl + 'healthinsurance/update';
//
  public _saveHoliday = this.ServerWithApiUrl + 'companyHoliday/save';
  public _deleteHoliday = this.ServerWithApiUrl + 'companyHoliday/delete';
  public _getHoliday = this.ServerWithApiUrl + 'companyHoliday/getholidays';
  public _getHolidays = this.ServerWithApiUrl + 'companyHoliday/getholidaysByYear';
  public _getHolidaysByMonth = this.ServerWithApiUrl + 'companyHoliday/getholidaysByMonth';
  //
  public _getCompanyWeekend = this.ServerWithApiUrl + 'companyweekend/getCompanyWeekend';
  public _getCompanyWeekendById = this.ServerWithApiUrl + 'companyweekend/getcompanyweekendbyid';
  public _saveCompanyWeekend = this.ServerWithApiUrl + 'companyweekend/save';
  public _deleteCompanyWeekend = this.ServerWithApiUrl + 'companyweekend/delete';
//
  public _getUserLeavePolicy= this.ServerWithApiUrl + 'userleavepolicy/getuserleavepolicy';
  public _getUserLeavePolicyById = this.ServerWithApiUrl + 'userleavepolicy/getuserleavepolicybyid';
  public _getUserLeavePolicyByYear = this.ServerWithApiUrl + 'userleavepolicy/getaccountableuserleavebyyear';
  public _getUserLeaveDetailsByYear = this.ServerWithApiUrl + 'userleavepolicy/getuserleaveDetailsByYear';
  public _saveUserLeavePolicy = this.ServerWithApiUrl + 'userleavepolicy/save';
  public _deleteUserLeavePolicy = this.ServerWithApiUrl + 'userleavepolicy/deleteuserleavepolicy';
//

   public _getCompanyLeavePolicy= this.ServerWithApiUrl + 'companyleavepolicy/getcompanyleavepolicy';
   public _getCompanyLeavePolicyById= this.ServerWithApiUrl + 'companyleavepolicy/getcompanyleavepolicybyid';
   public _getCompanyLeavePolicyByYear= this.ServerWithApiUrl + 'companyleavepolicy/getaccountableleavebyyear';
   public _saveCompanyLeavePolicy= this.ServerWithApiUrl + 'companyleavepolicy/save';
   public _deleteCompanyLeavePolicy= this.ServerWithApiUrl + 'companyleavepolicy/deletecompanyleavepolicy';
//
 


  public _saveDesignation = this.ServerWithApiUrl + 'company/savedesignation';
  public _saveBulkDesignation = this.ServerWithApiUrl + 'company/bulksavedesignation';
  public _deleteDesignation = this.ServerWithApiUrl + 'company/Deletedesignation';
  public _sendBulkMail = this.ServerWithApiUrl + 'user/BulkUserSendMail';
  public _getMailSettings = this.ServerWithApiUrl + 'mailSettings/getmycompanymailsettings';
  public _getConnectionType = this.ServerWithApiUrl + 'mailsettingconnectiontype/getconnectiontype';
  public _saveMailSettings = this.ServerWithApiUrl + 'mailSettings/savemailsetting';
  /* public _testMailSettings = this.ServerWithApiUrl + 'mailSettings/TestMail?email='; */
  public _testIncomingMailSettings = this.ServerWithApiUrl + 'mailSettings/testincomingmail?email=';
  public _testOutgoingMailSettings = this.ServerWithApiUrl + 'mailSettings/testoutgoingmail?email=';
  public _activeLicenseKey = this.initialURL + 'license/ActivateLicenseByUser';
  public _resetPassword = this.ServerWithApiUrl + 'user/resetuserpassword';
  public _getannouncementlist = this.ServerWithApiUrl + 'announcement/getannouncementlist';

  // Analytics
  public _getOverAllAssignedTaskSummary = this.ServerWithApiUrl + 'taskdashboard/getcompanytasksummary';
  public _getmonthlyStatistics = this.ServerWithApiUrl + 'taskdashboard/getcompanyassignedtaskmonthlyreport';
  public _getBestPerformance = this.ServerWithApiUrl + 'taskdashboard/getbestperformers';
  public _getWorstPerformance = this.ServerWithApiUrl + 'taskdashboard/getworstperformers';
  public _getTaskOverdue = this.ServerWithApiUrl + 'taskdashboard/getusertasksummarybycompanyId';
  public _getdelayedTask = this.ServerWithApiUrl + 'taskdashboard/getcompanydelayedtasksummary';


  // Assigned Task Servive
  public _getAssignedTaskList = this.ServerWithApiUrl + 'assignedtask/getassignedtasklistinutc';
  public _getAssignedTaskSummary = this.ServerWithApiUrl + 'assignedtask/getassignedtasksummary';
  public _getAssignedTaskSummaryByReceiverId = this.ServerWithApiUrl
    + 'assignedtask/getassignedtasksummarybyreceiverid?receiverId=';
  public _getAssignedTaskListByReceiver = this.ServerWithApiUrl + 'assignedtask/getassignedtasklistbyreceiver?receiverId=';
  public _saveAssignertaskEvent = this.ServerWithApiUrl + 'usertaskevent/saveassignertaskEvent';
  public _getArchivedAssignedTaskList = this.ServerWithApiUrl + 'assignedtask/getarchivedassignedtasklistinutc';
  public _getArchivedReportedTask = this.ServerWithApiUrl + 'assignedtask/getarchivedreportedtasksinutc';
  public _saveUserTaskComment = this.ServerWithApiUrl + 'usertaskcomment/save';
  public _updateUserTaskCommentReward = this.ServerWithApiUrl + 'usertaskevent/UpdateRewardComment';
  public _updateTask = this.ServerWithApiUrl + 'usertaskevent/updatetask';
  public _saveAttachments = this.ServerWithApiUrl + 'taskattachment/save';
  public _changeAssignedTaskCommentStatus = this.ServerWithApiUrl + 'usertaskevent/saveassignertaskevent';
  public _listAssignTaskByDate = this.ServerWithApiUrl + 'assignedtask/GetMyAssignedTaskDetailsFilterByDate';
  public _bulkAssignedTaskArchive = this.ServerWithApiUrl + 'usertaskevent/MultiSelectAssignerTaskArchive';
  public _shareAssignedTask = this.ServerWithApiUrl + 'usertask/save';
  public _onScheduleTask = this.ServerWithApiUrl + 'task/scheduletask';
  public _deleteTask = this.ServerWithApiUrl + 'usertaskevent/deletetask ';


  //ScheduleTask

  public _getAllScheduledTasks = this.ServerWithApiUrl + 'taskscheduler/getmytaskscheduler';
  public _saveSheduleTask = this.ServerWithApiUrl + 'taskscheduler/save';
  public _getScheduleTaskByDate = this.ServerWithApiUrl + 'taskscheduler/getmytaskschedulerfilterbydate';
  public _deleteScheduledTask = this.ServerWithApiUrl + 'taskscheduler/delete';

  // Map Service
  public _userWorkGroupWithGeoLocation = this.ServerWithApiUrl + 'dashboard/getuserworkgroupwithgeolocation';

  // WorkGroup Service
  public _gatInActiveWorkGroupMembers = this.ServerWithApiUrl + 'userworkgroup/getmyworkgroupmembers?&active=false';
  public _getActiveWorkGroupMembers = this.ServerWithApiUrl + 'userworkgroup/getmyworkgroupmembers?&active=true';
  public _getAllWorkGroupMembers = this.ServerWithApiUrl + 'userworkgroup/getuserworkgroupmembers';
  public _saveTask = this.ServerWithApiUrl + 'task/save';
  public _modifyWorkgroupMembers = this.ServerWithApiUrl + 'userworkgroup/savegroupmembers';
  public _saveTasks = this.ServerWithApiUrl + 'task/savetasks';

  // Received Task Service
  public _getMyReceivedTasks = this.ServerWithApiUrl + 'receivedtask/GetMyReceivedTasksInUTC';
  public _getMyReceivedTaskAssigners = this.ServerWithApiUrl + 'receivedtask/GetMyReceivedTaskAssigners';
  public _getMyReceivedTaskSummary = this.ServerWithApiUrl + 'receivedtask/GetMyReceivedTaskSummary';
  public _getMyReceivedTaskSummaryByAssignerId = this.ServerWithApiUrl + 'receivedtask/GetMyReceivedTaskSummaryByAssignerId?assignerId=';
  public _saveReceiverTaskEvent = this.ServerWithApiUrl + 'usertaskevent/savereceivertaskEvent';
  public _getArchivedReceivedTask = this.ServerWithApiUrl + 'receivedtask/getarchivedmyreceivedtasksinutc';
  public _getArchivedMyTasks = this.ServerWithApiUrl + 'mytask/getarchivedmytasks';
  public _listReceivedTaskByDate = this.ServerWithApiUrl + 'receivedtask/GetMyReceivedTaskDetailsFilterByDateInUTC';
  public _bulkReceivedTaskArchive = this.ServerWithApiUrl + 'usertaskevent/MultiSelectReceiverTaskArchive';
  public _getTaskByTaskId = this.ServerWithApiUrl + 'task/GetById';
  public _getReceivedTaskUnReadCountURL = this.ServerWithApiUrl + 'usertask/gettaskunreadcount';

  // Comments
  public _addComment = this.ServerWithApiUrl + 'usertaskcomment/save';
  public _getComments = this.ServerWithApiUrl + 'usertaskcomment/gettaskcommentsinutc?TaskId=';
  public _changeTaskCommentStatus = this.ServerWithApiUrl + 'usertaskevent/savereceivertaskEvent';

  // UserProfile Service
  public _getMyProfile = this.ServerWithApiUrl + 'user/getmyprofile';
  public _getHierarchyRequest = this.ServerWithApiUrl + 'Hierarchy/HierarchyRequest';
  public _getHierarchyAccept = this.ServerWithApiUrl + 'hierarchy/hierarchyaccept';
  public _getMyHierarchy = this.ServerWithApiUrl + AppConfig.settings.getHierarchyByUserId.URL;
  public _getHierarchyRequestReceived = this.ServerWithApiUrl + 'Hierarchy/GetHierarchyRequestReceived';
  public _getHierarchyRequestSent = this.ServerWithApiUrl + 'Hierarchy/GetHierarchyRequestSent';
  public _removeHierarchyUser = this.ServerWithApiUrl + 'hierarchy/removehierarchyuser';
  public _removeSubordinates = this.ServerWithApiUrl + AppConfig.settings.removeSubordinates.URL;
  //UserProfile JobDescription
  public _saveJobDescription = this.ServerWithApiUrl + 'jobdescription/save';
  public _deleteJobDescription = this.ServerWithApiUrl + 'jobdescription/deletejobdescription?jobDescriptionId=';
  public _getJobDescriptionById = this.ServerWithApiUrl + 'jobdescription/getjobdescriptionByUserId?userid=';
  public _updateJobDescription = this.ServerWithApiUrl + 'jobdescription/updatejobdescription'
 
  // Get Skill
  public _getAllUserSkillset = this.ServerWithApiUrl + 'userskillset/getalluserskillset';
  public _addUserSkillset = this.ServerWithApiUrl + 'userskillset/save';
  public _addUserProfileSummary = this.ServerWithApiUrl + 'user/saveUserSummary';
  public _deleteUserSkillset = this.ServerWithApiUrl + 'userskillset/delete';

  public _getSkillset = this.ServerWithApiUrl + 'skillset/getskillset';

  // Birthday Bot
  public _getBirthday = this.ServerWithApiUrl + 'user/GetUserBirthDay';

  //ActivityLog
  public getActivityLogsByTaskId = this.ServerWithApiUrl + 'taskevent/geteventsbytaskid';
  public getElapsedOnHoldTime = this.ServerWithApiUrl + 'task/getelapsedonholdtime';
  // change user status (password, status)
  public _updateUserDetail = this.ServerWithApiUrl + 'user/updateuserdetail';

  // Settings
  public _updateSettings = this.ServerWithApiUrl + 'user/updatewebappsetting';
  public _showSettings = this.ServerWithApiUrl + 'user/getwebappsetting';
  public _saveSettings = this.ServerWithApiUrl + 'user/savewebappsetting';

  // Clients
  public _getCountries = this.ServerWithApiUrl + 'company/getCountry';
  public _getStates = this.ServerWithApiUrl + 'company/getStates?CountryId=';
  public _getClients = this.ServerWithApiUrl + 'client/getclients';
  public _saveClients = this.ServerWithApiUrl + 'client/save';
  public _deleteClient = this.ServerWithApiUrl + 'client/delete?clientId=';

  // Company
  public _getMyCompany = this.ServerWithApiUrl + 'company/getmycompany';
  public _updateMyCompany = this.ServerWithApiUrl + 'company/updatecompany';

  // Notifications
  public _saveAnnouncement = this.ServerWithApiUrl + 'announcement/save';
  public _getAnnouncement = this.ServerWithApiUrl + 'announcement/getannouncement';
  public _getAnnouncementForDashboard = this.ServerWithApiUrl + 'announcement/getannouncement?currentDateTime=';
  public _updatetAnnouncement = this.ServerWithApiUrl + 'announcement/updateannouncement';
  public _deleteAnnouncement = this.ServerWithApiUrl + 'announcement/deleteannouncement?announcementId=';
  public deactivateAnnouncements = this.ServerWithApiUrl + 'announcement/deactivateannouncements';
  public activeteAnnouncements = this.ServerWithApiUrl + 'announcement/activateannouncement';

  // Email Notification for password
  public _sendEmailNotification = this.ServerWithApiUrl + 'user/mailusercredentials?userId=';

  // Bulk Import
  public _bulkImport = this.ServerWithApiUrl + 'user/importUser';

  // PushNotification
  public _pushNotification = this.ServerWithApiUrl + 'user/saveuserloginevent';
  // public _getRecentNotifications = this.getFirebaseNotificationDatabaseBasicPath();
  public _markAllNotificationsRead = this.ServerWithApiUrl + 'user/marknotificationasread';
  public _deleteNotification = this.ServerWithApiUrl + 'user/deletenotification';

  // mfa
  public _getQRCode = this.ServerWithApiUrl + 'user/GetMfaCredentials';
  public _validateMfaCode = this.ServerWithApiUrl + 'user/ValidateMfaCredentials';
  public _validateUserForMfa = this.ServerWithApiUrl + 'user/userlogin';
  public _disableMFA = this.ServerWithApiUrl + 'user/disablemfaforuser';

  // Add Alexa Credential
  public _saveAlexaIntegrationDetail = this.ServerWithApiUrl + 'alexaintegrationdetail/save';

  // OTP
  public _generateOTP = this.initialURL + 'company/getonetimepassword';
  public _verifyOTP = this.initialURL + 'company/verifyonetimepassword';

  // Activate License
  public _activateCompanyLicense = this.ServerWithApiUrl + 'company/activatecompanylicense';

  // Self Task

  public _getOnHoldTaskList = this.ServerWithApiUrl + 'mytask/GetMyTaskListByOnHold';
  public _getInprogressTaskList = this.ServerWithApiUrl + 'mytask/GetMyTaskListByInProgress';
  public _getCompletedTaskList = this.ServerWithApiUrl + 'mytask/GetMyTaskListByOnComplete';
  public _saveSelfTask = this.ServerWithApiUrl + 'mytask/savemytask';
  public _getSelfTaskList = this.ServerWithApiUrl + 'mytask/getmytasklist';


  // Todo Task
  public _saveTodoTask = this.ServerWithApiUrl + 'todolist/save';

  public _updateSelfTaskAttachment = this.ServerWithApiUrl + 'taskattachment/save';

  public _editTodoTaskStatus = this.ServerWithApiUrl + 'todolist/save';
  public _getTodoTaskListbyPaging = this.ServerWithApiUrl + 'todolist/gettodolistbypaging';
  public _changeTodoTaskStatus = this.ServerWithApiUrl + 'todolist/ShareTodoTask';
  public _completeTodoTaskStatus = this.ServerWithApiUrl + 'todolist/CompleteTodoList';
  public _deleteTodoTaskStatus = this.ServerWithApiUrl + 'todolist/DeleteTodoList';
  public _reopenTask = this.ServerWithApiUrl + 'usertaskevent/reopenmytaskbyassigner';

  // Inbox Service
  public _validateIncomingMailCredentialsURL = this.ServerWithApiUrl + 'mailSettings/validateincomingmailcredential';
  public _validateOutgoingMailCredentialsURL = this.ServerWithApiUrl + 'mailSettings/validateoutgoingmailcredential';
  public _composeMail = this.ServerWithApiUrl + 'email/sendmail';
  public _getAllInboxMail = this.ServerWithApiUrl + 'email/getallinboxmail';
  public _getInboxMailById = this.ServerWithApiUrl + 'Email/GetInboxMailById?mailId=';
  public _getAllDraftMail = this.ServerWithApiUrl + 'email/getalldraftmail';
  public _getDraftMailById = this.ServerWithApiUrl + 'Email/GetDraftMailById?mailId=';
  public _getAllSentMail = this.ServerWithApiUrl + 'email/getallsentmail';
  public _getSentMailById = this.ServerWithApiUrl + 'Email/GetSentMailById?mailId=';
  public _getAllTrashMails = this.ServerWithApiUrl + 'email/getalltrashmail';
  public _getTrashMailById = this.ServerWithApiUrl + 'Email/GetTrashMailById?mailId=';
  public _getAllStarredMail = this.ServerWithApiUrl + 'email/getallstarredmail';
  public _getStarredMailById = this.ServerWithApiUrl + 'Email/GetStarredMailById?mailId=';
  public _unreadMail = this.ServerWithApiUrl + 'emailaction/markasunread';
  public _readMail = this.ServerWithApiUrl + 'emailaction/markasread';
  public _getNewMessageCount = this.ServerWithApiUrl + 'emailaction/getnewmessagecount';
  public _saveDraftURL = this.ServerWithApiUrl + 'email/savedraft';
  public _deleteDraftMessagesURL = this.ServerWithApiUrl + 'email/deletedraftmessages';
  public _getFileAttachmentForMail = this.ServerWithApiUrl + 'email/downloadFile';
  public _replyMail = this.ServerWithApiUrl + 'email/replymail';

  // File Upload
  public _uploadTaskAttachmentURL = this.ServerWithApiUrl + 'taskattachment/uploadtaskattachment';
  public _uploadTaskCommentAttachmentURL = this.ServerWithApiUrl + 'taskattachment/uploadtaskcommentattachment';
  public _uploadAnnouncementAttachmentURL = this.ServerWithApiUrl + 'taskattachment/uploadannouncementattachment';
  public _uploadUserImageURL = this.ServerWithApiUrl + 'taskattachment/uploaduserimage';
  public _uploadMailFIleAttachment = this.ServerWithApiUrl + 'email/UploadFile';

  // Download Attachment
  public _downloadAttachmentURL = this.ServerWithApiUrl + 'taskattachment/downloadattachment';

  // Report
  public _getReportURL = this.ServerWithApiUrl + 'taskreport/getreport';

  // Attendance Report
  public _getAttendanceReportURL = this.ServerWithApiUrl + 'report/getreport';

   //User Attendance Report
   public _getUserAttendanceReportURL = this.ServerWithApiUrl + 'report/getUserAttendanceReport';
   public _getHierarchyReport = this.ServerWithApiUrl + 'report/GetUserAttendanceReportByHierarchy';
   public _getHierarchyUserReport = this.ServerWithApiUrl + 'report/GetUserAttendanceReports';
  
  // Meeting
  public _zoomMeetingSignature = this.ServerWithApiUrl + 'logintoken/zoomtokengenerator';

  //company Holiday
  public _delete = this.ServerWithApiUrl + 'companyHoliday/delete';

  // company weekend
  public _companyWeekendByYear = this.ServerWithApiUrl + 'companyweekend/getCompanyWeekend'; 

  //UserAttendanceRegister
  public _getUserAttendance = this.ServerWithApiUrl + 'UserAttendance/getUserAttendance';
  public _getBulkUserAttendance = this.ServerWithApiUrl + 'UserAttendance/getAttendanceByEmployeeID'
  public _getUserAttendanceByUserId = this.ServerWithApiUrl + 'UserAttendance/getUserAttendanceByUserId';
  //public _getUserAttendanceByMonth = this.baseURL + 'UserAttendance/getUserAttendanceByMonth';
  public _getUserAttendanceByMonth = this.ServerWithApiUrl + 'UserAttendance/UserAttendanceByMonth';
  public _updateUserAttendance = this.ServerWithApiUrl + 'UserAttendance/AttendanceRequest';
  public _userTimeIn = this.ServerWithApiUrl + 'UserAttendance/timeIn';
  public _UserTimeOut = this.ServerWithApiUrl + 'UserAttendance/timeOut';
  public _UserRequest = this.ServerWithApiUrl + 'UserAttendance/request';
  public _checkedInAttendance = this.ServerWithApiUrl + 'UserAttendance/getCheckedInAttendance';
  public _leaveRequest = this.ServerWithApiUrl + 'UserAttendance/bulkrequestleave';
  public _deleteAttendance = this.ServerWithApiUrl + 'UserAttendance/delete';
  public _punchInAttendance = 'https://api.opfin.com/api/att'

  public _requestAttendance= this.ServerWithApiUrl + 'UserAttendance/requestattendance';
  public _requestApproval = this.ServerWithApiUrl + 'UserAttendance/approval';
  public _getUserReport = this.ServerWithApiUrl + 'UserAttendance/getAttendanceForParticularUsers';


  //AttenndanceStatus
  public _attendanceStatus = this.ServerWithApiUrl + 'attendancestatus/getattendancestatus';

  //ADD Medical Form
  public _addMedicalForm = this.ServerWithApiUrl + 'healthinsurance/importInsurance'

  //AttenndanceType
  public _attendanceType = this.ServerWithApiUrl + 'attendancetype/getallattendancetypes';
  public _attendanceTypeById = this.ServerWithApiUrl + 'attendancetype/getattendancetypeById?Id=';
  public _attendanceTypeList = this.ServerWithApiUrl + 'attendancetype/getallattendancetypes';
  public _updateattendanceType = this.ServerWithApiUrl + 'attendancetype/update';
  public _deleteattendanceType = this.ServerWithApiUrl + 'attendancetype/delete';
  public _saveattendanceType = this.ServerWithApiUrl + 'attendancetype/save';
  //UserLeavePlicy
  public _userLeavepolicyImport = this.ServerWithApiUrl + 'userleavepolicy/importUserLeavePolicy'
  public _userLeaveDetailsByYear = this.ServerWithApiUrl + 'userleavepolicy/getuserleaveDetailsByYear';
  payrollId: any;
  
//Project 
public _getProject = this.ServerWithApiUrl + 'project/getProject ';
public _getProjectType = this.ServerWithApiUrl + 'projectType/getProjectType';
public _saveProject = this.ServerWithApiUrl +'project/save';
public _deleteProject = this.ServerWithApiUrl +'project/deleteProject';
public _getProjectById =this.ServerWithApiUrl +'project/getProjectById';
public _getbyProjectName =this.ServerWithApiUrl +'project/getbyProjectName';
public _getUserProjectByUserId =this.ServerWithApiUrl +'userProject/getUserProjectByUserId';
public _getUserDetailsById =this.ServerWithApiUrl +'user/getUserDetailsById';
public _getMultipleProject = this.ServerWithApiUrl +'project/getMultipleProject';
public _getUserSkillSetbyId =this.ServerWithApiUrl +'userskillset/getuserskillsetbyid';
  public loggedUserDetailAsJson(obj) {
    localStorage.setItem('userDetails', JSON.stringify(obj));
  }

  public getCompanyName() {
    this.companyName = JSON.parse(localStorage.getItem('userDetails')).companyname;
    return atob(this.companyName);
  }

  public getFirebaseNotificationDatabaseBasicPath() {
    this.firebaseNotificationPath = JSON.parse(localStorage.getItem('userDetails')).firebasenotificationdatabasebasicpath;
    return atob(this.firebaseNotificationPath);
  }

  public getuserId() {
    const decodeUserid = atob(JSON.parse(localStorage.getItem('userDetails')).userid);
    this.userId = parseInt(decodeUserid, 10);
    return this.userId;
  }
  public getRoleId() {
    const decoderoleId = atob(JSON.parse(localStorage.getItem('userDetails')).roleid);
    this.roleId = parseInt(decoderoleId, 10);
    return this.roleId;
  }
  public getTokenId() {
    this.tokenId = JSON.parse(localStorage.getItem('userDetails')).token;
    return this.tokenId;
  }

  getPayrollId(){
    this.payrollId = JSON.parse(localStorage.getItem('userDetails')).payrollid;
    return this.payrollId;
  }

  

  public getUserName() {
    this.userName = JSON.parse(localStorage.getItem('userDetails')).displayname;
    return atob(this.userName);
  }

  public getUserDepartment() {
    const userDepartment = JSON.parse(localStorage.getItem('userDetails')).department;
    return atob(userDepartment);
  }

  public getUserImage() {
    this.userImage = JSON.parse(localStorage.getItem('userDetails')).imagepath;
    return atob(this.userImage);
  }

  public getUserMailId() {
    this.userMail = JSON.parse(localStorage.getItem('userDetails')).usermail;
    return atob(this.userMail);
  }

  public getRole() {
    this.role = JSON.parse(localStorage.getItem('userDetails')).role;
    return atob(this.role);
  }
 

  public getHeaders() {
    return new HttpHeaders().set('authorization', 'bearer' + ' ' + this.getTokenId());
  }

  public getIncomingMailConfig() {
    const config = JSON.parse(sessionStorage.getItem('incomingMailConfig'));
    return {
      'MailAddress': config.email,
      'MailPassword': atob(config.password)
    };
  }

  public getOutgoingMailConfig() {
    const config = JSON.parse(sessionStorage.getItem('outgoingMailConfig'));
    return {
      'MailAddress': config.email,
      'MailPassword': atob(config.password)
    };
  }

  public getTimeZone() {
    this.timezone = JSON.parse(localStorage.getItem('userDetails')).clienttimezone;
    return atob(this.timezone);
  }

  public getUserStatus() {
    this.userstatus = JSON.parse(localStorage.getItem('userDetails')).userstatus;
    return atob(this.userstatus);
  }

  public getUserLocationCoordinate() {
    this.userlocationCoordinate = JSON.parse(localStorage.getItem('userDetails')).userLocationCoordinate;
    return (this.userlocationCoordinate);
  }

  public getLicenseExpiryDate() {
    const licenseExpiryDate = JSON.parse(localStorage.getItem('userDetails')).licenseExpiryDate;
    return atob(licenseExpiryDate);
  }

  public commentHeaders() {
    return new HttpHeaders().set('authorization', 'bearer' + ' ' + this.getTokenId())
      .set('Content-Type', 'application/json; charset=utf-8');
  }


  
}




