import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class ReceivedTaskService {
    constructor(private http: HttpClient, private _configuration: Configuration) {
    }

    public tasklist<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getMyReceivedTasks, {
            headers: this._configuration.getHeaders()
        });
    }

    public assignedUserList<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getMyReceivedTaskAssigners, { headers: this._configuration.getHeaders() });
    }

    public receivedTaskStats<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getMyReceivedTaskSummary, { headers: this._configuration.getHeaders() });
    }

    public userTaskStats<T>(id): Observable<T> {
        return this.http.get<T>(this._configuration._getMyReceivedTaskSummaryByAssignerId + id, {
            headers: this._configuration.getHeaders()
        });
    }

    public changeTaskEvent(status) {
        return this.http.put(this._configuration._saveReceiverTaskEvent, status, { headers: this._configuration.getHeaders() });
    }

    public getArchivedReceivedTask<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getArchivedReceivedTask, { headers: this._configuration.getHeaders() }
        );
    }

    public getArchivedMyTask<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getArchivedMyTasks, { headers: this._configuration.getHeaders() }
        );
    }

    public bulkArchivedforReceivedTask(taskIdList) {
        return this.http.put(this._configuration._bulkReceivedTaskArchive, taskIdList, { headers: this._configuration.getHeaders() });
    }

    public filteredTaskByDate(dateData): Observable<any> {
        return this.http.post(this._configuration._listReceivedTaskByDate, dateData, { headers: this._configuration.getHeaders() });
    }

    public getTaskByTaskId(taskId): Observable<any> {
        return this.http.get(this._configuration._getTaskByTaskId + '?id=' + taskId, { headers: this._configuration.getHeaders() })
    }

    public getReceivedTaskUnReadCount<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getReceivedTaskUnReadCountURL, { headers: this._configuration.getHeaders() });
    }
}


