import { RouteInfo } from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '/todotask', title: 'To-Do', icon: 'fa fa-list',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/mytask', title: 'My Task', icon: 'fa fa-list-alt',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/receivedtask', title: 'Received', icon: 'icon-layers', class: '', badge: '',
        badgeClass: 'badge badge-pill cus-badge-unread-count', isExternalLink: false, submenu: []
    },
    {
        path: '/assignedtask', title: 'Assigned', icon: 'ft-clipboard', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Attendance', icon: 'fa fa-calendar-check-o', class: 'has-sub', badge: '',
        badgeClass: '', isExternalLink: false, submenu: [
            {
                path: '/attendance/myattendance', title: 'My Attendance', icon: '', class: '', badge: '',
                badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/attendance/userattendance', title: 'Team Attendance', icon: '', class: '', badge: '',
                badgeClass: '', isExternalLink: false, submenu: []
            },
           /* {
                path: '/attendance/bulkattendance', title: 'Bulk Attendance', icon: '', class: '', badge: '',
                badgeClass: '', isExternalLink: false, submenu: []
            },*/
        ]
    },
    {
        path: '/inbox', title: 'Mail', icon: 'ft-mail', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: []
    },
    //  {
    //     path: '/chat', title: 'Chat', icon: 'ft-message-square', class: '', badge: '',
    //     badgeClass: '', isExternalLink: false, submenu: []
    // }, 
    {
        path: '/myworkgroup', title: 'Workgroup', icon: 'icon-users', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/analytics', title: 'Analytics', icon: 'ft-bar-chart-2', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Admin', icon: 'fa fa-user-plus', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            {
                path: '/admin/company', title: 'Company', icon: 'fas fa-building', class: '', badge: '',
                badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/branch', title: 'Branches', icon: 'ft-briefcase', class: '', badge: '',
                badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '', title: 'User', icon: 'ft-users', class: 'has-sub', badge: '',
                badgeClass: '', isExternalLink: false, submenu: [
                    {
                        path: '/admin/adduser', title: 'Add User', icon: '', class: '', badge: '',
                        badgeClass: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/admin/edituser', title: 'List User', icon: '', class: '', badge: '',
                        badgeClass: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/admin/addmedical', title: 'Add Medical Form', icon: '', class: '', badge: '',
                        badgeClass: '', isExternalLink: false, submenu: []
                    },
                ]
            },
            {
                path: '', title: 'Attendance', icon: 'fa fa-clock-o', class: 'has-sub', badge: '',
                badgeClass: '', isExternalLink: false, submenu: [

                    {
                        path: '/admin/settings', title: 'Settings', icon: '', class: '', badge: '',
                        badgeClass: '', isExternalLink: false, submenu: []

                    },

                    {
                        path: '/admin/attendancereport', title: 'Attendance Report', icon: '', class: '', badge: '',
                        badgeClass: '', isExternalLink: false, submenu: []
                    },
                ]
            },

            {
                path: '/admin/upload', title: 'Announcement', icon: 'ft-upload', class: '', badge: '',
                badgeClass: '', isExternalLink: false, submenu: []
            }
        ]
    },
    {
        path: '', title: 'Archived', icon: 'icon-trash', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            /* {
                path: '/archivedtasks/archived-self-task', title: 'Self Task', icon: '',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            }, */
            {
                path: '/archivedtasks/archived-tasks-receiver', title: 'Received', icon: '',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/archivedtasks/archived-tasks-assigner', title: 'Assigned', icon: '',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
        ]
    },
    {
        path: '/project', title: 'Project', icon: 'fa fa-file-text', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: []
    },
    // {
    //     path: '/resumeBuilder', title: 'Resume Builder', icon: 'fa fa-address-card', class: '', badge: '',
    //     badgeClass: '', isExternalLink: false, submenu: []
    // },
    {
        path: '/profile', title: 'My Profile', icon: 'fa fa-user', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: []
    },
    { path: '/report', title: 'Report', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/settings', title: 'Settings', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
];
