import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class AttachmentModel {
    @JsonProperty('AttachmentExtension', String)
    attachmentExtension: string;
    @JsonProperty('TaskAttachmentId', Number)
    taskAttachmentId: number;
    @JsonProperty('URL', String)
    url: string;

    constructor() {
        this.attachmentExtension = void 0;
        this.taskAttachmentId = void 0;
        this.url = void 0;
    }
}

@JsonObject
export class ReceiversModel {
    @JsonProperty('ClientCountry', String)
    clientCountry: string;
    @JsonProperty('ClientName', String)
    clientName: string;
    @JsonProperty('ClientTimeZone', String)
    clientTimeZone: string;
    @JsonProperty('CompanyCountry', String)
    companyCountry: string;
    @JsonProperty('CompanyName', String)
    companyName: string;
    @JsonProperty('CompanyTimeZone', String)
    CompanyTimeZone: string;
    @JsonProperty('Department', String)
    department: string;
    @JsonProperty('DisplayName', String)
    displayName: string;
    @JsonProperty('Email', String)
    email: string;
    @JsonProperty('FirstName', String)
    firstName: string
    @JsonProperty('ImagePath', String)
    imagePath: string;
    @JsonProperty('IsFyi', Boolean)
    isFyi: boolean
    @JsonProperty('IsGroupMember', Boolean)
    isGroupMember: boolean;
    @JsonProperty('IsRead', Boolean)
    isRead: boolean;
    @JsonProperty('LastName', String)
    lastName: string;
    @JsonProperty('Status', String)
    status: string;
    @JsonProperty('StatusId', Number)
    statusId: number;
    @JsonProperty('UserId', Number)
    userId: number;

    constructor() {
        this.clientCountry = void 0;
        this.clientName = void 0;
        this.clientTimeZone = void 0;
        this.companyCountry = void 0;
        this.companyName = void 0;
        this.CompanyTimeZone = void 0;
        this.department = void 0;
        this.displayName = void 0;
        this.email = void 0;
        this.firstName = void 0
        this.imagePath = void 0;
        this.isFyi = void 0;
        this.isGroupMember = void 0;
        this.isRead = void 0;
        this.lastName = void 0;
        this.status = void 0;
        this.statusId = void 0;
        this.userId = void 0;
    }
}
@JsonObject
export class AssignedTaskModel {
    @JsonProperty('BellCount', Number)
    bellCount: number;
    @JsonProperty('CreateDatetime', String)
    createDateTime: string;
    @JsonProperty('Description', String)
    description: string;
    @JsonProperty('Id', Number)
    id: number;
    @JsonProperty('Name', String)
    name: string;
    @JsonProperty('ReadDatetime', String)
    readDatetime: string;
    @JsonProperty('Receivers', [ReceiversModel], true)
    receivers: ReceiversModel;
    @JsonProperty('Status', String)
    status: number;
    @JsonProperty('TaskStatusId', Number)
    taskStatusId: number;
    @JsonProperty('Reward', Number)
    reward: number;
    @JsonProperty('IsGroupTask', Boolean)
    isGroupTask: boolean;
    @JsonProperty('IsSelfTask', Boolean)
    isSelfTask: boolean;
    @JsonProperty('IsReopenedTask', Boolean)
    isReopenedTask: boolean
    @JsonProperty('TaskAttachments', [AttachmentModel])
    taskattachments: AttachmentModel
    @JsonProperty('IsNewComment', Boolean)
    isNewComment: boolean;
    @JsonProperty('TaskPriorityId', Number, true)
    taskSeverityId: number;
    @JsonProperty('targetDate', String)
    targetDate: string;
    constructor() {
        this.bellCount = void 0;
        this.createDateTime = void 0;
        this.description = void 0;
        this.id = void 0;
        this.name = void 0;
        this.readDatetime = void 0;
        this.receivers = void 0;
        this.status = void 0;
        this.taskStatusId = void 0;
        this.reward = void 0;
        this.isGroupTask = void 0;
        this.isSelfTask = void 0;
        this.isReopenedTask = void 0;
        this.taskattachments = void 0;
        this.isNewComment = void 0;
        this.taskSeverityId = void 0;
    }
}

export class AssignedTaskJSONModal {
    deserialize(input) {
        const deserializedReceiversData = deserializeReceiver(input.Receivers, input.IsGroupTask);
        const deserializedAttachmentData = deserializeTaskAttachment(input.TaskAttachments);
        const obj = {
            bellCount: input.BellCount,
            createDateTime: input.CreateDatetime,
            description: input.Description,
            id: input.Id,
            name: input.Name,
            readDatetime: input.ReadDatetime,
            receivers: deserializedReceiversData.deserialized_receivers,
            fyi_receivers: deserializedReceiversData.fyi_receivers,
            group_receivers: deserializedReceiversData.group_receivers,
            status: input.Status,
            taskStatusId: input.TaskStatusId,
            reward: input.Reward,
            isGroupTask: input.IsGroupTask,
            isSelfTask: input.IsSelfTask,
            isReopenedTask: input.IsReopenedTask,
            taskattachments: deserializedAttachmentData,
            isNewComment: input.IsNewComment,
            taskSeverityId: input.TaskPriorityId,
            isDescriptionOpen: false,
            isSaving: false,
            rewardComment:input.RewardComment,
            targetDate: input.TargetDate 
        }

        if (deserializedReceiversData.main_receiver) {
            obj['main_receiver'] = deserializedReceiversData.main_receiver
        }

        return obj;
    }
}

export class ScheduledTaskJSONModal {
    deserialize(input) {
        const Receivers = input.FyiReceivers.length ? input.FyiReceivers.concat(input.Receivers) : input.Receivers
        const deserializedReceiversData = deserializeReceiver(Receivers, input.IsGroupTask);
        const deserializedAttachmentData = deserializeTaskAttachment(input.TaskAttachments);

        const obj = {
            bellCount: input.BellCount,
            createDateTime: input.AddDateTimeInUTC,
            description: input.Description,
            id: input.Id,
            name: input.Name,
            readDatetime: input.ReadDatetime,
            receivers: deserializedReceiversData.deserialized_receivers,
            fyi_receivers: deserializedReceiversData.fyi_receivers,
            group_receivers: deserializedReceiversData.group_receivers,
            status: input.Status,
            taskStatusId: input.TaskStatusId,
            reward: input.Reward,
            isGroupTask: input.IsGroupTask,
            isSelfTask: input.IsSelfTask,
            isReopenedTask: input.IsReopenedTask,
            taskattachments: deserializedAttachmentData,
            isNewComment: input.IsNewComment,
            taskSeverityId: input.TaskPriorityId,
            isDescriptionOpen: false,
            schedulerStatus: input.SchedulerStatus ? input.SchedulerStatus : '',
            schedulerTime: input.SchedulerTime ? input.SchedulerTime : ''
        }

        if (deserializedReceiversData.main_receiver) {
            obj['main_receiver'] = deserializedReceiversData.main_receiver
        }

        return obj;
    }
}


function deserializeReceiver(receivers, isGroupTask) {
    const obj: any = {};
    obj['fyi_receivers'] = [];
    obj['group_receivers'] = [];
    obj['deserialized_receivers'] = [];

    if (receivers && receivers.length > 0) {
        receivers.forEach(receiver => {
            const deseializedReceiver = {
                clientCountry: receiver.ClientCountry ? receiver.ClientCountry : '',
                clientName: receiver.ClientName ? receiver.ClientName : '',
                clientTimeZone: receiver.ClientTimeZone ? receiver.ClientTimeZone : '',
                companyCountry: receiver.CompanyCountry,
                companyName: receiver.CompanyName,
                companyTimeZone: receiver.CompanyTimeZone,
                department: receiver.Department ? receiver.Department : '',
                displayName: receiver.DisplayName,
                email: receiver.Email,
                firstName: receiver.FirstName,
                imagePath: receiver.ImagePath,
                isFyi: receiver.IsFyi,
                isGroupMember: receiver.IsGroupMember,
                isRead: receiver.IsRead,
                lastName: receiver.LastName,
                status: receiver.Status ? receiver.Status : '',
                statusId: receiver.StatusId,
                userId: receiver.UserId,
                userTaskStatusId: receiver.UserTaskStatusId,
                userTaskStatus: receiver.UserTaskStatus

            }

            obj.deserialized_receivers.push(deseializedReceiver);

            if (isGroupTask) {
                obj.group_receivers.push(deseializedReceiver);
            } else {
                if (deseializedReceiver.isFyi) {
                    obj.fyi_receivers.push(deseializedReceiver);
                } else {
                    obj.main_receiver = deseializedReceiver;
                }
            }
        })
    }
    return obj;
}


function deserializeTaskAttachment(attachments) {
    const deserializedAttachmentArray = [];
    if (attachments && attachments.length > 0) {
        attachments.forEach(attachment => {
            deserializedAttachmentArray.push({
                attachmentExtension: attachment.AttachmentExtension,
                taskAttachmentId: attachment.TaskAttachmentId,
                url: attachment.URL
            });
        });
    }

    return deserializedAttachmentArray;
}

export class NotificationTaskJSONModal {
    deserialize(input) {
        const deserializedReceiversData = deserializeNotificationReceiver(input.Receivers, input.IsGroupTask);
        const deserializedAttachmentData = deserializeTaskAttachment(input.TaskAttachments);

        const obj = {
            createUserId: input.CreateUserId,
            createUserDisplayName: input.CreatedDisplayName,
            createUserImagePath: input.CreateUserImagePath,
            isFYI: input.isFYI,
            bellCount: input.BellCount,
            createDateTime: input.CreateDatetime,
            description: input.Description,
            id: input.Id,
            isRead: input.isRead,
            name: input.Name,
            readDatetime: input.ReadDatetime,
            receiversDetails: deserializedReceiversData,
            fyi_receivers: [],
            group_receivers: [],
            status: input.Status,
            taskStatusId: input.TaskStatusId,
            reward: input.Reward,
            isGroupTask: input.IsGroupTask,
            isSelfTask: input.IsSelfTask,
            isReopenedTask: input.IsReopenedTask,
            taskattachments: deserializedAttachmentData,
            isNewComment: input.IsNewComment,
            taskSeverityId: input.TaskPriorityId,
            rewardComment:input.RewardComment
        }
        return obj;
    }
}

function deserializeNotificationReceiver(receivers, isGroupTask) {
    const obj: any = [];
    if (receivers && receivers.length > 0) {
        receivers.forEach(receiver => {
            const deseializedReceiver = {
                clientCountry: receiver.ClientCountry,
                clientName: receiver.ClientName,
                clientTimeZone: receiver.ClientTimeZone,
                companyCountry: receiver.CompanyCountry,
                companyName: receiver.CompanyName,
                companyTimeZone: receiver.CompanyTimeZone,
                department: receiver.Department,
                displayName: receiver.DisplayName,
                email: receiver.Email,
                firstName: receiver.FirstName,
                imagePath: receiver.ImagePath,
                isFyi: receiver.IsFyi,
                isGroupMember: receiver.IsGroupMember,
                isRead: receiver.IsRead,
                lastName: receiver.LastName,
                status: receiver.Status,
                statusId: receiver.StatusId,
                userId: receiver.UserId,
                userTaskStatusId: receiver.UserTaskStatusId,
                userTaskStatus: receiver.UserTaskStatus
            }
            obj.push(deseializedReceiver);
        })
    }
    return obj;
}
