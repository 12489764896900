import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConvertDateToLocal, DateConversionPipe,customDateFormatPipe, MomentPipe,newMomentPipe} from './dateconversion.pipe';

@NgModule({
  declarations: [DateConversionPipe,ConvertDateToLocal,customDateFormatPipe, MomentPipe, newMomentPipe],
  imports: [CommonModule],
  exports: [DateConversionPipe,ConvertDateToLocal,customDateFormatPipe, MomentPipe, newMomentPipe]
})

export class MainDatePipe {}
