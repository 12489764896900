import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';

@Injectable()
export class ActivityLogSidebarCommunicationService {
  private verticalTimelineData = new BehaviorSubject({tasktype: '', taskDetails: [], isOpenSidebarModal: false});
  receivedData = this.verticalTimelineData.asObservable();

  getUserDetails(data) {
    this.verticalTimelineData.next(data);
  }
}
