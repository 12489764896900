import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterText',
})

export class FilterText implements PipeTransform {
  transform(items: any[], searchText: string, jsonString: string): any[] {
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(it => {
      return it[jsonString].toLowerCase().includes(searchText);
    });
  }
}
