import { Input } from "@angular/core";

export class TaskDetailedViewJSONResponseModel {
    deserialise(input: any) {
        const page = input.page;
        const isFYI = page === "assignedTask" && input.fyi_receivers.length ? true : input.isFYI;
        const deserializedReceiversData = deserializeReceiver(input.fyi_receivers && input.fyi_receivers.length ? input.fyi_receivers : input.group_receivers && input.group_receivers.length ? input.group_receivers : input.receiversDetails, page);
        const deserializedAttachmentData = deserializeTaskAttachment(input.taskAttachments ? input.taskAttachments : input.taskattachments);
        const obj = {
            userId: input.createUserId ? input.createUserId : input.main_receiver && input.main_receiver.userId ? input.main_receiver.userId : null,
            userImagePath: input.main_receiver && input.main_receiver.imagePath ? input.main_receiver.imagePath : input.createUserImagePath,
            userDisplayName: input.main_receiver && input.main_receiver.displayName ? input.main_receiver.displayName : input.createUserDisplayName,
            description: input.description,
            isRead: input.isRead,
            isFYI: isFYI,
            isSelfTask: input.isSelfTask,
            isGroupTask: input.isGroupTask,
            isNewComment: input.isNewComment,
            bellCount: input.bellCount,
            name: input.name,
            createDateTime: input.createDateTime,
            isReopenedTask: input.isReopenedTask,
            taskPriorityId: input.taskPriorityId ? input.taskPriorityId : input.taskSeverityId,
            // fyiReceivers: input.fyi_receivers ? input.fyi_receivers : null,
            receivers: deserializedReceiversData,
            taskStatusId: input.taskStatusId,
            reward: Number(input.reward),
            page: input.page,
            id: input.id,
            status: input.status ? input.status : null,
            attachments: deserializedAttachmentData,
            taskType: input.taskType ? input.taskType : null,
            schedulerStatus: input.schedulerStatus ? input.schedulerStatus : '',
            schedulerTime: input.schedulerTime ? input.SchedulerTime : '',
            rewardComment: input.rewardComment,
            rewardStarImages: input.reward ? new Array(Number(input.reward)) : '',
            targetDate:input.targetDate


        }
        return obj;
    }
}

function deserializeReceiver(receivers, page) {
    const deserialized_receivers = [];
    if (receivers && receivers.length > 0) {
        receivers.forEach(receiver => {
            const deseializedReceiver = {
                IsFyiUser: page === "assignedTask" || page === "dashboard" ? receiver.isFyi : receiver.IsFyiUser,
                ReceiverId: receiver.ReceiverId ? receiver.ReceiverId : receiver.userId,
                ReceiverImagePath: receiver.ReceiverImagePath ? receiver.ReceiverImagePath : receiver.imagePath,
                ReceiverName: receiver.ReceiverName ? receiver.ReceiverName : receiver.displayName,
                UserTaskStatus: receiver.userTaskStatus ? receiver.userTaskStatus : receiver.UserTaskStatus,
                UserTaskStatusId: receiver.userTaskStatusId ? receiver.userTaskStatusId : receiver.UserTaskStatusId,
            }
            deserialized_receivers.push(deseializedReceiver);
        })
        /* if (deserialized_receivers && deserialized_receivers.length > 1) {
            deserialized_receivers.sort(function (name1, name2) {
                if (name1.ReceiverName < name2.ReceiverName) {
                    return -1;
                } else if (name1.ReceiverName > name2.ReceiverName) {
                    return 1;
                } else {
                    return 0;
                }
            });
            return deserialized_receivers;
        } else {
            return deserialized_receivers;
        } */
        return deserialized_receivers;
    }
}

function deserializeTaskAttachment(attachments) {
    const deserializedAttachmentArray = [];

    if (attachments && attachments.length > 0) {
        attachments.forEach(attachment => {
            deserializedAttachmentArray.push({
                AttachmentExtension: attachment.AttachmentExtension ? attachment.AttachmentExtension : attachment.attachmentExtension,
                TaskAttachmentId: attachment.TaskAttachmentId ? attachment.TaskAttachmentId : attachment.taskAttachmentId,
                url: attachment.URL ? attachment.URL : attachment.url
            });
        });
    }

    return deserializedAttachmentArray;
}
