import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoSanitizePipe } from './domSanitizer.pipe'

@NgModule({
    declarations: [NoSanitizePipe],
    imports: [CommonModule],
    exports: [NoSanitizePipe]
})


export class DomSanitizer {

    static forRoot() {
        return {
            ngModule: DomSanitizer,
            providers: [],
        };
    }
}
