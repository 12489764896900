import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbDateStruct, NgbDatepicker, NgbDate, NgbTimeStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpModalService } from 'app/services/popup-modal.service';

@Component({
  selector: 'app-date-filter-popup',
  templateUrl: './date-filter-popup.component.html',
  styleUrls: ['./date-filter-popup.component.scss']
})
export class DateFilterPopupComponent implements OnInit {
  @ViewChild('datePicker1', { static: true }) datePicker1: NgbDatepicker;

  dateInputForm: FormGroup;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  popupDetails: any;
  isSubmittingDate: boolean;
  selectedTaskType: string;
  serviceSubscription: any

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private popUpModalService: PopUpModalService) { }

  ngOnInit() {

    this.serviceSubscription = this.popUpModalService.dateFilterCommunicationData.subscribe((data: any) => {
      if (data && data.isClosePopup) {
        this.isSubmittingDate = false;
        const popupData = {
          isClosePopup: false
        }
        this.popUpModalService.getDateFilterCommunicationData(popupData);
        this.activeModal.close('');
      }
    })

    this.dateInputForm = this.fb.group({
      'fromDate': this.popupDetails.filterDates ? this.popupDetails.filterDates.fromDate : '',
      'toDate': this.popupDetails.filterDates ? this.popupDetails.filterDates.toDate : '',
    });

    if (this.popupDetails.taskType !== 'Scheduled') {
      this.minDate = { year: 2008, month: 1, day: 1 };
      this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    } else {
      this.minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
      this.maxDate = { year: 2048, month: 12, day: 31 };
    }

  }

  closePopuModal() {
    this.activeModal.close('');
  }

  applyFilter() {
    const formValue = this.dateInputForm.value
    const FromDate: NgbDate = new NgbDate(formValue.fromDate.year, formValue.fromDate.month, formValue.fromDate.day);
    const isDateValid = FromDate.before(NgbDate.from({ year: formValue.toDate.year, month: formValue.toDate.month, day: formValue.toDate.day })) || FromDate.equals(NgbDate.from({ year: formValue.toDate.year, month: formValue.toDate.month, day: formValue.toDate.day }));
    if (isDateValid) {
      this.isSubmittingDate = true;
      const sendData = {
        isSubmitDate: true,
        form: this.dateInputForm
      }
      this.popUpModalService.getDateFilterCommunicationData(sendData);
      // this.activeModal.close(this.dateInputForm);
    } else {
      $('.date-filter-dateRangerErrAlert').slideDown('medium').delay(1200).slideUp('medium');
    }
  }

}
