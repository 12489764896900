import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Configuration } from './configuration.constants';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';


@Injectable()
export class ActivityLogService {

  constructor(private http: HttpClient, private _configuration: Configuration) { }

  public getAllActivityLogsByTaskId<T>(taskId): Observable<T> {
    return this.http.get<T>(this._configuration.getActivityLogsByTaskId + '?taskId=' + taskId ,{
        headers: this._configuration.getHeaders()
    });
}
public getElapsedOnHoldTimeByTaskId<T>(taskId): Observable<T> {
  return this.http.get<T>(this._configuration.getElapsedOnHoldTime + '?taskId=' + taskId ,{
      headers: this._configuration.getHeaders()
  });
}


}
