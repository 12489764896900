import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class CustomDateParserService extends NgbDateParserFormatter {

  constructor() {
    super(); 
  } 
  format(date: NgbDateStruct | null): string {
    return date ? `${this.pad(date.day)}-${this.pad(date.month)}-${date.year}` : '';
  }
  parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const parts = value.split('-').map(part => parseInt(part, 10));
    return (parts.length === 3 && !isNaN(parts[0]) && !isNaN(parts[1]) && !isNaN(parts[2])) 
      ? { day: parts[0], month: parts[1], year: parts[2] } 
      : null;
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
