
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class CommentService {
    constructor(private http: HttpClient, private _configuration: Configuration) { }

    public getComments<T>(taskId): Observable<T> {
        return this.http.get<T>(this._configuration._getComments + taskId, {
            headers: this._configuration.getHeaders()
        });
    }

    public changeTaskCommentStatus(status) {
        return this.http.put(this._configuration._changeTaskCommentStatus, status, { headers: this._configuration.getHeaders() })
    }

    public saveComments(comment) {
        return this.http.post(this._configuration._addComment, comment, { headers: this._configuration.getHeaders() });
    }
}