import {
    Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef,
    Renderer2
} from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../services/toastr.service';
import { CommentService } from '../services/comment.service';
import { Configuration } from '../services/configuration.constants';
import { UploadService } from '../services/upload.service';
import { AppConfig } from '../app.config';
import { AssignedTaskService } from '../services/assignedtask.service';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { DetailedViewCommunicationService } from '../services/detailedViewCommunication.service'
import { CommentsJSONResponseModel } from '../shared/models/comments.JSON.model';
import { CommonService } from '../services/common.service';
import { onCreateObjectURLAndDownloadFile } from '../shared/utils/common-functions';
import * as commentTaskSkeleton from './comment-skeleton-config'

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({ height: '*', opacity: 0 })),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),

                group([
                    animate(350, style({ height: 0 })),
                    animate('600ms ease-in-out', style({ 'opacity': '0' }))
                ])

            ]),
            transition(':enter', [
                style({ height: '0', opacity: 0 }),

                group([
                    animate(800, style({ height: '*' })),
                    animate('1200ms ease-in-out', style({ 'opacity': '1' }))
                ])

            ])
        ])
    ]
})

export class CommentsComponent implements OnInit, OnChanges {
    skeleton = commentTaskSkeleton.commentSkeleton

    modalRef: NgbModalRef;
    @ViewChild('commentModal', { static: true }) commentModal: ElementRef;
    @Input() isOpenCommentsModal = false;
    @Input() taskDetails;
    @Input() canSendComments = true;
    @Output() closeCommentModalEvent = new EventEmitter<any>();
    @Output() communicationEvent = new EventEmitter<any>();

    settings: any = AppConfig.settings;
    closeResult: any;

    loggedInUserId: number;
    loggedInUserImage: string;

    commentList: any = [];
    userComment: any = '';
    commentLoading = true;
    noComments = true;
    isValidAttachment = false;
    extensionError = false;
    biggerFileSize = false;
    attachmentExtension: any;
    commentSending: boolean;
    isNotCommentSent: boolean;
    attachedFileFormData: any;
    uploadedFileName: string;
    isMaximizeModeOn = false;
    videoURL;
    maxFileSize = this.settings.tasksettings.MAX_UPLOAD_SIZE;
    maxFileSizeText = this.settings.tasksettings.MaxFileSizeText;

    constructor(private toastrservice: NGXToastrService, private commentService: CommentService,
        private detailedViewCommunicationService: DetailedViewCommunicationService,
        private conf: Configuration, private modalDialog: NgbModal,
        private cdr: ChangeDetectorRef, private assignedTaskService: AssignedTaskService,
        private uploadService: UploadService, private renderer: Renderer2, private commonService: CommonService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.isOpenCommentsModal) {
            this.showComments(this.taskDetails);
        }
    }

    fileChange(event) {
        const fileList: FileList = event.target.files;
        this.attachedFileFormData = undefined;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            this.uploadedFileName = file.name;
            this.attachmentExtension = this.uploadedFileName.substr((this.uploadedFileName.lastIndexOf('.') + 1)).toLowerCase();
            switch (this.attachmentExtension) {
                case 'jpg':
                    this.extensionError = false;
                    break;
                case 'jpeg':
                    this.extensionError = false;
                    break;
                case 'png':
                    this.extensionError = false;
                    break;
                case 'mp3':
                    this.extensionError = false;
                    break;
                case 'wav':
                    this.extensionError = false;
                    break;
                case 'm4a':
                    this.extensionError = false;
                    break;
                case 'mp4':
                    this.extensionError = false;
                    break;
                case 'mov':
                    this.extensionError = false;
                    break;
                case 'txt':
                    this.extensionError = false;
                    break;
                case 'doc':
                    this.extensionError = false;
                    break;
                case 'docx':
                    this.extensionError = false;
                    break;
                case 'ppt':
                    this.extensionError = false;
                    break;
                case 'pptx':
                    this.extensionError = false;
                    break;
                case 'pdf':
                    this.extensionError = false;
                    break;
                case 'xlsx':
                    this.extensionError = false;
                    break;
                case 'xls':
                    this.extensionError = false;
                    break;
                case 'csv':
                    this.extensionError = false;
                    break;
                case 'zip':
                    this.extensionError = false;
                    break;
                case 'rar':
                    this.extensionError = false;
                    break;
                default:
                    this.extensionError = true;
                    this.isValidAttachment = false;
                    this.toastrservice.typeError('File format is not supported');
                    return false;
            }

            if (file) {
                if (file.size <= this.maxFileSize) {
                    if (!this.extensionError) {
                        const sendFormData = new FormData();
                        sendFormData.append('file', file);
                        this.attachedFileFormData = sendFormData;
                        this.isValidAttachment = true;
                        this.biggerFileSize = false;
                    } else {
                        this.attachedFileFormData = undefined;
                        this.attachmentExtension = '';
                        this.isValidAttachment = false;
                    }
                } else {
                    this.biggerFileSize = true;
                    this.toastrservice.typeError('Maximum file size allowed is ' + this.maxFileSizeText + '.');
                }
            }
        }
        // For Re-Select the same Image
        event.target.value = '';
    }

    showComments(taskDetails) {
        if (taskDetails) {
            taskDetails.task.taskType = taskDetails.taskType;
            this.taskDetails = new CommentsJSONResponseModel().deserialise(taskDetails.task);
            this.userComment = '';
            this.commentLoading = true;
            this.cdr.detectChanges();
            this.noComments = true;
            this.loggedInUserImage = this.conf.getUserImage();
            this.loggedInUserId = this.conf.getuserId();
            const loggedInUserMailId = this.conf.getUserMailId();
            this.isNotCommentSent = false;
            if (this.taskDetails.IsNewComment) {
                const commentRead = {
                    'UserId': this.loggedInUserId,
                    'TaskId': this.taskDetails.taskId,
                    'TaskAction': taskDetails.taskType === "assigned" ? this.settings.assignedTaskListComponent.newComment_TaskAction : this.settings.receivedTaskListComponent.comments_TaskAction,
                    'TaskValue': taskDetails.taskType === "assigned" ? this.settings.assignedTaskListComponent.newComment_TaskValue : this.settings.receivedTaskListComponent.comments_TaskValue,
                    'ModBy': loggedInUserMailId
                }
                if (this.taskDetails.taskType === "myTask" || this.taskDetails.taskType === "received") {
                    taskDetails.isNewComment = false;
                    const details = {
                        type: 'commentStatus',
                        taskDetails: taskDetails.task
                    }
                    this.detailedViewCommunicationService.refreshTaskStats(details);
                    this.commentService.changeTaskCommentStatus(commentRead).subscribe(success => {
                        this.communicationEvent.emit('changeStatus');
                    }, error => {
                        console.log(error);
                    });
                } else {
                    this.assignedTaskService.changeAssignedTaskCommentStatus(commentRead).subscribe(success => {
                        taskDetails.isNewComment = false;
                        const details = {
                            type: 'commentStatus',
                            taskDetails: taskDetails.task
                        }
                        this.detailedViewCommunicationService.refreshTaskStats(details);

                        this.communicationEvent.emit('changeStatus');
                    }, error => {
                        console.log(error);
                    });
                }
            }
            this.commentService.getComments(this.taskDetails.taskId).subscribe(data => {
                this.commentList = data;
                this.noComments = false
                this.commentLoading = false;
            }, error => {
                this.commentLoading = false;
                this.noComments = true;
                this.toastrservice.typeError('Something went wrong. Please refresh the page')
            });
            this.isMaximizeModeOn = false;
            this.modalRef = this.modalDialog.open(this.commentModal, {
                size: 'lg', backdrop: 'static',
                windowClass: 'cus-comment-modal-cont'
            });
            this.modalRef.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    getDismissReason(reason: any) {
        if (reason === ModalDismissReasons.ESC) {
            this.closeCommentModal();
        }
    }

    changeCommentModalSize() {
        const commentWindow = document.querySelector('.cus-comment-modal');
        if (this.isMaximizeModeOn) {
            this.renderer.removeClass(commentWindow, 'cus-comment-modal-cont');
        } else {
            this.renderer.addClass(commentWindow, 'cus-comment-modal-cont');
        }
        this.isMaximizeModeOn = !this.isMaximizeModeOn;
    }

    sendComment(comment) {
        if ((comment !== '' && comment.trim().length) || this.isValidAttachment) {
            this.commentSending = true;
            this.isNotCommentSent = false;
            if (comment === 'ThumbsUp') {
                comment = String.fromCodePoint(128077);
            } else if (comment === '') {
                comment = null;
            }
            if (this.isValidAttachment) {
                this.uploadService.uploadTaskCommentAttachment(this.attachedFileFormData)
                    .subscribe((data) => {
                        const fileInfo = JSON.parse(data['_body']);
                        this.onSaveComments(comment, this.attachmentExtension, fileInfo.FileLink, fileInfo.FileSize);
                    }, error => {
                        console.log(error);
                        this.toastrservice.typeError('Something went wrong. Please try again.');
                        this.commentSending = false;
                        this.isNotCommentSent = true;
                    });
            } else {
                this.onSaveComments(comment, null, null, null);
            }

        } else {
            this.toastrservice.typeError('Empty Comment or Attachment can not be sent');
        }
    }

    onSaveComments(comment, commentsAttachmentExtension, commentsAttachedFileURL, commentAttachmentFileSize) {
        const commentSendData = {
            'TaskId': this.taskDetails.taskId,
            'Comments': comment,
            'AttachmentExtension': commentsAttachmentExtension,
            'AttachmentURL': commentsAttachedFileURL,
            'FileSize': commentAttachmentFileSize
        }
        this.commentService.saveComments(commentSendData).subscribe(success => {
            this.attachedFileFormData = undefined;
            this.attachmentExtension = '';
            this.isValidAttachment = false;
            this.commentService.getComments(this.taskDetails.taskId).subscribe(data => {
                this.commentList = data;
                this.commentSending = false;
            }, error => {
                this.commentSending = false;
                this.toastrservice.typeError('Something went wrong while updating the comments. Please refresh the page.');
            });
            this.userComment = '';
        }, error => {
            console.log("error", error)
            this.commentSending = false;
            this.isNotCommentSent = true;
        });
    }

    clearAttachment() {
        this.attachedFileFormData = undefined;
        this.attachmentExtension = '';
        this.isValidAttachment = false;
    }

    closeCommentModal() {
        this.modalRef.close();
        this.closeCommentModalEvent.emit(true);
        this.communicationEvent.emit('closeModal');
        this.commentList = [];
        this.clearAttachment();
    }

    downloadAttachment(URL, event) {
        event.target.classList.add('cus-beat-effect');
        const sendObj = {
            'AttachmemtLink': URL
        }

        this.commonService.downloadAttachment(sendObj)
            .subscribe((data: Blob) => {
                const fileName = URL.split('/').pop();
                onCreateObjectURLAndDownloadFile(data, fileName, event);
            }, error => {
                console.log(error);
                event.target.classList.remove('cus-beat-effect');
                this.toastrservice.typeError('Something went wrong. Please try again.');
            });
    }
}
