import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class UserProfileService {
    constructor(private http: HttpClient, private _configuration: Configuration) {

    }

    public userInfo<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getMyProfile, { headers: this._configuration.getHeaders() });
    }

    public updateUserDetail(status) {
        return this.http.put(this._configuration._updateUserDetail, status, { headers: this._configuration.getHeaders() });
    }

    public saveJobDescription(status){
        return this.http.post(this._configuration._saveJobDescription, status,  { headers: this._configuration.getHeaders() });
    }

    public deleteJobDescription(delId){
        return this.http.delete(this._configuration._deleteJobDescription  + delId , 
        { headers: this._configuration.getHeaders() });
    }

    public getJobDescriptionById(id){
        return this.http.get(this._configuration._getJobDescriptionById + id + '&active=true', { headers: this._configuration.getHeaders() });
    }

    public updateJobDescription(status){
        return this.http.put(this._configuration._updateJobDescription, status, { headers: this._configuration.getHeaders() })
    }

    public getHierachyRequest(status) {
        return this.http.put(this._configuration._getHierarchyRequest, status, { headers: this._configuration.getHeaders() });
    }

    public getHierachyAccept(status) {
        return this.http.put(this._configuration._getHierarchyAccept, status, { headers: this._configuration.getHeaders() });
    }

    public removeHierarchyUser(data) {
        return this.http.put(this._configuration._removeHierarchyUser, data, { headers: this._configuration.getHeaders() });
    }

    public removeSubordinates(data) {
        return this.http.put(this._configuration._removeSubordinates, data, { headers: this._configuration.getHeaders() });
    }

    public getMyHierarchy<T>(userId): Observable<T> {
        return this.http.get<T>(this._configuration._getMyHierarchy + userId, { headers: this._configuration.getHeaders() });
    }

    public getHierarchyRequestReceived() {
        return this.http.get<any>(this._configuration._getHierarchyRequestReceived, { headers: this._configuration.getHeaders() });
    }

    public getHierarchyRequestSent(): Observable<any> {
        return this.http.get(this._configuration._getHierarchyRequestSent, { headers: this._configuration.getHeaders() });
    }

    public getSkillSet() {
        return this.http.get<any>(this._configuration._getSkillset, { headers: this._configuration.getHeaders() });
    }

    public getUserSkillSet() {
        return this.http.get<any>(this._configuration._getAllUserSkillset, { headers: this._configuration.getHeaders() });
    }

    public addUserSkillSet(skillSet) {
        return this.http.post(this._configuration._addUserSkillset, skillSet, { headers: this._configuration.getHeaders() });
    }
    public addUserProfileSummary(profileSummary) {
        return this.http.post(this._configuration._addUserProfileSummary, profileSummary, { headers: this._configuration.getHeaders() });
    }

    public deleteUserSkillSet(skill) {
        return this.http.post(this._configuration._deleteUserSkillset, skill, { headers: this._configuration.getHeaders() });
    }
}
