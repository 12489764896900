import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class DataService {
    sharingData: any;
    constructor(private http: HttpClient, private _configuration: Configuration) {
    }

    public saveDashboardTaskData(task) {
        this.sharingData = task;
    }

    public getDashboardTaskData() {
        return this.sharingData;
    }

    // Overall Task Summary Statistics
    public taskSummary<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getTaskSummary, {
            headers: this._configuration.getHeaders()
        }
        );
    }

    // Today Task Statistics
    public todayTaskStatistics<T>(UTCTime): Observable<T> {
        return this.http.get<T>(this._configuration._getTodayTaskStatistics + UTCTime, {
            headers: this._configuration.getHeaders()
        }
        );
    }

    public recentTaskReceived<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getRecentTaskReceived, {
            headers: this._configuration.getHeaders()
        });
    }

    public recentTaskAssigned<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._recentAssignedTasks, {
            headers: this._configuration.getHeaders()
        });
    }

    public userTaskRewards<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getUserTaskRewards, {
            headers: this._configuration.getHeaders()
        }); 
    }

    public weeklyTaskStatistics<T>(UTCDateTime): Observable<T> {
        return this.http.get<T>(this._configuration._getWeeklyStatistics + UTCDateTime, {
            headers: this._configuration.getHeaders()
        });
    }

    public getUserPerformance<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getUserPerformance, {
            headers: this._configuration.getHeaders()
        });
    }
    public getWeeklyUserPerformanceStatistics<T>(UTCDateTime): Observable<T> {
        return this.http.get<T>(this._configuration._getWeeklyUserPerformanceStat + UTCDateTime, {
            headers: this._configuration.getHeaders()
        });
    }
}


