import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class AssignedTaskService {
  constructor(private http: HttpClient, private _configuration: Configuration) {
  }
  public assignedTaskList<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getAssignedTaskList, { headers: this._configuration.getHeaders() });
  }
  public assignedTaskStats<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getAssignedTaskSummary, { headers: this._configuration.getHeaders() });
  }
  public workgroupmemberTaskStats<T>(id): Observable<T> {
    return this.http.get<T>(this._configuration._getAssignedTaskSummaryByReceiverId + id, {
      headers: this._configuration.getHeaders()
    });
  }
  public assignedTaskListByReceiver<T>(id): Observable<T> {
    return this.http.get<T>(this._configuration._getAssignedTaskListByReceiver + id, {
      headers: this._configuration.getHeaders()
    });
  }
  public changeTaskAction(actionValue) {
    return this.http.put(this._configuration._saveAssignertaskEvent, actionValue, { headers: this._configuration.getHeaders() });
  }
  public archivedAssignedTask<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getArchivedAssignedTaskList,
      { headers: this._configuration.getHeaders() });
  }
  public archivedReportedTask<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getArchivedReportedTask,
      { headers: this._configuration.getHeaders() });
  }
  public filteredTaskByDate(dateDetails): Observable<any> {
    return this.http.post(this._configuration._listAssignTaskByDate, dateDetails, { headers: this._configuration.getHeaders() });
  }

  public addComment(comment) {
    return this.http.post(this._configuration._saveUserTaskComment, comment, { headers: this._configuration.getHeaders() });
  }

  public updateCommentandReward(comment) {
    return this.http.put(this._configuration._updateUserTaskCommentReward, comment, { headers: this._configuration.getHeaders() });
  }

  public taskEditReopen(editedData) {
    return this.http.put(this._configuration._updateTask, editedData, { headers: this._configuration.getHeaders() });
  }

  public bulkArchivedforAssignedTask(taskIdList) {
    return this.http.put(this._configuration._bulkAssignedTaskArchive, taskIdList, { headers: this._configuration.getHeaders() });
  }

  public shareAssignedTask(task) {
    return this.http.post(this._configuration._shareAssignedTask, task, { headers: this._configuration.getHeaders() })
  }

  public scheduleTask(task) {
    return this.http.post(this._configuration._saveSheduleTask, task, { headers: this._configuration.getHeaders() })
  }

  public saveAttachments(attachment) {
    return this.http.post(this._configuration._saveAttachments, attachment, { headers: this._configuration.getHeaders() })
  }

  public getComments<T>(taskId): Observable<T> {
    return this.http.get<T>(this._configuration._getComments + taskId, {
      headers: this._configuration.getHeaders()
    });
  }

  public saveComments(comment) {
    return this.http.post(this._configuration._addComment, comment, { headers: this._configuration.getHeaders() });
  }

  public changeAssignedTaskCommentStatus(status) {
    return this.http.put(this._configuration._changeAssignedTaskCommentStatus, status, { headers: this._configuration.getHeaders() })
  }

  public getAllScheduledTasks<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getAllScheduledTasks, { headers: this._configuration.getHeaders() });
  }

  public getScheduledTasksByDate(date): Observable<any> {
    return this.http.post(this._configuration._getScheduleTaskByDate, date, { headers: this._configuration.getHeaders() });
  }

  public deleteScheduledTask(taskId) {
    return this.http.post(this._configuration._deleteScheduledTask, taskId, { headers: this._configuration.getHeaders() })
  }


  public disableTask(sendObj) {
    return this.http.put(this._configuration._deleteTask, sendObj, { headers: this._configuration.getHeaders() })
  }
}

