import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef, NgbModalOptions, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  @ViewChild('videoModal', { static: true }) videoModal: ElementRef;
  @Output() closeVideoModalEvent = new EventEmitter<any>();
  @Input() isOpenVideoModal = false;
  @Input() videoURL;
  modalref: NgbModalRef;

  constructor(private modaldialog: NgbModal) { }

  ngOnChanges() {
    if (this.isOpenVideoModal) {
      this.openVideoModal();
    }
  }

  ngOnInit() {
  }

  openVideoModal() {
    this.modalref = this.modaldialog.open(this.videoModal, { size: 'lg' });
  }

  closeVideoModal() {
    this.modalref.close();
    this.closeVideoModalEvent.emit(true);
  }

}
