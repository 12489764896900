import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'convertDateFromUTC',
})

export class DateConversionPipe implements PipeTransform {
    transform(date): any {
        return moment.utc(date).local();
    }
}


@Pipe({
    name: 'convertDateToLocalFromIsoFormat',
})

export class ConvertDateToLocal implements PipeTransform {
    transform(date): any {
        return moment(date).local();
    }
}

@Pipe({
    name: 'date',
})

export class MomentPipe implements PipeTransform {
    transform(value: string) {
       var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'mediumDate');
        return value;
    }
}

@Pipe({
    name: 'customDateFormat',
})
export class customDateFormatPipe implements PipeTransform {
    transform(value: string) {
       var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'medium');
        return value;
    }
}

@Pipe({
    name: 'time',
})

export class newMomentPipe implements PipeTransform {
    transform(value: string) {
       var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'shortTime');
        return value;
    }
}