import { Injectable } from '@angular/core'
import { Http, Response } from '@angular/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './app-config.model';


@Injectable()
export class AppConfig {

    static settings: IAppConfig;

    constructor(private http: Http) { }

    load() {
        const jsonFile = `../config/config.${environment.name}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: Response) => {
                AppConfig.settings = <IAppConfig>response.json();
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
        /* this.http.get(jsonFile).map(res => {
            const result = res.json();
            return result;
        }).subscribe((data) => {
            AppConfig.settings = <IAppConfig>data;
        }); */
    }
}
