import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
// import * as firebase from 'firebase';
import * as firebase from 'firebase/app';
import '@firebase/messaging';
import { Configuration } from './configuration.constants';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PushNotificationService {
  browserType() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else {
      return 'unknown';
    }
  }

  messaging;
  currentMessage = new BehaviorSubject(null);

  constructor(private http: HttpClient, private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private _configuration: Configuration) {
    if (this.browserType() !== 'Safari') {
      //this.messaging = firebase.messaging();
    }
  }

  updateToken(token) {
    this.afAuth.authState.take(1).subscribe(user => {
      if (!user) {
        return
      }
      const data = { [user.uid]: token }
      this.db.object('fcmTokens/').update(data)
    })
  }

  /*getPermission() {
    this.messaging.requestPermission()
      .then(() => {
        return this.messaging.getToken()
      })
      .then(token => {
        // console.log(token)
        this.updateToken(token);
        const notifydata = {
          "AppTypeId": 3,
          "UserId": this._configuration.getuserId(),
          "DeviceName": "",
          "DeviceInstanceID": token,
          "AddBy": this._configuration.getUserMailId()
        }
        this.http.post(this._configuration._pushNotification, notifydata,
          {
            headers: this._configuration.getHeaders()
          }).subscribe(success => {
          }, error => console.log(error));
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
  }*/

  receiveMessage() {
    this.messaging.onMessage((payload) => {
      this.currentMessage.next(payload)
    });
  }

  receiveRecentNotifications(userid): Observable<any> {
    return this.http.get(this._configuration.getFirebaseNotificationDatabaseBasicPath()
      + userid + '.json?orderBy="$key"&limitToLast=5');
  }

  markAllNotificationsRead() {
    return this.http.post(this._configuration._markAllNotificationsRead, '', { headers: this._configuration.getHeaders() })
  }

  deleteNotification(rootName) {
    return this.http.delete(this._configuration._deleteNotification
      + '?rootName=' + rootName, { headers: this._configuration.getHeaders() });
  }

  deleteAllNotifications() {
    return this.http.delete(this._configuration._deleteNotification, { headers: this._configuration.getHeaders() });
  }
}
