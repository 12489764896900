import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';

import { Configuration } from './configuration.constants';



@Injectable()
export class UploadService {
    constructor(private http: HttpClient, private conf: Configuration, private httpObj: Http) {
    }

    getHeaders() {
        const headers = new Headers();
        headers.append('Authorization', 'bearer ' + this.conf.getTokenId());
        headers.append('enctype', 'multipart/form-data');
        const options = new RequestOptions({ headers: headers });
        return options;
    }

    uploadTaskAttachment(data) {
        const options = this.getHeaders();
        return this.httpObj.post(this.conf._uploadTaskAttachmentURL, data, options);
    }

    uploadTaskCommentAttachment(data) {
        const options = this.getHeaders();
        return this.httpObj.post(this.conf._uploadTaskCommentAttachmentURL, data, options);
    }

    uploadAnnouncementAttachment(data) {
        const options = this.getHeaders();
        return this.httpObj.post(this.conf._uploadAnnouncementAttachmentURL, data, options);
    }

    uploadUserImage(data) {
        const options = this.getHeaders();
        return this.httpObj.post(this.conf._uploadUserImageURL, data, options);
    }

    uploadMailAttachment(data) {
        const options = this.getHeaders();
        return this.httpObj.post(this.conf._uploadMailFIleAttachment, data, options);
    }
}
