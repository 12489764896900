import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';

import { Configuration } from './configuration.constants';

@Injectable()
export class SettingsService {

    settingsData: Observable<any>

    constructor(private http: HttpClient, private _configuration: Configuration) {

    }
    public updateSettings(updatedSettings) {
        return this.http.post(this._configuration._saveSettings, updatedSettings, { headers: this._configuration.getHeaders() });
    }

    public showSettings(refresh): Observable<any> {
        this.settingsData = refresh ? null : this.settingsData
        if (!this.settingsData) {
            this.settingsData =
                this.http.get(this._configuration._showSettings, { headers: this._configuration.getHeaders() })
                    .publishReplay(1).refCount();
            return this.settingsData;
        } else {
            return this.settingsData;
        }
    }

    public saveSettings(settingsdata) {
        return this.http.post(this._configuration._saveSettings, settingsdata, { headers: this._configuration.getHeaders() })
    }

    public getQrCode<T>(): Observable<T> {
        return this.http.get<T>(this._configuration._getQRCode, { headers: this._configuration.getHeaders() });
    }

    public validataMFACode(mfacode) {
        return this.http.post(this._configuration._validateMfaCode, mfacode, { headers: this._configuration.getHeaders() });
    }

    public validatePasswordForMfa(passwordconfirm) {
        return this.http.post(this._configuration._validateUserForMfa, passwordconfirm, { headers: this._configuration.getHeaders() });
    }
}


