import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { Configuration } from './configuration.constants';

@Injectable()
export class MyWorkgroupService {

  public activeWorkGroupMembers: Observable<any>;

  constructor(private http: HttpClient, private _configuration: Configuration) {
  }

  public addToworkgroupmembers<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._gatInActiveWorkGroupMembers, {
      headers: this._configuration.getHeaders()
    });
  }

  public workgroupmembers<T>(refresh): Observable<T> {
    this.activeWorkGroupMembers = refresh ? null : this.activeWorkGroupMembers
    if (!this.activeWorkGroupMembers) {
      this.activeWorkGroupMembers = this.http.get<T>(this._configuration._getActiveWorkGroupMembers, {
        headers: this._configuration.getHeaders()
      }).publishReplay(1).refCount();
      return this.activeWorkGroupMembers;
    } else {
      return this.activeWorkGroupMembers;
    }

  }

  public allWorkgroupmembers<T>(): Observable<T> {
    return this.http.get<T>(this._configuration._getAllWorkGroupMembers, {
      headers: this._configuration.getHeaders()
    });
  }

  public assigntasktogroup(postdata) {
    return this.http.post(this._configuration._saveTask, postdata, { headers: this._configuration.getHeaders() })
  }

  public assignBulkTask(data) {
    return this.http.post(this._configuration._saveTasks, data, { headers: this._configuration.getHeaders() })
  }

  public modifyWorkGroupMembers(modifiedList) {
    return this.http.post(this._configuration._modifyWorkgroupMembers, modifiedList, { headers: this._configuration.getHeaders() })
  }
}



