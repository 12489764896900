import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';

import * as $ from 'jquery';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';

import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
// import { AgmCoreModule } from '@agm/core';
import { LoadersCssModule } from 'angular2-loaders-css';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { CustomInterceptor } from './services/http.interceptor';

import { LoginService } from './services/login.service';
import { DataService } from './services/dashboard.service';
import { FeatureCollection, MapService } from './services/map.service';
import { ReceivedTaskService } from './services/receivedtask.service';
import { AssignedTaskService } from './services/assignedtask.service';
import { AdminService } from './services/admin.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Configuration } from './services/configuration.constants';
import { NGXToastrService } from './services/toastr.service';
import { MyWorkgroupService } from './services/my-work-group.service';
import { ProjectService } from './services/project.service';
import { UserProfileService } from './services/user-profile.service';
import { SettingsService } from './services/settings.service';
import { BirthdayBotService } from './services/birthday-bot.service';
import { CommentService } from './services/comment.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CustomDateParserService } from './services/custom-date-parser.service';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { CookieService, CookieModule } from 'ngx-cookie';
import { OnlyLoggedInUsersGuard } from './services/OnlyLoggedInUsers.service';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { PushNotificationService } from './services/pushnotification.service';
import { InActiveUser } from './services/inActiveUser.service';
import { VerifiedUser } from './services/verifiedUser.service';
import { AlreadyLoggedInUserInOneTab } from './services/alreadyLoggedIn.service';
import { ErrorInterceptor } from './services/error.interceptor';
import { ComponentsCommunicationService } from './services/componentsCommunication.service';
import { DetailedViewCommunicationService } from './services/detailedViewCommunication.service'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DragulaService } from 'ng2-dragula';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { MyTaskService } from './services/mytask.service';
import { FindInUserList } from '../app/pipes/findinuserlist.pipe';
import { FilterSelfTask } from '../app/pipes/selfTaskSearch.pipe';
import { FilterText } from '../app/pipes/search-text.pipe';
import { InboxMailService } from './services/inboxMail.service';
import { UploadService } from './services/upload.service';
import { CommonService } from './services/common.service';
import { ActivityLogSidebarCommunicationService } from './services/activity-log-sidebar.service';
import { TaskDetailedViewModule } from '../app/detailedview-sidebar/detailedview-sidebar.module'
import { ActivityLogService } from './services/activityLog.service';
import { PopUpModalService } from './services/popup-modal.service';
import { AttendanceService } from './services/attendance.service';
import { MatDatepickerModule, MatNativeDateModule,MatCardModule} from '@angular/material';
// import { ResumeBuilderComponent } from './resume-builder/resume-builder.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    // ResumeBuilderComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    TaskDetailedViewModule,
    HttpModule,
    LoadersCssModule,
    NgIdleKeepaliveModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCardModule,
    ToastrModule.forRoot(),
    ToastrModule.forRoot({
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning'
      },
      autoDismiss: true,
      maxOpened: 1,
      timeOut: 3000,

      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgbModule,
    CookieModule.forRoot(),
    UiSwitchModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCHBSySkx3D6aFuUZSfza1eD7vUuZZfRGg',
      authDomain: 'task-manager-cf60e.firebaseapp.com',
      databaseURL: 'https://task-manager-cf60e.firebaseio.com',
      projectId: 'task-manager-cf60e',
      storageBucket: 'task-manager-cf60e.appspot.com',
      messagingSenderId: '962409468072'
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'
    // })
  ],
  providers: [
    AppConfig, Title,
    InActiveUser, VerifiedUser, AlreadyLoggedInUserInOneTab, FeatureCollection, OnlyLoggedInUsersGuard, ActivityLogService,
    MapService, LoginService, DataService, AdminService, SettingsService, UserProfileService, BirthdayBotService,
    ReceivedTaskService, AssignedTaskService, MyTaskService, FilterSelfTask, FilterText, FindInUserList, InboxMailService,
    CommentService, UploadService, CommonService, DragulaService, PushNotificationService, ActivityLogSidebarCommunicationService,
    AmChartsService, Configuration, NGXToastrService, MyWorkgroupService, CookieService,ProjectService,
    DetailedViewCommunicationService, ComponentsCommunicationService, PopUpModalService, AttendanceService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor , multi: true },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserService },
    // { provide: CookieService, useFactory: cookieServiceFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function cookieServiceFactory() { return new CookieService(); }
