import { Routes } from '@angular/router';
import { OnlyLoggedInUsersGuard } from '../../services/OnlyLoggedInUsers.service';
// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'login',
        loadChildren: () => import('../../login/content-pages.module').then(m => m.ContentPagesModule)
    },
    {
        path: 'print-report',
        loadChildren: () => import('../../print-report/print-report.module').then(m => m.PrintReportModule),
        canActivate: [OnlyLoggedInUsersGuard]
    },
    // {
    //     path: 'meeting',
    //     loadChildren: () => import('../../meeting/meeting.module').then(m => m.MeetingModule),
    //     canActivate: [OnlyLoggedInUsersGuard]
    // },
    {
        path: '**',
        redirectTo: '/login/error',
        pathMatch: 'full',
    }
];
