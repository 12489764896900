import { Component, OnInit, OnDestroy } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Configuration } from '../../services/configuration.constants';
import { DataService } from '../../services/dashboard.service';
import { ComponentsCommunicationService } from '../../services/componentsCommunication.service';
import { Settings } from '../../settings/settings.model';
import { NGXToastrService } from '../../services/toastr.service';
import { SettingsService } from '../../services/settings.service';
import { ReceivedTaskService } from '../../services/receivedtask.service';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],

})

export class SidebarComponent implements OnInit, OnDestroy {
    public menuItems: any[];
    public menuSubItem: any[];
    role: any;
    admin: boolean;
    taskValue: any;
    settingsData: any;
    settingsDataforIcon: any;
    settingsDataToSend: any;
    loggedInUserId: any;
    ThemeID: any;
    EmailNotification: any;
    PushNotification: any;
    LockTaskInMobile: any;
    MFA: any;
    sidebarWidthStatus: any;
    sidebarImageStatus: any;
    compactMenuStatus: any;
    toggleRight: boolean;
    toggleLeft: boolean;
    toggleStatus: any = 'expanded';

    receivedTaskUnReadCount = {
        count: 0,
        countInText: '0'
    };

    receivedTaskUnreadCountValueSubscription: Subscription;
    menuItem: any;


    constructor(private router: Router,
        private route: ActivatedRoute, private conf: Configuration, private dataService: DataService,
        private toastrservice: NGXToastrService, private settingsservice: SettingsService,
        private componentsCommunicationService: ComponentsCommunicationService, private receivedTaskService: ReceivedTaskService) {
        this.componentsCommunicationService.currentUserDetails.subscribe((data) => {
            if (data === 'YES') {
                this.initiate();
            }
        });
    }

    ngOnInit() {
        this.role = this.conf.getRole();
        $.getScript('./assets/js/app-sidebar.js');
        this.initiate();

        this.componentsCommunicationService.updateReceivedTaskUnreadCount({
            isCallAPI: true, event: ''
        });

        this.receivedTaskUnreadCountValueSubscription = this.componentsCommunicationService.receivedTaskUnreadCountValue.subscribe((data: any) => {
            this.getReceivedUnReadTaskCount(data);
        })

    }

    /* @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {

        this.settingsData = JSON.parse(localStorage.getItem('settingsData'));
        this.ThemeID = this.settingsData.Model.AppColorThemeId;
        this.EmailNotification = this.settingsData.Model.EnableEMailNotification;
        this.PushNotification = this.settingsData.Model.EnablePushNotification;
        this.LockTaskInMobile = this.settingsData.Model.LockTaskApp;
        this.MFA = this.settingsData.Model.EnableMFA;
        this.sidebarWidthStatus = this.settingsData.Model.SideBarWidth;
        this.sidebarImageStatus = this.settingsData.Model.IsSidebarBgImage;
        this.compactMenuStatus = this.settingsData.Model.IsCompactMenu;
        const ud = JSON.parse(localStorage.getItem('userDetails'));
        if ($(event.target).parent().children().attr('data-toggle') != undefined) {
            if ($(event.target).parent().children().attr('data-toggle') === "collapsed") {
                this.settingsDataToSend = new Settings(true, this.loggedInUserId, this.ThemeID, this.LockTaskInMobile, this.PushNotification, this.MFA, this.EmailNotification, true, this.sidebarWidthStatus, this.sidebarImageStatus);
                $.ajax({
                    type: 'POST',
                    url: this.conf._saveSettings,
                    data: this.settingsDataToSend,
                    async: false,
                    success: function (msg) {
                        console.log(msg);
                        //this.toastrservice.typeSuccess('Setting is updated successfully.');
                        this.toggleRight = false;
                        this.toggleLeft = true;
                        this.toggleStatus = "collapsed";
                        console.log(this.toggleStatus);
                    },
                    beforeSend: function (xhr, settings) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + ud['token']);
                    },
                    error: function (err) {
                        //  this.toastrservice.typeError('Something Went Wrong. Please Try Again.');
                    }
                });
            } else {
                this.settingsDataToSend = new Settings(true, this.loggedInUserId, this.ThemeID, this.LockTaskInMobile, this.PushNotification, this.MFA, this.EmailNotification, false, this.sidebarWidthStatus, this.sidebarImageStatus);
                $.ajax({
                    type: 'POST',
                    url: this.conf._saveSettings,
                    data: this.settingsDataToSend,
                    async: false,
                    success: function (msg) {
                        console.log(msg);
                        // this.toastrservice.typeSuccess('Setting is updated successfully.');   
                        this.toggleRight = true;
                        this.toggleLeft = false;
                        this.toggleStatus = "expanded";
                        console.log(this.toggleStatus);
                    },
                    beforeSend: function (xhr, settings) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + ud['token']);
                    },
                    error: function (err) {
                        //  this.toastrservice.typeError('Something Went Wrong. Please Try Again.');
                    }
                });
            }
        }
    } */

    initiate() {
       // this.admin = false;
        this.loggedInUserId = this.conf.getuserId();
        this.showSettings(false);
        if (this.role === 'Admin') {
            this.menuItems = ROUTES.filter(menuItem => {
                return menuItem

            });
        } else if (this.role === 'User') {
            this.menuItems = ROUTES.filter(menuItem => {
                return (menuItem.title !== 'Admin')
            });
        } else if (this.role === 'Admin') {
            this.menuSubItem = ROUTES.filter(menuSubItem => {
                return (menuSubItem.title !== 'Team Attendance')
            });
        }
      /*  else if (this.role === 'Manager'){
            this.menuItem.submenu = ROUTES.filter(menuSubItem => {
                return (menuSubItem.title !== 'Team Attendance') 
            });*/
        // && menuItem.title !== 'Analytics'
        // $('.app-sidebar').attr('data-background-color', 'pomegranate');
    }

    showSettings(refresh) {
        this.settingsservice.showSettings(refresh).subscribe(data => {
            if (data && data['Model'] && data['Model'].IsCompactMenu === true) {
                this.toggleRight = false;
                this.toggleLeft = true;
                this.toggleStatus = 'collapsed';
                // this.componentsCommunicationService.changeUIElements('No Change');
                $('.logo-img img').attr('width', '40px');
                $('.logo-img img').css('margin-left', '-66px');
            } else {
                this.toggleRight = true;
                this.toggleLeft = false;
                this.toggleStatus = 'expanded';
                // this.sendWindowWidthToAssignedPage();
                $('.logo-img img').attr('width', '300%');
                $('.logo-img img').css('margin-left', '0px');
            }
        },
            error => {
                console.log(error);
            })
    }

    getReceivedUnReadTaskCount(actionInfo) {
        if (actionInfo.isCallAPI) {
            this.receivedTaskService.getReceivedTaskUnReadCount().subscribe(data => {
                this.receivedTaskUnReadCount.count = data['Model'];
                this.checkForUnReadCount();
            }, error => {
                console.log(error);
                this.toastrservice.typeError('Something went wrong while getting received task unread count. Please refresh again');
            })
        } else {
            if (actionInfo.event === 'readToUnRead') {
                this.receivedTaskUnReadCount.count++;
            } else {
                if (this.receivedTaskUnReadCount.count) {
                    this.receivedTaskUnReadCount.count--;
                }
            }
            this.checkForUnReadCount();
        }
    }

    checkForUnReadCount() {
        this.receivedTaskUnReadCount.countInText = this.receivedTaskUnReadCount.count > 99 ? '99' + '+'
            : String(this.receivedTaskUnReadCount.count);
    }

    sidebarclicked() {
        this.taskValue = '';
        this.dataService.saveDashboardTaskData(this.taskValue);
    }

    onMouseDown(menu, event) {
        switch (event.which) {
            case 2:
                localStorage.setItem('CurrentUrl', menu.path)
                break;
            case 3:
                localStorage.setItem('CurrentUrl', menu.path)
                break;
        }
    }

    // NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1) {
            this.router.navigate(['forms/ngx/wizard'],
                { skipLocationChange: false });
        }
    }

    onSidebarToggleClick(e) {
        if ($(e.target).attr('data-toggle') === 'collapsed') {
            this.sendWindowWidthToAssignedPage();
        } else if ($(e.target).attr('data-toggle') === 'expanded') {
            this.componentsCommunicationService.changeUIElements('No Change');
        }
    }

    sendWindowWidthToAssignedPage() {
        if (window.innerWidth === 1680) {
            this.componentsCommunicationService.changeUIElements('1680');
        } else if (window.innerWidth === 1600) {
            this.componentsCommunicationService.changeUIElements('1600');
        } if (window.innerWidth === 1440) {
            this.componentsCommunicationService.changeUIElements('1440');
        } else if (window.innerWidth <= 1396 && window.innerWidth >= 1366) {
            this.componentsCommunicationService.changeUIElements('1396');
        } else if (window.innerWidth === 1280) {
            this.componentsCommunicationService.changeUIElements('1280');
        }
    }

    ngOnDestroy() {
        this.receivedTaskUnreadCountValueSubscription.unsubscribe();
        this.settingsservice.settingsData = null;
    }
}
