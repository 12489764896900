import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()

export class DetailedViewCommunicationService {
    checkStatusChange: Subject<any> = new Subject<any>();
    constructor() { }
    refreshTaskStats(type) {
        this.checkStatusChange.next(type);
    }

}
