import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function onCreateObjectURLAndDownloadFile(data, fileName, event) {
    event.target.classList.remove('cus-beat-effect');
    const url = URL.createObjectURL(data);
    let a;
    a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
}

export function calculateFileSizeInText(file) {
    let size = file.size;
    const ext = new Array('Byte(s)', 'KB', 'MB', 'GB');
    let i = 0;
    while (size > 900) {
        size /= 1024;
        i++;
    }
    return (Math.round(size * 100) / 100) + ' ' + ext[i];
}

export function makeFormControlUntouched(formControl: FormControl) {
    formControl.markAsUntouched();
}

export function ConvertDateTimeFromUTC(date, format) {
    return moment.utc(date).local().format(format);
  }

  export function ConvertDateTimeToUTC(date,format) {
  return moment.utc(date).format(format);
}
