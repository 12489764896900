import { Directive, AfterViewInit, ElementRef, NgModule, Input } from '@angular/core';

@Directive({
    selector: '[inputAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
    @Input()
    inputAutofocus: ElementRef;
    constructor(private elRef: ElementRef) {
    }

    ngAfterViewInit() {
        this.elRef.nativeElement.focus();
    }
}

@NgModule({
    declarations: [AutofocusDirective],
    exports: [AutofocusDirective]
})

export class AutofocusModule { }
