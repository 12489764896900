import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterText } from './search-text.pipe';

@NgModule({
  declarations: [FilterText],
  imports: [CommonModule],
  exports: [FilterText]
})

export class SearchTextPipeModule {}
