import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { ToastsManager } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ComponentsCommunicationService } from './services/componentsCommunication.service';
import { AppConfig } from './app.config';
import { environment } from '../environments/environment';
import * as moment from 'moment';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {
    currentUrl: any;

    constructor(public router: Router, public location: Location,
        private componentsCommunicationService: ComponentsCommunicationService, private titleService: Title) {

        const locationArray = location.path().split('/');
        const currentURLStored = localStorage.getItem('CurrentUrl');

        if (currentURLStored !== null && currentURLStored !== '/' && currentURLStored !== '/login') {
            if (locationArray[1] === undefined) {
                router.navigate([currentURLStored]);
            } else if (locationArray[1] === 'login') {
                router.navigate([currentURLStored]);
            }
        }

        router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(e => {
                if (document.querySelector('.modal-backdrop') && document.querySelector('.modal-dialog')) {
                    document.querySelector('.modal-backdrop').remove();
                    document.querySelector('.modal').remove();
                }

                if (localStorage.getItem('userDetails') && sessionStorage.getItem('oldUserDetails')) {
                    this.componentsCommunicationService.changedUserDetails('YES');
                    sessionStorage.clear();

                    this.componentsCommunicationService.changedUserDetails('');
                }

                this.currentUrl = e['url'];
                const urlArray = this.currentUrl.split('/');

                if (urlArray[1] === 'login' && urlArray[2] !== undefined) {
                    if (urlArray[2] !== 'alreadyLoggedIn') {
                        localStorage.setItem('CurrentUrl', '/login');
                    }
                } else {
                    localStorage.setItem('CurrentUrl', this.currentUrl);
                }
            });
    }

    ngOnInit() {
        if (localStorage.getItem('userDetails')) {
            if ((new Date(atob(JSON.parse(localStorage.getItem('userDetails')).tokenExpiryTime)) <= new Date())) {
                sessionStorage.setItem('tokenExpired', 'true');
                sessionStorage.removeItem('incomingMailConfig');
                sessionStorage.removeItem('outgoingMailConfig');
                this.router.navigate(['/login']);
            }
        }

        this.setTitle(AppConfig.settings.appPageTitle.title);

        // Save environment name in local storage for the correct config file to be read
        localStorage.setItem('env', btoa(environment.name));
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    ngOnDestroy() {

    }
}
